import React from 'react';

const initialState = {
	open: false,
	horizontal: 'center',
	vertical: 'top',
	class: 'error',
	time: 4000,
	msg: '',
};

const NotifyContext = React.createContext({
	notify: initialState,
	onChangeNotify: () => console.log('Not implementated yet'),
});

export function NotifyProvider({ children }) {
	const [notify, setNotify] = React.useState(initialState);

	const onChangeNotify = (data) => {
		setNotify({ ...notify, ...data });
	};

	return (
		<NotifyContext.Provider value={{ notify, onChangeNotify }}>
			{children}
		</NotifyContext.Provider>
	);
}

export function useNotify() {
	const context = React.useContext(NotifyContext);

	if (!context)
		throw new Error('useNotify must be used within an AuthProvider');

	return context;
}
