import axios from 'axios';
import config from '../config';

const apiMonitoramento = axios.create({
	baseURL:
		// 'http://localhost:3097',
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_MONITORAMENTO_PROD
			: process.env.REACT_APP_MONITORAMENTO_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key': config.xapikey,
	},
});

export default apiMonitoramento;
