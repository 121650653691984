import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Papa from 'papaparse';
import moment from 'moment';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiSiv from '../../../services/apiSiv';

const initialState = {
	PLUcode: '',
};

const ProductsCdcContext = React.createContext({
	productsCdc: initialState,
	onChangeProductsCdc: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

ProductsCdcContext.displayName = 'Contexto - Gerenciamento de Produtos';

const validationSchema = yup.object().shape({
	// PLUcode: yup.number('Digite o PLU').required('PLU é obrigatório'),
});

export function ProductsCdcProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const [productsCdc, setProductsCdc] = React.useState(initialState);
	const [csv, setCsv] = React.useState([]);
	const [nameList, setNameList] = React.useState('');
	const [dataList, setDataList] = React.useState([]);
	const [brandList, setBrandList] = React.useState([]);
	const [regionalList, setRegionalList] = React.useState([]);
	const [storeList, setStoreList] = React.useState([]);
	const [registrationType, setRegistrationType] = React.useState('');
	const [fileName, setFileName] = React.useState('');
	const [value, setValue] = React.useState('');
	const [selected, setSelected] = React.useState();
	const [initialDate, setInitialDate] = React.useState(null);
	const [finalDate, setFinalDate] = React.useState(null);
	const [disabled, setDisabled] = React.useState(true);
	const [massRegistration, setMassRegistration] = React.useState(false);
	const [openRegisterModal, setOpenRegisterModal] = React.useState(false);

	const [storesList, setStoresList] = React.useState([]);

	const [openEditModal, setOpenEditModal] = React.useState(false);
	const [openexhibitionModal, setOpenexhibitionModal] = React.useState(false);
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
	const [carregando, setCarregando] = React.useState(true);
	const [initialSearchDate, setInitialSearchDate] = React.useState();
	const [finalSearchDate, setFinalSearchDate] = React.useState();
	const [product, setProduct] = React.useState();
	const [ean, setEan] = React.useState();
	const [status, setStatus] = React.useState();
	const [selectedId, setSelectedId] = React.useState(null);
	const [productCode, setProductCode] = React.useState();
	const [flag, setFlag] = React.useState();
	const [store, setStore] = React.useState();
	const [regional, setRegional] = React.useState();
	const [option, setOption] = React.useState();
	const [products, setProducts] = React.useState();
	const [isLoadingMassRasgistration, setIsLoadingMassRagistration] = React.useState(false)

	const [storeFilter, setStoreFilter] = React.useState('');
	const [flagFilter, setFlagFilter] = React.useState('');
	const [regionalFilter, setRegionalFilter] = React.useState('');
	const [productFilter, setProductFilter] = React.useState('');

	const handleSelection = (event) => {
		setValue(event.target.value);
		setSelected();
		setDisabled(false);
		if (event.target.value === 'brand') {
			setDataList(brandList);
			setRegistrationType('brand');
		} else if (event.target.value === 'regional') {
			setDataList(regionalList);
			setRegistrationType('regional');
		} else if (event.target.value === 'store') {
			setDataList(storeList);
			setRegistrationType('store');
		}
	};

	// Bandeiras, regionas e lojas
	const getData = async () => {
		await apiSiv
			.get(`brand/query`)
			.then((res) => {
				setBrandList(res.data);
			})
			.catch(() => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
		
		await apiSiv
			.get(`regional/query`)
			.then((res) => {
				setRegionalList(res.data);
			})
			.catch(() => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
		
		await apiSiv
			.get(`store/query`)
			.then((res) => {
				setStoreList(res.data);
			})
			.catch(() => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const formik = useFormik({
		initialValues: productsCdc,
		validationSchema,
		onSubmit: (data, { resetForm }) => {
			handleSubmit(data, resetForm);
		},
	});

	const handleChange = (event) => {
		Papa.parse(event.target.files[0], {
			header: true,
			dynamicTyping: true,
			skipEmptyLines: 'greedy', // ignora linhas vazias ou apenas com espaços após conversão
			complete: (results) => {
				setCsv(results.data);
				setFileName(event.target.files[0].name);
			},
		});
	};

	const onChangeProductsCdc = (data) => {
		setProductsCdc({ ...productsCdc, ...data });
	};

	const closeMassRegistration = () => {
		setMassRegistration(false);
		setCsv([]);
		setFileName('');
	};

	const handleMassRegistration = async () => {
		// setMassRegistration(false);
		setIsLoadingMassRagistration(true);
		onChangeLoading({
			open: true,
			msg: 'Cadastrando produtos...',
		});

		await apiSiv
			.post(`product/insert`, csv)
			.then((res) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'success',
					msg: `Cadastro realizado com sucesso! `,
				});
				closeMassRegistration();
				setIsLoadingMassRagistration(false);
			})
			.catch(() => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
				setIsLoadingMassRagistration(false);
			});
	};
	const handleSubmit = async (data, resetForm) => {
		onChangeLoading({
			open: true,
			msg: 'Cadastrando produtos...',
		});

		const objData = {
			codigoBandeira: dataList === brandList ? selected : 0,
			codigoRegional: dataList === regionalList ? selected : 0,
			codigoLoja: dataList === storeList ? selected : 0,
			codigoPlu: parseInt(data.PLUcode),
			dataInicial: moment(initialDate).format('YYYY-MM-DD HH:MM:SS'),
			dataFinal: moment(finalDate).format('YYYY-MM-DD HH:MM:SS'),
			status: true,
		};

		await apiSiv
			.post(`product/insert`, objData)
			.then((res) => {
				onChangeLoading({ open: false });
				// resetForm();
				// setValue('');
				// setSelected();
				// setInitialDate(null);
				// setFinalDate(null);
				// setDisabled(true);
				onChangeNotify({
					open: true,
					class: 'success',
					msg: `Cadastro realizado com sucesso! `,
				});
			})
			.catch(() => {
				onChangeLoading({ open: false });
				// resetForm();
				// setValue('');
				// setSelected();
				// setDisabled(true);
				// setInitialDate(null);
				// setFinalDate(null);
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	// btn buscar
	const handleSearch = async () => {
		setCarregando(true);
		setProducts([]);
		onChangeLoading({
			open: true,
		});

		
		const header = {}
		header['codigo-loja'] = storeFilter === undefined ? '' : storeFilter;
		header['codigo-plu'] = productFilter === undefined ? '' : productFilter;
		header['codigo-bandeira'] = flagFilter === undefined ? '' : flagFilter;
		header['codigo-regional'] = regionalFilter === undefined ? '' : regionalFilter;

		const config = {
			headers: header,
		};

		await apiSiv
			.get('/product/query', config)
			.then((res) => {
				onChangeLoading({ open: false });

				if (res.data == '') {
					setProducts([]);
				} else if (res.data.length >= 1) {
					setProducts(
						res.data.sort(
							(a, b) => new Date(b.dataInicial) - new Date(a.dataInicial)
						)
					);
				} else {
					setProducts([res.data]);
				}
				setCarregando(false);
			})
			.catch((error) => {
				setCarregando(false);
				onChangeLoading({ open: false });
			});
	};

	const handleCloseModal = () => {
		setOpenDeleteModal(false);
		setOpenexhibitionModal(false);
		setOpenEditModal(false);

		setInitialSearchDate('');
		setFinalSearchDate('');
		setProduct('');
		setProductCode('');
		setStatus('');
		setFlag('');
		setRegional('');
		setStore('');
		setOption('');
		setSelectedId('');
		setEan('');
	};

	// Delete
	const handleDelete = async () => {
		const config = {
			headers: {
				'id-produto': selectedId,
			},
		};

		await apiSiv
			.delete('/product/delete', config)
			.then((res) => {
				setSelectedId(null);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'success',
					msg: `Produto excluido com sucesso!`,
				});
				handleSearch();
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
		handleCloseModal();
	};

	const setModalData = (produto) => {
		setInitialSearchDate(produto.dataInicial);
		setFinalSearchDate(produto.dataFinal);
		setProduct(produto.produtoDescricao);
		setProductCode(produto.codigoPlu);
		setStatus(produto.status);
		setFlag(produto.codigoBandeira);
		setRegional(produto.codigoRegional);
		setStore(produto.codigoLoja);
		setSelectedId(produto.produtoId);
		setEan(produto.ean);
	};

	function countFilled(arr1, arr2, arr3) {
		let count = 0;
		if (arr1 !== null) {
			count++;
		}
		if (arr2 !== null) {
			count++;
		}
		if (arr3 !== null) {
			count++;
		}
		return count === 1;
	}

	function formatDateGrid(date) {
		const date1 = moment(date);
		const newDate = date1.tz('Africa/Dakar').format('YYYY-MM-DD');
		return newDate;
	}

	// btn editar
	const handleEdit = async () => {
		const data = {
			codigoBandeira: flag,
			codigoRegional: regional,
			codigoLoja: store,
			codigoPlu: productCode,
			dataInicial: `${formatDateGrid(initialSearchDate)} 00:00:00`,
			dataFinal: `${formatDateGrid(finalSearchDate)} 23:59:59`,
			ean,
			status,
		};

		const config = {
			headers: {
				'id-produto': selectedId,
			},
		};

		if (countFilled(flag, regional, store)) {
			await apiSiv
				.put(`/product/update`, data, config)
				.then((res) => {
					onChangeLoading({ open: false });

					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Edição realizada com sucesso!`,
					});
					handleCloseModal();
					handleSearch();
				})
				.catch(() => {
					onChangeLoading({ open: false });

					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				});
		} else {
			alert(
				'Preencha apenas um dos campos:\n* Bandeira\n* Regional\n* Loja\nOs outros dois devem ser deixados em branco.'
			);
		}
	};

	function getDataById(id) {
		for (const i in products) {
			if (products[i].produtoId == id) {
				return products[i];
			}
		}
		return -1;
	}

	function updateStatusData(id, status) {
		for (const i in products) {
			if (products[i].produtoId == id) {
				products[i].status = status;
			}
		}
	}

	async function handleChangeSwitch(event) {
		const config = {
			headers: {
				'id-produto': event.target.id,
			},
		};

		const data = {
			codigoBandeira: getDataById(event.target.id).codigoBandeira
				? getDataById(event.target.id).codigoBandeira
				: 0,
			codigoRegional: getDataById(event.target.id).codigoRegional
				? getDataById(event.target.id).codigoRegional
				: 0,
			codigoLoja: getDataById(event.target.id).codigoLoja
				? getDataById(event.target.id).codigoLoja
				: 0,
			codigoPlu: getDataById(event.target.id).codigoPlu,
			dataInicial: `${formatDateGrid(
				getDataById(event.target.id).dataInicial
			)} 00:00:00`,
			dataFinal: `${formatDateGrid(
				getDataById(event.target.id).dataFinal
			)} 23:59:59`,
			ean: getDataById(event.target.id).ean,
			status: !getDataById(event.target.id).status,
		};

		await apiSiv
			.put(`/product/update`, data, config)
			.then((res) => {
				updateStatusData(event.target.id, !getDataById(event.target.id).status);
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'success',
					msg: `Status atualizado com sucesso!`,
				});
			})
			.catch((e) => {
				console.log(e);
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	}

	const handleCancelRegister = () => {
		setOpenRegisterModal(false);
		setInitialDate('')
		setFinalDate('')
		setValue(null)
	}

	return (
		<ProductsCdcContext.Provider
			value={{
				productsCdc,
				onChangeProductsCdc,
				handleMassRegistration,
				fileName,
				setFileName,
				setCsv,
				value,
				selected,
				setSelected,
				initialDate,
				setInitialDate,
				finalDate,
				setFinalDate,
				disabled,
				nameList,
				dataList,
				regionalList,
				brandList,
				setDataList,
				handleSelection,
				handleChange,
				handleSubmit,
				closeMassRegistration,
				massRegistration,
				setMassRegistration,
				formik,
				setOpenRegisterModal,
				openRegisterModal,
				storesList,
				setModalData,
				initialSearchDate,
				finalSearchDate,
				product,
				ean,
				status,
				selectedId,
				productCode,
				flag,
				store,
				regional,
				setInitialSearchDate,
				setFinalSearchDate,
				setProduct,
				setEan,
				setStatus,
				setSelectedId,
				setProductCode,
				setFlag,
				setStore,
				setRegional,
				setStoreFilter,
				setFlagFilter,
				setRegionalFilter,
				setProductFilter,
				handleSearch,
				productFilter,
				carregando,
				products,
				handleDelete,
				handleCloseModal,
				handleEdit,
				openEditModal,
				setOpenEditModal,
				openexhibitionModal,
				setOpenexhibitionModal,
				openDeleteModal,
				setOpenDeleteModal,
				handleChangeSwitch,
				storeList,
				handleCancelRegister,
				getData,
				flagFilter,
				storeFilter,
				productFilter,
				regionalFilter,
				isLoadingMassRasgistration,
			}}
		>
			{children}
		</ProductsCdcContext.Provider>
	);
}

export function useProductsCdc() {
	const context = React.useContext(ProductsCdcContext);

	if (!context)
		throw new Error('useProductsCdc must be used within an AuthProvider');

	return context;
}
