const RemoveStorageData = () => {
	localStorage.removeItem('@SF:ApprovedData');
	localStorage.removeItem('@SF:ApprovedResponse');
	localStorage.removeItem('@SF:Service');
	localStorage.removeItem('@SF:ServiceData');
	localStorage.removeItem('@SF:Product');
	localStorage.removeItem('@SF:SimulateProposalCdc');
	localStorage.removeItem('@SF:ServiceGe');
	localStorage.removeItem('@SF:ServiceFr');
  localStorage.removeItem('@SF:ServiceDa');
	localStorage.removeItem('@SF:ServiceCb');
	localStorage.removeItem('@SF:ParcelaCdc');
	localStorage.removeItem('@SF:DataOCR');
	localStorage.removeItem('@SF:ParcelaEp');
  localStorage.removeItem('@SF:SimulateProposalEp');
  localStorage.removeItem('@SF:ProductWithdrawal');
  localStorage.removeItem('@SF:ProposalApprovedCdc');
};

export default RemoveStorageData;