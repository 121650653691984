import React from 'react';
import * as yup from 'yup';
import { validateCPF } from 'validations-br';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import { useSessionExpiration } from '../../../hooks/sessionExpiration';

import apiMotorSiv from '../../../services/apiMotorSiv';
import apiIdentify from '../../../services/apiIdentify';

const initialState = {
	nrCPF: '',
	dsNomeCompleto: '',
	id: '',
	proposalStatus: '',
	business: '',
	store: '',
	proposalDate: '',
};

const ConsultProposalContext = React.createContext({
	consultProposal: initialState,
	onChangeConsultProposal: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

const validationSchema = yup.object({
	nrCPF: yup
		.string('Digite seu CPF')
		.test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
		.required('CPF é obrigatório'),
});

export function ConsultProposalProvider({ children }) {
	const history = useHistory();
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { getMoreSession } = useSessionExpiration();

	const [consultProposal, setConsultProposal] = React.useState(initialState);
	const [consultData, setConsultData] = React.useState([]);
	const [oneData, setOneData] = React.useState([]);
	const [dataReal, setDataReal] = React.useState('');
	const [activeStep, setActiveStep] = React.useState(0);
	const [continueProposal, setContinueProposal] = React.useState(false);
	const [openModalMessage, setOpenModalMessage] = React.useState(false);
	const [modalMessage, setModalMessage] = React.useState(false);
	const [controlState, setControlState] = React.useState(false);


	const formik = useFormik({
		initialValues: consultProposal,
		validationSchema,
		onSubmit: (data, { resetForm }) => {
			handleSubmit(data, resetForm);
			setDataReal(data);
			getMoreSession();
		},
	});

	const verifyBio = () => {		
		apiMotorSiv
			.get(`/consultar-proposta/${oneData.dadosPessoais.nrCPF}`)
			.then((response) => {
				const dataProposal = response.data;
				const filterStatus = dataProposal.filter(
					(item) => item.cdProposta === oneData.cdProposta
				);
				if (
					filterStatus[0].cdStatusProposta !== 'Pendencia' &&
					filterStatus[0].cdStatusProposta !== 'Gerar Pac'
				) {
					setControlState(false);
					handleInitialConsult();
				}
			})
	}

	const handleSelectOneData = (item) => {
		const filterOne = consultData.filter(
			(itemOne) => itemOne.cdProposta === item
			);
			setOneData(filterOne[0]);
		if (filterOne[0].cdStatusProposta === 'Continuar Proposta') {
			handleRedirectToContinueProposal(filterOne[0].cdProposta);
		} else {
			handleNext();
		}
	};

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const onChangeConsultProposal = (data) => {
		setConsultProposal({ ...consultProposal, ...data });
	};

	const handleSubmit = async (data, resetForm) => {
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		await apiMotorSiv
			.get(`/consultar-proposta/${data.nrCPF.replace(/[^0-9]/g, '')}`)
			.then((res) => {
				onChangeLoading({ open: false });

				if (res.status === 200) {
					setConsultData(res.data);
					handleNext();
					resetForm();

					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Consulta realizada com sucesso! `,
					});
				} else {
					resetForm();

					onChangeNotify({
						open: true,
						class: 'attention',
						msg: `Não encontramos dados para o CPF informado! `,
					});
				}
			})
			.catch(() => {
				onChangeLoading({ open: false });

				resetForm();

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const openWin = (open) => {
		if (open) {
			open.focus();
		} else {
			alert('Por favor, permita pop-ups para este site');
		}
	};

	const handleIdentify = async (proposal) => {
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});
		const data = {
			cpf: proposal.dadosPessoais.nrCPF,
			email: '',
			nrProposta: proposal.cdProposta,
		};

		await apiIdentify.post(`/pendency`, data).then((res) => {
			onChangeLoading({ open: false });
			const win = window.open(res.data.url, '_blank');
			openWin(win);			
		});
	};

	const handleRemovePendenceTel = async (objData) => {
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});
		const data = {
			proposalUUID: objData.proposalUUID,
			phone: parseInt(objData.phone),
			referencePhone: parseInt(objData.referencePhone),
			promoterRegistration: parseInt(objData.promoterRegistration),
		};

		await apiMotorSiv
			.post(`/resolve-pendencies`, data)
			.then((res) => {
				onChangeLoading({ open: false });
				setModalMessage(res.data.result);
				setOpenModalMessage(!openModalMessage);
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleVerifyStorage = async () => {
		const responseData = await localStorage.getItem('@SF:ApprovedResponse');
		const responseDataObj = JSON.parse(responseData);
		if (responseDataObj.nrProposta === '') {
			return;
		}
		if (responseDataObj.flEP === 1) {
			return history.push('/emprestimo/cadastro-proposta-ep');
		}
		if (responseDataObj.flCDC === 1) {
			history.push('/emprestimo/cadastro-proposta-cdc');
		}
	};

	const verifyDataContinueProposal = (data) => {
		if (data.length <= 0) {
			setContinueProposal(true);
			return;
		}
		localStorage.setItem('@SF:ApprovedData', JSON.stringify(data.approvedData));
		localStorage.setItem(
			'@SF:ApprovedResponse',
			JSON.stringify(data.approvedResponse)
		);
		localStorage.setItem('@SF:DataOCR', JSON.stringify(data.dataOCR));
		localStorage.setItem('@SF:Product', JSON.stringify(data.produto));
		localStorage.setItem('@SF:ServiceGe', JSON.stringify(data.seguro.dataGE));
		localStorage.setItem('@SF:ServiceCb', JSON.stringify(data.seguro.dataCb));
		localStorage.setItem('@SF:ServiceFr', JSON.stringify(data.seguro.dataFr));
		localStorage.setItem('@SF:ServiceDa', JSON.stringify(data.seguro.dataDa));
		localStorage.setItem('@SF:ServiceData', JSON.stringify(data.serviceData));
		localStorage.setItem('@SF:ParcelaEp', JSON.stringify(data.parcelaEP));
		localStorage.setItem('@SF:ParcelaCdc', JSON.stringify(data.parcelaCDC));
		localStorage.setItem(
			'@SF:SimulateProposalEp',
			JSON.stringify(data.simulateProposalEP)
		);
		localStorage.setItem(
			'@SF:SimulateProposalCdc',
			JSON.stringify(data.simulateProposalCDC)
		);
		handleVerifyStorage();
	};

	const handleRedirectToContinueProposal = async (objData) => {
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		await apiMotorSiv
			.get(`/continuar-proposta/${objData}`)
			.then((res) => {
				onChangeLoading({ open: false });
				verifyDataContinueProposal(res.data);
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleGeneratePac = async (objData) => {
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		const ObjData = {
			nrProposta: objData.cdProposta,
			cdContrato: objData.cdContrato,
		};

		await apiMotorSiv
			.post(`/gerar-pac`, ObjData)
			.then((res) => {
				if (res.status === 200) {
					onChangeNotify({
						open: true,
						class: 'success',
						msg: `PAC gerada com sucesso! `,
					});
					handleInitialConsult();
				}
				onChangeLoading({ open: false });
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleInitialConsult = (proposalNumber) => {
		history.push('/emprestimo/consultar-proposta');
	}

	return (
		<ConsultProposalContext.Provider
			value={{
				consultProposal,
				onChangeConsultProposal,
				consultData,
				handleSubmit,
				activeStep,
				handleBack,
				handleNext,
				formik,
				dataReal,
				oneData,
				handleSelectOneData,
				handleIdentify,
				handleRemovePendenceTel,
				continueProposal,
				setContinueProposal,
				setActiveStep,
				openModalMessage,
				setOpenModalMessage,
				modalMessage,
				handleInitialConsult,
				verifyBio,
				controlState,
				setControlState,
				handleGeneratePac,
			}}
		>
			{children}
		</ConsultProposalContext.Provider>
	);
}

export function useConsultProposal() {
	const context = React.useContext(ConsultProposalContext);

	if (!context)
		throw new Error('useConsultProposal must be used within an AuthProvider');

	return context;
}
