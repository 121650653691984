import React from 'react';
import {
	Dialog,
	AppBar,
	Toolbar,
	Typography,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	makeStyles,
	IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		// color: theme.palette.grey[500],
	},
}));

export default function Modal({
	isOpen,
	onClose,
	title,
	children,
	onCancel,
	onConfirm,
	leftButtons,
	rightButtons,
	cancelButtonText = 'Cancelar',
	confirmButtonText = 'Confirmar',
	confirmButtonDisabled = false,
	cancelButtonDisabled = false,
	contentAnimation,
	fullWidth = true,
	maxWidth = 'sm',
	headerColor = 'secondary',
	headerElevation = 1,
	notButton,
	notButtonText = 'Não adicionar serviço',
}) {
	const classes = useStyles();

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth={fullWidth}
			maxWidth={maxWidth}
			disableRestoreFocus={true}
		>
			{title || onClose ? (
				<AppBar
					position="static"
					elevation={headerElevation}
					color={headerColor}
				>
					<Toolbar className="flex w-full">
						<Typography variant="h6" color="inherit">
							{title}
						</Typography>
						{onClose && (
							<IconButton
								aria-label="close"
								className={classes.closeButton}
								onClick={onClose}
								style={{
									color: headerColor === 'secondary' ? 'white' : 'gray',
								}}
							>
								<CloseIcon />
							</IconButton>
						)}
					</Toolbar>
				</AppBar>
			) : null}

			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{contentAnimation ? { children } : children}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{leftButtons}
				{notButton && (
					<Button onClick={notButton} type="submit" color="secondary">
						{notButtonText}
					</Button>
				)}
				{onCancel && (
					<Button
						onClick={onCancel}
						color="secondary"
						disabled={cancelButtonDisabled}
					>
						{cancelButtonText}
					</Button>
				)}
				{onConfirm && (
					<Button
						disabled={confirmButtonDisabled}
						onClick={onConfirm}
						type="submit"
						variant="contained"
						color="primary"
					>
						{confirmButtonText}
					</Button>
				)}
				{rightButtons}
			</DialogActions>
		</Dialog>
	);
}
