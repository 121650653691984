import React from 'react';
import * as yup from 'yup';
import { validateCPF } from 'validations-br';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiSiv from '../../../services/apiSiv';
import { daysToWeeks } from 'date-fns/esm';

const initialState = {
	dtInicial: new Date(),
	dtFinal: new Date(),
	flConciliado: true
};

const ReportConcContext = React.createContext({
	ReportConc: initialState,
	onChangeReportConc: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

const validationSchema = yup.object({
	dtInicial: yup
		.string('Digite a data Fiscal Inicial')
		//.test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
		.required('Data é obrigatório'),
		dtInicial: yup
		.string('Digite a data Fiscal Final')
		//.test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
		.required('Data é obrigatório'),
});

export function ReportConcProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const [ReportConc, setReportConc] = React.useState(initialState);
	const [consultData, setConsultData] = React.useState([]);
	const [oneData, setOneData] = React.useState([]);
	const [dataReal, setDataReal] = React.useState('');
	const [activeStep, setActiveStep] = React.useState(0);

	const formik = useFormik({
		initialValues: ReportConc,
		validationSchema,
		onSubmit: (data, { resetForm }) => {
			handleSubmit(data, resetForm);
			setDataReal(data);
		},
	});

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const onChangeReportConc = (data) => {
		setReportConc({ ...ReportConc, ...data });
	};

	const handleSubmit = async (data, resetForm) => {
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		const dataJson = {
				dtInicial: moment(data.dtInicial).format('YYYY-MM-DD')||' 00:00:00',
				dtFinal: moment(data.dtFinal).format('YYYY-MM-DD')||' 23:59:59',
				conciliado: data.flConciliado,
		};

		await apiSiv
			.post(`report/conciliacao`, dataJson)
			.then((res) => {
				onChangeLoading({ open: false });

				if (res.status === 200) {
					setConsultData(res.data);
					handleNext();
					resetForm();

					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Consulta realizada com sucesso! `,
					});
				} else {
					resetForm();

					onChangeNotify({
						open: true,
						class: 'attention',
						msg: `Não encontramos dados! `,
					});
				}
			})
			.catch(() => {
				onChangeLoading({ open: false });

				resetForm();

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	return (
		<ReportConcContext.Provider
			value={{
				ReportConc,
				onChangeReportConc,
				consultData,
				handleSubmit,
				activeStep,
				handleBack,
				handleNext,
				formik,
				dataReal,
				oneData,
			}}
		>
			{children}
		</ReportConcContext.Provider>
	);
}

export function useReportConc() {
	const context = React.useContext(ReportConcContext);

	if (!context)
		throw new Error('useReportConc must be used within an AuthProvider');

	return context;
}
