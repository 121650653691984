import React from 'react';
import {
	Dialog,
	AppBar,
	Toolbar,
	Typography,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Grid,
} from '@material-ui/core';
import { useSessionExpiration } from '../../hooks/sessionExpiration';

export default function SessionExpirationModal({
	onClose,
	title = 'Estender Sessão?',
	maxWidth = 'sm',
	headerColor = 'secondary',
	headerElevation = 1,
}) {
	const { minutes, seconds, modal, setModal, getMoreSession } = useSessionExpiration();

	return (
		<Dialog
			open={modal}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth={maxWidth}
			disableRestoreFocus
		>
			{title || onClose ? (
				<AppBar
					position="static"
					elevation={headerElevation}
					color={headerColor}
				>
					<Toolbar className="flex w-full">
						<Typography variant="h6" color="inherit">
							{title}
						</Typography>
					</Toolbar>
				</AppBar>
			) : null}

			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<Grid container justifyContent="center">
						{minutes === 0 && seconds === 0 ? null : (
							<>
								<Grid item>
									<Typography variant="h3" color="inherit">
										{minutes}:{seconds < 10 ? `0${seconds}` : seconds} restantes
									</Typography>
								</Grid>
							</>
						)}
					</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setModal(false)} type="submit" color="secondary">
					Não
				</Button>
				<Button onClick={getMoreSession} color="secondary">
					Sim
				</Button>
			</DialogActions>
		</Dialog>
	);
}
