import React from 'react';
import { AuthProvider } from './auth';
import { NotifyProvider } from './notify';
import { LoadingProvider } from './loading';
import { StoreProvider } from './store';
import { SessionExpirationProvider } from './sessionExpiration';
import { CountdownProvider } from './countdown';
import { PermissionsProvider } from './permissions';

import Providers from '../pages/PersonalLoan/Providers/index';
import ProvidersSecurity from '../pages/Security/Providers/index';
import TangibleProvider from '../pages/Tangible/Providers/index';
import { SalesMonitoringProvider } from '../pages/SalesMonitoring/Providers/salesMonitoring';
import SuperAppProvider from '../pages/SuperApp/Providers/index';
import ReportProvider from '../pages/Report/Providers/index';
import InvoiceProvider from '../pages/Invoice/Providers/index';
import MonitoringProvider from '../pages/Monitoring/Providers';
import ConsultProvider from '../pages/Cencopay/Providers';

function AppProvider({ children }) {
	return (
		<AuthProvider>
			<StoreProvider>
				<LoadingProvider>
					<NotifyProvider>
						<Providers>
							<ProvidersSecurity>
								<CountdownProvider>
									<SessionExpirationProvider>
										<PermissionsProvider>
											<TangibleProvider>
												<SalesMonitoringProvider>
													<SuperAppProvider>
														<ReportProvider>
															<InvoiceProvider>
																<MonitoringProvider>
																	<ConsultProvider>{children}</ConsultProvider>
																</MonitoringProvider>
															</InvoiceProvider>
														</ReportProvider>
													</SuperAppProvider>
												</SalesMonitoringProvider>
											</TangibleProvider>
										</PermissionsProvider>
									</SessionExpirationProvider>
								</CountdownProvider>
							</ProvidersSecurity>
						</Providers>
					</NotifyProvider>
				</LoadingProvider>
			</StoreProvider>
		</AuthProvider>
	);
}

export default AppProvider;
