import React from 'react';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import { useAuth } from '../../../hooks/auth';

import apiFaturaSisf from '../../../services/apiFaturaSisf';

const AnalyticalReportContext = React.createContext({});
AnalyticalReportContext.displayName =
	'Contexto de Relatório Analítico de Fatura';

export function AnalyticalReportProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();

	const [cpf, setCpf] = React.useState('');
	const [initialDate, setInitialDate] = React.useState('');
	const [finalDate, setFinalDate] = React.useState('');
	const [isLoading, setIsLoading] = React.useState(false);
	const [data, setData] = React.useState([]);

	const handleConsult = async () => {
		const _dataInicio = new Date(initialDate);
		const _dataFim = new Date(finalDate);
		const difTempo = _dataFim.getTime() - _dataInicio.getTime();
		const difDias = Math.ceil(difTempo / (1000 * 3600 * 24));

		if (initialDate == '' || finalDate == '') {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Preencha as datas de forma válida.',
			});
		} else if (difDias < 0) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'A data final não pode ser menor que a inicial.',
			});
		} else if (difDias > 29) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'O intervalo máximo é de 30 dias.',
			});
		} else {
			setIsLoading(true);

			await apiFaturaSisf
				.get(
					`/relatorio/analitico?nrCpf=${cpf
						.toString()
						.replace(
							/\.|-/gm,
							''
						)}&dtInicial=${initialDate}&dtFinal=${finalDate}`
				)
				.then((res) => {
					setData(res.data);

					handleRecordLog(
						'RELATORIO ANALITICO',
						'EXTRACAO',
						cpf.toString().replace(/\.|-/gm, '')
					);

					onChangeLoading({ open: false });
					setIsLoading(false);
				})
				.catch((error) => {
					setIsLoading(false);
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				});
		}
	};

	const handleRecordLog = async (
		screen = '',
		type = '',
		cpf = '',
		customerName = '',
		modality = '',
		logo = '',
		dueDate = '',
		destinationEmail = ''
	) => {
		const payload = {
			loginAd: user.login_ad,
			matriculaAd: user.matricula,
			nmColaborador: user.name,
			dsTela: screen,
			dsAcao: type,
			nmCliente: customerName,
			dsModalidadeFatura: modality,
			dsLogo: logo,
			dtVencimento: dueDate,
			dsEmailDestino: destinationEmail,
			nrCpfCliente: cpf,
		};

		await apiFaturaSisf
			.post(`/log/insert`, payload)
			.then((res) => {})
			.catch((error) => {});
	};

	return (
		<AnalyticalReportContext.Provider
			value={{
				cpf,
				setCpf,
				initialDate,
				setInitialDate,
				finalDate,
				setFinalDate,
				isLoading,
				data,
				setData,
				handleConsult,
				handleRecordLog,
			}}
		>
			{children}
		</AnalyticalReportContext.Provider>
	);
}

export function useAnalyticalReport() {
	const context = React.useContext(AnalyticalReportContext);

	if (!context)
		throw new Error('useAnalyticalReport must be used within an AuthProvider');

	return context;
}
