import axios from 'axios';
import config from '../config';

const apiEnviaEmail = axios.create({
	baseURL: process.env.REACT_APP_PROD === "true"
		? process.env.REACT_APP_ENVIAEMAIL_PROD
		: process.env.REACT_APP_ENVIAEMAIL_HMG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key': config.xapikey,
		apikey: config.apikeykong,
	},
});

export default apiEnviaEmail;
