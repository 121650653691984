import React from 'react';
import { SimulateProposalProvider } from './simulateProposal';
import { SimulateCdcProvider } from './simulateCdc';
import { ConsultProposalProvider } from './consultProposal';
import { DuplicateRequestProvider } from './duplicateRequest';
import { ReportConcProvider } from './ReportConc';
import { CertificateProvider } from './Certificate';
import { ControlCreditCdcProvider } from './controlCreditCdc';

function PersonalLoanProvider({ children }) {
	return (
		<SimulateProposalProvider>
			<SimulateCdcProvider>
				<ConsultProposalProvider>
					<DuplicateRequestProvider>
						<ReportConcProvider>
							<CertificateProvider>
								<ControlCreditCdcProvider>
										{children}
								</ControlCreditCdcProvider>
							</CertificateProvider>
						</ReportConcProvider>
					</DuplicateRequestProvider>
				</ConsultProposalProvider>
			</SimulateCdcProvider>
		</SimulateProposalProvider>
	);
}

export default PersonalLoanProvider;
