import axios from 'axios';
import config from '../config'

const apiAutenticacao = axios.create({
	baseURL:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_AUTENTICACAO_PROD
			: process.env.REACT_APP_AUTENTICACAO_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Headers':
		'Origin, X-Requested-With, Content-Type, Accept',
		'x-api-key': config.xapikey,
	},
});

export default apiAutenticacao;
