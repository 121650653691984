import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import config from '../../../config';
import apiAuth from '../../../services/apiAuth';

const initialState = {
	profileName: '',
};

const ProfileManagementContext = React.createContext({
	profileManagement: initialState,
	onChangeProfileManagement: () => console.log('Not implemented yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

ProfileManagementContext.displayName = 'Contexto de gerenciamento de perfil';

const validationSchema = yup.object().shape({});

export function ProfileManagementProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const [profileManagement, setProfileManagement] =
		React.useState(initialState);
	const [profileList, setProfileList] = React.useState([]);
	const [activeStep, setActiveStep] = React.useState(0);
	const [registration, setRegistration] = React.useState(false);
	const [profileName, setProfileName] = React.useState('');
	const [selected, setSelected] = React.useState();
	const [result, setResult] = React.useState(false);
	const [profilePermissions, setProfilePermissions] = React.useState([]);
	const [newGroupData, setNewGroupData] = React.useState('');
	const [editGroup, setEditGroup] = React.useState(false);
	const [deleteGroup, setDeleteGroup] = React.useState(false);

	const formik = useFormik({
		initialValues: profileManagement,
		validationSchema,
		onSubmit: (data, { resetForm }) => {
			handleSubmit(data, resetForm);
		},
	});

	const handleNext = React.useCallback(() => {
		setActiveStep(activeStep + 1);
	}, [activeStep]);

	const handleBack = React.useCallback(() => {
		setActiveStep(activeStep - 1);
	}, [activeStep]);

	const onChangeProfileManagement = (data) => {
		setProfileManagement({ ...profileManagement, ...data });
	};

	const handleProfileList = async () => {
		await apiAuth
			.get('group/query')
			.then((res) => {
				setProfileList(res.data);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleRegistration = async (data) => {
		onChangeLoading({ open: true, msg: 'Cadastrando perfil...' });
		const User = localStorage.getItem('@SF:User');
		const userObj = JSON.parse(User);
		const objData = {
			app_id: config.app_id,
			name: data.toUpperCase(),
		};
		const settings = {
			headers: {
				'login-ad-abm': userObj.login_ad,
			},
		};
		await apiAuth
			.post('group/insert', objData, settings)
			.then((res) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Perfil cadastrado com sucesso!',
				});
			})
			.catch(() => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
		setRegistration(false);
	};

	const handleConsult = async () => {
		onChangeLoading({ open: true, msg: 'Consultando perfil...' });
		const settings = {
			headers: {
				'id-group': selected?.group_id,
				permissions: true,
				'id-app': config.app_id,
			},
		};

		await apiAuth
			.get(`group/query`, settings)
			.then((res) => {
				onChangeLoading({ open: false });
				setProfilePermissions(res.data);
				setResult(true);
			})
			.catch(() => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};
	const handleReload = async () => {
		onChangeLoading({ open: true, msg: 'Atualizando informações...' });
		const settings = {
			headers: {
				'id-group': selected,
				permissions: true,
				'id-app': config.app_id,
			},
		};

		await apiAuth
			.get(`group/query`, settings)
			.then((res) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Informações atualizadas com sucesso!',
				});
				setProfilePermissions(res.data);
			})
			.catch(() => {
				onChangeLoading({ open: false });
			});
	};

	const handleEditPermission = async (data, permission) => {
		onChangeLoading({ open: true, msg: 'Alterando permissões...' });
		const objData = {
			type_permission: data.permissions.permission_type,
			change: permission,
			consult: permission,
			register: permission,
			view: permission,
			type_id: data.permissions.type_id,
			group_id: profilePermissions.group_id,
			app_id: config.app_id,
		};

		const User = localStorage.getItem('@SF:User');
		const userObj = JSON.parse(User);
		const settings = {
			headers: {
				'login-ad-abm': userObj.login_ad,
			},
		};

		await apiAuth
			.post(`permissions/update`, objData, settings)
			.then((res) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Acesso alterado com sucesso!',
				});
				handleReload();
			})
			.catch(() => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleSubmit = async (data, resetForm) => {
		onChangeNotify({
			open: true,
			class: 'success',
			msg: 'Cadastro realizado com sucesso',
		});
	};

	const closeEditGroup = () => {
		setEditGroup(false);
	};

	const handleDelete = async (groupId) => {
		const User = localStorage.getItem('@SF:User');
		const userObj = JSON.parse(User);
		const settings = {
			headers: {
				'id-group': groupId,
				'login-ad-abm': userObj.login_ad,
			},
		};

		await apiAuth
			.delete('group/delete', settings)
			.then(() => {
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Perfil excluído com sucesso!',
				});
				setDeleteGroup(false);
				setResult(false);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};
	const handleEdit = async (groupData) => {
		const User = localStorage.getItem('@SF:User');
		const userObj = JSON.parse(User);
		const objData = {
			app_id: config.app_id,
			name: groupData.group_name?.toUpperCase(),
		};
		const settings = {
			headers: {
				'id-group': groupData.group_id,
				'login-ad-abm': userObj.login_ad,
			},
		};
		await apiAuth
			.put('group/update', objData, settings)
			.then(() => {
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Perfil atualizado com sucesso!',
				});
				handleReload();
				closeEditGroup();
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	return (
		<ProfileManagementContext.Provider
			value={{
				profileManagement,
				onChangeProfileManagement,
				handleProfileList,
				handleConsult,
				handleBack,
				handleRegistration,
				registration,
				setRegistration,
				profileName,
				newGroupData,
				setProfileName,
				setNewGroupData,
				selected,
				setSelected,
				profileList,
				handleSubmit,
				profilePermissions,
				handleEditPermission,
				activeStep,
				setActiveStep,
				formik,
				handleDelete,
				result,
				setResult,
				handleEdit,
				closeEditGroup,
				editGroup,
				setEditGroup,
				deleteGroup,
				setDeleteGroup,
				setProfilePermissions,
			}}
		>
			{children}
		</ProfileManagementContext.Provider>
	);
}

export function useProfileManagement() {
	const context = React.useContext(ProfileManagementContext);

	if (!context)
		throw new Error(
			'useProfileManagement must be use within an ProfileManagementProvider'
		);

	return context;
}
