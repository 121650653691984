import React from 'react';
import { pdfjs } from 'react-pdf';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { validateCPF } from 'validations-br';

import { makeStyles } from '@material-ui/core';
import { useLoading } from '../../../hooks/loading';
import { useNotify } from '../../../hooks/notify';

import apiMotorSiv from '../../../services/apiMotorSiv';
import apiEnviaEmail from '../../../services/apiEnviaEmail';

const initialState = {
	nrCPF: '',
};

const DuplicateRequestContext = React.createContext({
	duplicate: initialState,
	onChangeDuplicate: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log(''),
});

const validationSchema = yup.object().shape({
	nrCPF: yup
		.string('Digite o CPF')
		.test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
		.required('CPF é obrigatório'),
});

export function DuplicateRequestProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const [duplicate, setDuplicate] = React.useState(initialState);
	const [submitResult, setSubmitResult] = React.useState([]);
	const [duplicateResp, setDuplicateResp] = React.useState([]);
	const [disabledBill, setDisabledBill] = React.useState(true);
	const [loadingMode, setLoadingMode] = React.useState(false);
	const [boletos, setBoletos] = React.useState([]);
	const [carne, setCarne] = React.useState('');

	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

	const base64 = 'data:application/pdf;base64,';
	const pdfContentType = 'application/pdf';

	const [activeStep, setActiveStep] = React.useState(0);
	const [sendEmail, setSendEmail] = React.useState(false);
	const [bill, setBill] = React.useState(false);
	const [installment, setInstallment] = React.useState(false);

	const [permissionEditEmail, setPermissionEditEmail] = React.useState(false);
	const [dataDuplicate, setDataDuplicate] = React.useState(null);
	const [dataEmail, setDataEmail] = React.useState(dataDuplicate);
	const [selected, setSelected] = React.useState('');
	const [selectedCard, setSelectedCard] = React.useState('');
	const [disabled, setDisabled] = React.useState(true);

	const useStyles = makeStyles({
		selected: {
			marginBottom: '20px',
			backgroundColor: '#f4a100',
			color: '#FFF',
		},
		root: {
			marginBottom: '20px',
		},
		disabled: {
			marginBottom: '20px',
			backgroundColor: '#ededed',
			props: { variant: 'secondary' },
			pointerEvents: 'none',
		},
		actionArea: {
			'&:hover $focusHighlight': {
				opacity: 0,
			},
		},
		grid: {
			'&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus ':
				{
					outline: 'none',
				},
		},
	});

	const formik = useFormik({
		initialValues: duplicate,
		validationSchema,
		onSubmit: (data, { resetForm }) => {
			handleSubmit(data, resetForm);
		},
	});

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const onChangeDuplicate = (data) => {
		setDuplicate({ ...duplicate, ...data });
	};

	const handleCloseInstallment = () => {
		setInstallment(false);
		setSelected([]);
		setSelectedCard('');
		setBoletos([]);
		setDisabled(true);
		setDisabledBill(true);
		setDuplicateResp([]);
	};

	const handleCloseBill = () => {
		setBill(false);
		setDisabled(true);
		setDisabledBill(true);
		setDuplicateResp([]);
	};

	// Consulta contrato
	const handleSubmit = async (data, resetForm) => {
		onChangeLoading({
			open: true,
			msg: 'Consultando CPF...',
		});

		const cpf = data.nrCPF.toString().replace(/\.|-/gm, '');

		await apiMotorSiv
			.get(`consulta-contrato/${cpf}`)
			.then((res) => {
				onChangeLoading({ open: false });

				if (res.status === 200) {
					setSubmitResult(res.data);
					handleNext();
					resetForm();

					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Consulta realizada com sucesso!`,
					});
				} else {
					onChangeNotify({
						open: true,
						class: 'error',
						msg: `Nenhum dado encontrado para o CPF informado.`,
					});
				}
			})
			.catch(() => {
				onChangeLoading({ open: false });
				resetForm();
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	// Consulta boleto/carnê
	const handleDuplicate = async (data) => {
		setLoadingMode(true);
		if (data.nrContrato !== ' ') {
			const objData = {
				nrContrato: data.nrContrato,
				cdTipo: data.cdTipo,
			};
			await apiMotorSiv
				.post(`segunda-via`, objData)
				.then((res) => {
					onChangeLoading({ open: false });
					setDuplicateResp(res.data);
					setDisabledBill(false);
					if (data.cdTipo === 1) {
						setBoletos(res?.data?.boletos);
					} else if (data.cdTipo === 2) {
						setCarne(res?.data?.carne);
					}
				})
				.catch(() => {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
					if (data.cdTipo === 1) {
						setInstallment(false);
					} else if (data.cdTipo === 2) {
						setBill(false);
					}
				});
		} else {
			onChangeLoading({ open: false });
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Não é possível consultar boleto/carnê sem contrato',
			});
			if (data.cdTipo === 1) {
				setInstallment(false);
			} else if (data.cdTipo === 2) {
				setBill(false);
			}
		}

		setLoadingMode(false);
	};

	// Converte base64 para blob
	const base64toBlob = (data) => {
		const base64WithoutPrefix = data.substr(
			`data:${pdfContentType};base64,`.length
		);

		const bytes = atob(base64WithoutPrefix);
		let { length } = bytes;
		const out = new Uint8Array(length);

		while (length--) {
			out[length] = bytes.charCodeAt(length);
		}

		return new Blob([out], { type: pdfContentType });
	};

	// Cria URL e abre uma nova janela
	const createUrlNewTab = (document) => {
		window.open(
			URL.createObjectURL(base64toBlob(base64 + document)),
			'_newWindow'
		);
	};

	// Envia email boleto/carnê
	const handleSubmitEmail = async () => {
		installment ? handleCloseInstallment() : handleCloseBill();
		onChangeLoading({
			open: true,
			msg: 'Enviando email...',
		});
		setSendEmail(false);
		const objData = {
			nrContrato: dataEmail.nrContrato,
			tpEnvio: dataEmail.tpEnvio,
			dsEmail: dataEmail.dsEmail,
			dsNome: dataEmail.dsNome.split(' ')[0],
			nrCpf: dataEmail.nrCPF,
			anexoBase64: dataEmail.cdTipo === 2 ? carne : selected,
			cdBandeira: Number(dataEmail.dsBandeira),
			dsProduto: dataEmail.dsProduto,
		};

		await apiEnviaEmail
			.post(`enviar-email-anexo-dtm`, objData)
			.then((res) => {
				onChangeLoading({
					open: false,
				});
				onChangeNotify({
					open: true,
					class: 'success',
					msg: `${
						dataEmail.cdTipo === 1 ? 'Boleto' : 'Carnê'
					} enviado com sucesso para: ${dataEmail.dsEmail.toUpperCase()}`,
				});
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
		setLoadingMode(false);
	};

	return (
		<DuplicateRequestContext.Provider
			value={{
				duplicate,
				onChangeDuplicate,
				loadingMode,
				setLoadingMode,
				disabledBill,
				setDisabledBill,
				duplicateResp,
				setDuplicateResp,
				submitResult,
				handleDuplicate,
				handleSubmit,
				handleNext,
				handleBack,
				activeStep,
				handleSubmitEmail,
				sendEmail,
				setSendEmail,
				bill,
				setBill,
				installment,
				setInstallment,
				formik,
				createUrlNewTab,
				setActiveStep,
				permissionEditEmail,
				setPermissionEditEmail,
				dataDuplicate,
				setDataDuplicate,
				dataEmail,
				setDataEmail,
				selected,
				setSelected,
				selectedCard,
				setSelectedCard,
				disabled,
				setDisabled,
				useStyles,
				boletos,
				setBoletos,
				carne,
				handleCloseInstallment,
				handleCloseBill,
			}}
		>
			{children}
		</DuplicateRequestContext.Provider>
	);
}

export function useDuplicateRequest() {
	const context = React.useContext(DuplicateRequestContext);

	if (!context)
		throw new Error('useDuplicateRequest must be used within an AuthProvider');

	return context;
}
