import React from 'react';
import { useHistory } from 'react-router-dom';

import * as yup from 'yup';
import { validateCPF, validatePhone } from 'validations-br';
import { useFormik } from 'formik';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import { useAuth } from '../../../hooks/auth';
import { useStore } from '../../../hooks/store';

import apiMotorSiv from '../../../services/apiMotorSiv';

const initialState = {
	nrCPF: '',
	dsNomeCompleto: '',
	nrLoja: '',
	dtNascimento: '',
	nrCelular: '',
	dsEmail: '',
	// 1 - Portal, 2 - APP
	flCanal: 1,
	flEmancipado: false,
	flInstrucao: false,
	nrMatriculaPromotor: '',
	dsNomePromotor: '',
	dsEmailPromotor: '',
};

const SimulateProposalContext = React.createContext({});

function inserirEspacoAposSegundoCaractere(texto) {
	if (texto.length >= 6) {
		const primeiraParte = texto.slice(0, 6);
		const segundaParte = texto.slice(6);
		return `${primeiraParte} ${segundaParte}`;
	}
	return texto;
}

function verificaSequenciaRepetida(str) {
	const regex = /(0{4,}|1{4,}|2{4,}|3{4,}|4{4,}|5{4,}|6{4,}|7{4,}|8{4,}|9{4,})/;
	return !regex.test(inserirEspacoAposSegundoCaractere(str));
}

function verificaSequenciaCrescente(texto) {
	const regex = /(0123|1234|2345|3456|4567|5678|6789)/;
	return !regex.test(texto);
}

const validationSchema = yup.object().shape({
	nrCPF: yup
		.string('Digite seu CPF')
		.test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
		.required('CPF é obrigatório'),
	dsNomeCompleto: yup
		.string('Digite seu nome completo')
		.matches(/[A-Za-z]{2,2} [A-Za-z]{2,2}/, 'Dever ter Nome e Sobrenome')
		.required('Nome é obrigatório'),
	dsEmail: yup.string('Digite seu e-mail'),
	nrCelular: yup
		.string('Digite seu número de telefone')
		.test('is-phone', 'Telefone não é válido', (value) => {
			if (value?.replace(/[^\d]/g, '').length > 6) {
				return validatePhone(value);
			}
		})
		.test(
			'is-phone',
			'Telefone com 4 ou mais números repetidos é inválido',
			(value) => {
				if (value?.length === 15) {
					return verificaSequenciaRepetida(value);
				}
			}
		)
		.test(
			'is-phone',
			'Telefone com sequência de 4 ou mais números é inválido',
			(value) => {
				if (value?.length === 15) {
					return verificaSequenciaCrescente(value);
				}
			}
		)
		.required('Número de telefone é obrigatório'),
});

export function SimulateProposalProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();
	const { store } = useStore();

	const [simulateProposal, setSimulateProposal] = React.useState([]);
	const [activeStep, setActiveStep] = React.useState(0);

	const [openMessage, setOpenMessage] = React.useState(false);
	const history = useHistory();

	const [permissionEP, setPermissionEP] = React.useState(false);
	const [permissionCARTAO, setPermissionCARTAO] = React.useState(false);
	const [permissionCDC, setPermissionCDC] = React.useState(false);

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const formik = useFormik({
		initialValues: initialState,
		validationSchema,
		onSubmit: async (data, { resetForm }) => {
			onChangeLoading({
				open: true,
				msg: 'Consultando...',
			});

			const objData = {
				dsEmail: data.dsEmail.toUpperCase(),
				dsNomeCompleto: data.dsNomeCompleto.toUpperCase().substring(0, 30),
				dtNascimento: null,
				flCanal: 1,
				flEmancipado: data.flEmancipado,
				flInstrucao: data.flInstrucao,
				nrCPF: data?.nrCPF?.toString().replace(/\.|-/gm, ''),
				nrCelular: data.nrCelular.replace(/[^\d]/g, ''),
				nrLoja: store,
				nrMatriculaPromotor: String(user.matricula),
				dsNomePromotor: String(user.name),
				dsEmailPromotor: String(user.email),
			};

			localStorage.setItem('@SF:ApprovedData', JSON.stringify(objData));

			await apiMotorSiv
				.post(`/ConsultarP1`, objData)
				.then((res) => {
					localStorage.setItem(
						'@SF:ApprovedResponse',
						JSON.stringify(res.data)
					);

					onChangeLoading({ open: false });

					setSimulateProposal(res.data);

					resetForm();
					handleNext();

					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Consulta realizada com sucesso! `,
					});
				})
				.catch(() => {
					onChangeLoading({ open: false });

					resetForm();

					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				});
		},
	});

	const handleSubmitCard = async () => {
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});
		await apiMotorSiv
			.get(`/select-cartao/${simulateProposal.nrCPF}`)
			.then((res) => {
				onChangeLoading({ open: false });
				window.open(res.data.url, '_blank');
			})
			.catch(() => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};
	const handleNewProposal = async () => {
		setActiveStep(0);
		history.push('/emprestimo/simular-proposta');
	};

	return (
		<SimulateProposalContext.Provider
			value={{
				simulateProposal,
				handleNewProposal,
				activeStep,
				handleBack,
				handleSubmitCard,
				formik,
				setActiveStep,
				openMessage,
				setOpenMessage,
				setPermissionCDC,
				setPermissionCARTAO,
				setPermissionEP,
				permissionCDC,
				permissionCARTAO,
				permissionEP,
			}}
		>
			{children}
		</SimulateProposalContext.Provider>
	);
}

export function useSimulateProposal() {
	const context = React.useContext(SimulateProposalContext);

	if (!context)
		throw new Error('useSimulateProposal must be used within an AuthProvider');

	return context;
}
