import React from 'react';
import { useAuth } from './auth';

const PermissionsContext = React.createContext({});

export function PermissionsProvider({ children }) {
	const { user } = useAuth();
	const categories = user?.categories;

	const checkCategoryPermission = (categoryId) => {
		const result = categoryPermission(categoryId);
		if (
			result?.permission_view === true &&
			result?.permission_register === true
		) {
			return true;
		}
		return false;
	};

	const checkFunctionPermission = (categoryId, functionId) => {
		if (checkCategoryPermission(categoryId)) {
			const result = functionPermission(functionId);
			if (
				result?.permission_view === true &&
				result?.permission_register === true
			) {
				return true;
			}
			return false;
		}
		return false;
	};

	const checkComponentPermission = (functionId, componentId) => {
		const result = componentPermission(functionId, componentId);
		if (
			result?.permission_view === true &&
			result?.permission_register === true
		) {
			return true;
		}
		return false;
	};

	const categoryPermission = (categoryId) =>
		categories?.find(({ category_id }) => category_id === categoryId)
			?.permissions;

	const functionPermission = (functionId) =>
		categories
			?.map(({ functionalities }) =>
				functionalities?.find(({ function_id }) => function_id === functionId)
			)
			?.find((id) => id !== undefined)?.permissions;

	const componentPermission = (functionId, componentId) =>
		categories
			?.map(({ functionalities }) =>
				functionalities?.find(({ function_id }) => function_id === functionId)
			)
			?.find((id) => id !== undefined)
			?.components?.map((components) => components)
			?.find(({ component_id }) => component_id === componentId)?.permissions;

	return (
		<PermissionsContext.Provider
			value={{
				checkCategoryPermission,
				checkFunctionPermission,
				checkComponentPermission,
			}}
		>
			{children}
		</PermissionsContext.Provider>
	);
}

export function usePermissions() {
	const context = React.useContext(PermissionsContext);

	if (!context)
		throw new Error('usePermissions must be used within a PermissionsProvider');

	return context;
}
