import React from 'react';

const StoreContext = React.createContext({
	store: String,
	onChangeStore: () => console.log('Not implementated yet'),
});

export function StoreProvider({ children }) {
	const [store, setStore] = React.useState('');
	const [modal, setModal] = React.useState(false);

	const onChangeStore = (data) => {
		localStorage.setItem('@SF:Store', data);
		setStore(data);
		setModal(false);
	};

	React.useEffect(() => {
		const item = JSON.parse(localStorage.getItem('@SF:Store'));
		if (item) {
			setStore(item);
		}
	}, []);

	return (
		<StoreContext.Provider value={{ store, onChangeStore, modal, setModal }}>
			{children}
		</StoreContext.Provider>
	);
}

export function useStore() {
	const context = React.useContext(StoreContext);

	if (!context) throw new Error('useStore must be used within an AuthProvider');

	return context;
}
