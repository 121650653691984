import React from 'react';
import * as XLSX from 'xlsx';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiAutomacao from '../../../services/apiAutomacao'
import apiCartao from '../../../services/apiCartao'

const LogPdvReportContext = React.createContext({
	onChangeBatchMonitor: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

LogPdvReportContext.displayName = 'Contexto de Relatório de Log do PDV';

export function LogPdvReportProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const [nrCartao, setNrCartao] = React.useState("")
	const [carregando, setCarregando] = React.useState(false)
	const [dados, setDados] = React.useState([])

	const consultWithFilter = async () => {
		setCarregando(true);

		const dataAtual = new Date();

		function formatarData(data) {
			const ano = data.getFullYear();
			const mes = String(data.getMonth() + 1).padStart(2, '0');
			const dia = String(data.getDate()).padStart(2, '0');
			return `${ano}-${mes}-${dia}`;
		}

		const dataAtualFormatada = formatarData(dataAtual);

		const payload = {
			cartao: nrCartao,
			dtInicio: '2023-01-01',
			dtFim: dataAtualFormatada,
		};

		await apiCartao
			.post(`/relatorio-pdv-filtro`, payload)
			.then((res) => {
				setDados(res.data);
				onChangeLoading({ open: false });
				setCarregando(false);
			})
			.catch((error) => {
				setCarregando(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const consultWithoutFilter = async () => {
		setCarregando(true);

		await apiCartao
			.get(`/relatorio-pdv`)
			.then((res) => {
				setDados(res.data);
				onChangeLoading({ open: false });
				setCarregando(false);
			})
			.catch((error) => {
				setCarregando(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};


	const handleConsult = async () => {
		if (nrCartao === "") {
			consultWithoutFilter()
		} else {
			consultWithFilter()
		}
	};

	function exportToExcel(jsonData, fileName) {
		const ws = XLSX.utils.json_to_sheet(jsonData);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});

		const url = URL.createObjectURL(data);
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		a.click();
		URL.revokeObjectURL(url);
	}

	return (
		<LogPdvReportContext.Provider
			value={{
				carregando,
				dados,
				setDados,
				handleConsult,
				nrCartao,
				setNrCartao,
				exportToExcel,
			}}
		>
			{children}
		</LogPdvReportContext.Provider>
	);
}

export function useLogPdvReport() {
	const context = React.useContext(LogPdvReportContext);

	if (!context)
		throw new Error('useLogPdvReport must be used within an AuthProvider');

	return context;
}
