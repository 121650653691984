import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { validateCPF, validatePhone } from 'validations-br';
import dayjs from 'dayjs';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';

import apiAutomacaoTangiveis from '../../../services/apiAutomacao'

const SmsParameterContext = React.createContext({
	onChangeSmsParameter: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

SmsParameterContext.displayName = 'Contexto parâmetro de SMS - Tangíveis';

export function SmsParameterProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();
	
	const [activeStep, setActiveStep] = React.useState(0);
	const [filterSmsType, setFilterSmsType] = React.useState(undefined)
	const [isLoading, setIsLoading] = React.useState(false)
	const [openModal, setOpenModal] = React.useState(false);
	const [modalData, setModalData] = React.useState(null);
	const [edit, setEdit] = React.useState(false);
	
	const [newStatus, setNewStatus] = React.useState('');
	const [smsType, setSmsType] = React.useState()
	const [brand, setBrand] = React.useState();
	const [logo, setLogo] = React.useState();
	const [newMessage, setNewMessage] = React.useState('')
	const [logoList, setLogoList] = React.useState([])
	const [disableSaveButton, setDisableSaveButton] = React.useState(true)
	const [data, setData] = React.useState([])
	const [selectedEditId, setSelectedEditId] = React.useState()

	const [reportData, setReportData] = React.useState([])
	const [dtInicial, setDtInicial] = React.useState('');
	const [dtFinal, setDtFinal] = React.useState('');
	const [carregando, setCarregando] = React.useState(false);

	const handleConsultReport = async () => {
		var _dataInicio = new Date(dtInicial);
		var _dataFim = new Date(dtFinal);
		var difTempo = _dataFim.getTime() - _dataInicio.getTime();
		var difDias = Math.ceil(difTempo / (1000 * 3600 * 24));

		if (dtInicial == '' || dtFinal == '') {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Preencha as datas de forma válida.',
			});
		} else if (difDias < 0) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'A data final não pode ser menor que a inicial.',
			});
		} else if (difDias > 30) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'O maior espaço de tempo para a consulta deve ser de até 31 dias.',
			});
		} else {
			setCarregando(true);
			const payload = {
				"dtInicial": dtInicial,
				"dtFinal": dtFinal,
			};

			await apiAutomacaoTangiveis
				.post(`/sms/query-relatorio`, payload)
				.then((res) => {
					setReportData(res.data);
					onChangeLoading({ open: false });
					setCarregando(false);
				})
				.catch((error) => {
					setCarregando(false);
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				});
		}
	};

	const handleSelectFilter = (selected) => {
		setFilterSmsType(selected);
	}

	const handleNext = () => {
		setActiveStep(1);
	};

	const handleBack = () => {
		setActiveStep(0);
		setFilterSmsType();
	};

	React.useEffect(() => {
		setDisableSaveButton(modalData?.dsMensagem === newMessage);
	},[newMessage])

	const handleOpenModalEdit = (ModalData) => {
		getLogo();
		setEdit(true);
		setSelectedEditId(ModalData?.idSmsParam);
		setSmsType(ModalData?.dsTipoSms);
		setBrand(ModalData?.cdNegocio);
		setNewMessage(ModalData?.dsMensagem);
		setLogo(ModalData?.cdLogo);
		setNewStatus(ModalData?.cdStatus);
		setModalData(ModalData);
		setOpenModal(true);
	};

	const handleOpenModalRegistration = () => {
		setModalData(null);
		setEdit(false);
		setSmsType(filterSmsType);
		setBrand("");
		setNewMessage("");
		setLogo("");
		setNewStatus(true);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setLogoList([])
		handleConsult();
		setDisableSaveButton(true);
	};

	const handleConsult = async () => {
		setIsLoading(true);

		const config = {
			headers: {
				cd_tipo_sms: filterSmsType.split(' ')[1],
			}
		};

		await apiAutomacaoTangiveis
			.get(`/sms/query`, config)
			.then((res) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				setData(res.data);
				handleRecordLog("parâmetros de sms", "consulta");
				handleNext();
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro na busca dos parâmetros de SMS, tente novamente!',
				});
			});
	}

	const getLogo = async () => {
		const payload = {
			cdNegocio: brand
		}

		await apiAutomacaoTangiveis
			.post(`/logo`, payload)
			.then((res) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				setLogoList(res.data);
			})
		.catch((error) => {
			setIsLoading(false);
			onChangeLoading({ open: false });
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Aconteceu algum erro na busca das logos, tente novamente!',
			});
		});
	}

	React.useEffect(() => {
		if (!!brand) {
			getLogo()
		}
	}, [brand])
	
	const handleSubmitEdit = async () => {
		const payload = {
			idSmsParam: selectedEditId,
			dsMensagem: newMessage,
			userId: user.login_ad,
		};

		const header = {
			headers: {
				id_param: selectedEditId,
			},
		};

		await apiAutomacaoTangiveis
			.put(`/sms/update-mensagem`, payload, header)
			.then((res) => {
				onChangeLoading({ open: false });
				onChangeNotify({
				open: true,
				class: 'success',
				msg: 'Atualização realizada com sucesso!',
				});
				handleRecordLog('parâmetros de sms', 'edição');
				handleCloseModal();
			})
		.catch((error) => {
			onChangeLoading({ open: false });
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Aconteceu algum erro na atualização, tente novamente!',
			});
		});
	}

	const handleSubmitCreate = async () => {
		const payload = {
			cdTipoSms: filterSmsType.split(' ')[1],
			cdLogo: Number(logo),
			dsTipoSms: filterSmsType,
			dsMensagem: newMessage,
			userId: user.login_ad,
		};

		await apiAutomacaoTangiveis
			.post(`/sms/insert-mensagem`, payload)
			.then((res) => {
				if (res.status === 200) {
					onChangeLoading({ open: false });
					onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Cadastro realizado com sucesso!',
					});
					handleRecordLog('parâmetros de sms', 'cadastro');
				handleCloseModal()
				} else {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Já existe mensagem ativa para essa logo. Para cadastrar uma nova, desative antes a mensagem atualmente ativa para essa logo.',
					});
				}
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro no cadastro, tente novamente!',
				});
			});
	}

	const handleUpdateStatus = async (e) => {
		const payload = {
			idSmsParam: selectedEditId,
			cdStatus: e.target.checked === true ? 1 : 0,
			cdTipoSms: Number(filterSmsType.split(' ')[1]),
			cdLogo: Number(logo),
			userId: user.login_ad,
		};

		const header = {
			headers: {
				id_param: selectedEditId,
			},
		};
		
		await apiAutomacaoTangiveis
			.put(`/sms/update-status`, payload, header)
			.then((res) => {
				if (res.status === 200) {
					setNewStatus(!e.target.checked);
					handleRecordLog('parâmetros de sms', 'alteracao status sms');
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'success',
						msg: 'Status atualizado com sucesso!',
					});
				} else {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Não é possível ativar duas ou mais menagens para um mesmo logo.',
					});
				}
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum na atualização do status, tente novamente!',
				});
			});
	}

	const handleRecordLog = async (screen, type) => {
		const payload = {
			loginAd: user.login_ad,
			dsTela: screen,
			dsTipo: type,
		};

		await apiAutomacaoTangiveis
			.post(`/log-acesso`, payload)
			.then((res) => {})
			.catch((error) => {});
	};

	return (
		<SmsParameterContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				filterSmsType,
				setFilterSmsType,
				handleSelectFilter,
				handleConsult,
				isLoading,
				handleOpenModalEdit,
				handleCloseModal,
				openModal,
				modalData,
				newMessage,
				setNewMessage,
				newStatus,
				setNewStatus,
				edit,
				handleOpenModalRegistration,
				smsType,
				brand,
				setBrand,
				logo,
				setLogo,
				logoList,
				disableSaveButton,
				data,
				handleSubmitEdit,
				handleSubmitCreate,
				handleUpdateStatus,
				handleRecordLog,
				reportData,
				setReportData,
				dtInicial,
				setDtInicial,
				dtFinal,
				setDtFinal,
				carregando,
				handleConsultReport,
			}}
		>
			{children}
		</SmsParameterContext.Provider>
	);
}

export function useSmsParameter() {
	const context = React.useContext(SmsParameterContext);

	if (!context)
		throw new Error('useAttendance must be used within an AuthProvider');

	return context;
}
