import React from 'react';
import { CircularProgress, Backdrop, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useLoading } from '../../hooks/loading';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

export default function Loading() {
	const classes = useStyles();

	const { loading } = useLoading();

	return (
		<Backdrop className={classes.backdrop} open={loading.open}>
			<CircularProgress color="inherit" />
			<Typography style={{ color: '#fff', marginLeft: 20 }} variant="h1">
				{loading.msg}
			</Typography>
		</Backdrop>
	);
}
