import React from 'react';
import { InvoiceProvider } from './Consult';
import { AnalyticalReportProvider } from './AnalyticalReport';
import { SummaryReportProvider } from './SummaryReport';
import { LogReportProvider } from './LogReport';

function TangibleProvider({ children }) {
	return (
		<InvoiceProvider>
			<AnalyticalReportProvider>
				<SummaryReportProvider>
					<LogReportProvider>{children}</LogReportProvider>
				</SummaryReportProvider>
			</AnalyticalReportProvider>
		</InvoiceProvider>
	);
}

export default TangibleProvider;
