import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { validateCPF, validatePhone } from 'validations-br';
import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';

import apiAutomacaoTangiveis from '../../../services/apiAutomacao'

const AttendanceContext = React.createContext({
	onChangeAttendance: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

AttendanceContext.displayName = 'Contexto acompanhamento - Tangíveis';

export function AttendanceProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();
	
	const [activeStep, setActiveStep] = React.useState(0);
	const [filter, setFilter] = React.useState()
	const [cpf, setCpf] = React.useState("");
	const [modalData, setModalData] = React.useState(null)
	const [data, setData] = React.useState([])
	const [trackingCode, setTrackingCode] = React.useState()

	const [DisableStepButton, setDisableStepButton] = React.useState(true)
	const [openModalDetail, setOpenModalDetail] = React.useState(false)
	const [isLoading, setIsLoading] = React.useState(false)

	const handleSelectFilter = (selected) => {
		setFilter(selected);
		setCpf('');
		setTrackingCode('');
		setDisableStepButton(true);
	}

	const handleNext = () => {
		setActiveStep(1);
	};

	const handleBack = () => {
		setActiveStep(0);
		setFilter();
		setCpf('');
		setTrackingCode('');
		setDisableStepButton(true);
	};

	const handleDisableStepButton = () => {
		if (filter === 1 && validateCPF(cpf)) {
			setDisableStepButton(false);
		} else if (filter === 2 && trackingCode !== '') {
			setDisableStepButton(false)
		} else {
			setDisableStepButton(true)
		}
	}

	React.useEffect(() => {
		handleDisableStepButton()
	}, [cpf, trackingCode])

	const handleConsult = async () => {
		setIsLoading(true);
		setDisableStepButton(true);

		const payload = {
			cpf: cpf !== '' ? cpf : 'null',
			cdRastreio: trackingCode !== '' ? trackingCode : 'null',
		};

		await apiAutomacaoTangiveis
			.post(`/acompanhamento`, payload)
			.then((res) => {
				setIsLoading(false);
				setDisableStepButton(false);
				if (res.status === 200) {
					onChangeLoading({ open: false });
					setData(res.data);
					handleRecordLog('acompanhamento', 'consulta');
					handleNext();
				} else if (res.status === 201) {
					onChangeLoading({ open: false });

					onChangeNotify({
						open: true,
						class: 'success',
						msg: 'Nenhum dado foi encontrado!',
					});
				}
			})
			.catch((error) => {
				setIsLoading(false);
				setDisableStepButton(false);
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro na busca das lojas, tente novamente!',
				});
			});
		
	}

	const handleOpenModalDatail = (arrModalData) => {
		setModalData(arrModalData);
		setOpenModalDetail(true);
	}
	
	const handleCloseModalDatail = () => {
		setOpenModalDetail(false);
	};

	const handleRecordLog = async (screen, type) => {
		const payload = {
			loginAd: user.login_ad,
			dsTela: screen,
			dsTipo: type,
		};

		await apiAutomacaoTangiveis
			.post(`/log-acesso`, payload)
			.then((res) => {})
			.catch((error) => {});
	};

	return (
		<AttendanceContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				filter,
				setFilter,
				cpf,
				setCpf,
				trackingCode,
				setTrackingCode,
				handleDisableStepButton,
				DisableStepButton,
				handleSelectFilter,
				handleConsult,
				handleOpenModalDatail,
				openModalDetail,
				handleCloseModalDatail,
				modalData,
				data,
				isLoading,
				handleRecordLog,
			}}
		>
			{children}
		</AttendanceContext.Provider>
	);
}

export function useAttendance() {
	const context = React.useContext(AttendanceContext);

	if (!context)
		throw new Error('useAttendance must be used within an AuthProvider');

	return context;
}
