import React from 'react';

const initialState = {
	open: false,
	horizontal: 'center',
	vertical: 'top',
	class: 'error',
	time: 4000,
	msg: '',
};

const CountdownContext = React.createContext({
	onChangeCountdown: () => console.log('Not implementated yet'),
});

export function CountdownProvider({ children }) {
	const initialMinute = 0;
	const initialSeconds = 30;

	const [minutes, setMinutes] = React.useState(initialMinute);
	const [seconds, setSeconds] = React.useState(initialSeconds);

	React.useEffect(() => {
		const myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval);
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	});


	return (
		<CountdownContext.Provider value={{ minutes, seconds, setSeconds }}>
			{children}
		</CountdownContext.Provider>
	);
}

export function useCountdown() {
	const context = React.useContext(CountdownContext);

	if (!context)
		throw new Error('useCountdown must be used within an AuthProvider');

	return context;
}
