import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';
import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiAuth from '../../../services/apiAuth';

const initialState = {
	dtInicial: new Date(),
	dtFinal: new Date(),
	dsLoginAd: null,
	dsLoginAdAbm: null,
	idGroup: null
};

const ReportAbmContext = React.createContext({
	ReportAbm: initialState,
	onChangeReportAbm: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

ReportAbmContext.displayName = "Contexto Relatório Log ABM"

const validationSchema = yup.object({
	dtInicial: yup
		.string('Digite a data Fiscal Inicial')
		//.test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
		.required('Data é obrigatório'),
		dtInicial: yup
		.string('Digite a data Fiscal Final')
		//.test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
		.required('Data é obrigatório'),
});

export function ReportAbmProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const [ReportAbm, setReportAbm] = React.useState(initialState);
	const [consultData, setConsultData] = React.useState([]);
	const [dataReal, setDataReal] = React.useState('');
	const [activeStep, setActiveStep] = React.useState(0);
	const [filter, setFilter] = React.useState()
	const [accessGroupList, setAccessGroupList] = React.useState([])

	const formik = useFormik({
		initialValues: ReportAbm,
		validationSchema,
		onSubmit: (data, { resetForm }) => {
			handleSubmit(data, resetForm);
			setDataReal(data);
		},
	});

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
		setFilter(undefined);
		formik.setFieldValue('dsLoginAd', '');
		formik.setFieldValue('idGroup', '');
	};

	const onChangeReportAbm = (data) => {
		setReportAbm({ ...ReportAbm, ...data });
	};

	const handleSubmit = async (data, resetForm) => {
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		const payload = {
			dtini: moment(data.dtInicial).format('YYYY-MM-DD 00:00:00'),
			dtfin: moment(data.dtFinal).format('YYYY-MM-DD 23:59:59'),
			user_login_ad: data.dsLoginAd === undefined ? '' : data.dsLoginAd,
			user_login_ad_abm: data.idGroup === undefined ? '' : data.dsLoginAdAbm,
			id_group: data.idGroup === undefined ? '' : data.idGroup,
		};

		await apiAuth
			.post(`log/event/query`, payload)
			.then((res) => {
				onChangeLoading({ open: false });

				if (res.status === 200) {
					setConsultData(res.data);
					handleNext();
					resetForm();

					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Consulta realizada com sucesso! `,
					});
				} else {
					resetForm();

					onChangeNotify({
						open: true,
						class: 'attention',
						msg: `Não encontramos dados! `,
					});
				}
			})
			.catch(() => {
				onChangeLoading({ open: false });

				resetForm();

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleSelectFilter = (selected) => {
		setFilter(selected);
		formik.setFieldValue('dsLoginAd', '');
		formik.setFieldValue('idGroup', '');
	};
	
	const getAccessGroupList = async () => {
		await apiAuth
			.get(`group/query`)
			.then((res) => {
				setAccessGroupList(res.data);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Erro ao consultar os grupos de acesso.',
				});
			});
	};

	return (
		<ReportAbmContext.Provider
			value={{
				ReportAbm,
				onChangeReportAbm,
				consultData,
				handleSubmit,
				activeStep,
				handleBack,
				handleNext,
				formik,
				dataReal,
				handleSelectFilter,
				filter,
				getAccessGroupList,
				accessGroupList,
			}}
		>
			{children}
		</ReportAbmContext.Provider>
	);
}

export function useReportAbm() {
	const context = React.useContext(ReportAbmContext);

	if (!context)
		throw new Error('useReportAbm must be used within an AuthProvider');

	return context;
}
