import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { validateCPF, validatePhone } from 'validations-br';
import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';

import apiAutomacaoTangiveis from '../../../services/apiAutomacao';

const ReportContext = React.createContext({
	onChangeReport: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

ReportContext.displayName = 'Contexto relatório - Tangíveis';

export function ReportProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();

	const [activeStep, setActiveStep] = React.useState(0);
	const [initialDate, setInitialDate] = React.useState('');
	const [finalDate, setFinalDate] = React.useState('');
	const [brand, setBrand] = React.useState(null);
	const [uf, setUF] = React.useState(null);
	const [reportType, setReportType] = React.useState(null);
	const [data, setData] = React.useState([]);
	const [ufList, setUfList] = React.useState([]);
	const [returnData, setReturnData] = React.useState([]);
	const [storeTrakingData, setStoreTrakingData] = React.useState([]);
	const [chooseTrakingTable, setChooseTrakingTable] = React.useState(1);

	const [openModalColumnFilter, setOpenModalColumnFilter] =
		React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [DisableStepButton, setDisableStepButton] = React.useState(true);

	const [filterColumn, setFilterColumn] = React.useState({
		name: true,
		phoneNumber: true,
		cpfNumber: true,
		uf: true,
		count: true,
		district: true,
		street: true,
		houseNumber: true,
		zipCode: true,
		complement: true,
		plasticNumber: true,
		passwordStatus: false,
		unlockChannel: false,
		unlockDate: false,
		unlockStatus: true,
		plasticCurrentLock: true,
		brandCard: false,
		postingMode: true,
		trackingCode: true,
		postDate: true,
		deliveryDate: true,
		returnReason: true,
		processingDate: true,
		returnDate: true,
		sms1: true,
		sms2: true,
		sms3: true,
		sms4: true,
		brand: true,
		logo: true,
		passwordChange: true,
		passwordConsult: true,
		trakingStatus: true,
		effectivationDate: true,
		saleChannel: true,
		ownership: true,
	});

	const handleChange = (e) => {
		const { name, checked } = e.target;
		setFilterColumn((prevState) => ({
			...prevState,
			[name]: checked,
		}));
	};

	const handleNext = () => {
		setActiveStep(1);
	};

	const handleBack = () => {
		setActiveStep(0);
		setDisableStepButton(true);
		setInitialDate('');
		setFinalDate('');
		setBrand(null);
		setUF(null);
		setChooseTrakingTable(1);
	};

	const handleDisableStepButton = () => {
		if (initialDate === '' || finalDate === '') {
			setDisableStepButton(true);
		} else {
			setDisableStepButton(false);
		}
	};

	React.useEffect(() => {
		handleDisableStepButton();
	}, [initialDate, finalDate, brand]);

	const handleOpenModalFilterColumn = () => {
		setOpenModalColumnFilter(true);
	};

	const handleCloseModalFilterColumn = () => {
		setOpenModalColumnFilter(false);
	};

	const handleConsultTracking = async () => {
		const payload = {
			filtros: {
				cdNegocio: brand,
				sgUf: uf,
				dtInicial: initialDate,
				dtFinal: finalDate,
			},
			colunas: {
				nome: filterColumn.name,
				nrTelefone: filterColumn.phoneNumber,
				nrCpf: filterColumn.cpfNumber,
				uf: filterColumn.uf,
				cidade: filterColumn.count,
				bairro: filterColumn.district,
				logradouro: filterColumn.street,
				nrResidencial: filterColumn.houseNumber,
				cep: filterColumn.zipCode,
				complemento: filterColumn.complement,
				nrPlatico: filterColumn.plasticNumber,
				statusSenha: filterColumn.passwordStatus,
				canalDesbloq: filterColumn.unlockChannel,
				dtDesbloqueio: filterColumn.unlockDate,
				statusDesbloq: filterColumn.unlockStatus,
				bloqueioAtualPlast: filterColumn.plasticCurrentLock,
				bandeiraCartao: filterColumn.brandCard,
				modPostagem: filterColumn.postingMode,
				cdRastreio: filterColumn.trackingCode,
				dtPostagem: filterColumn.postDate,
				dtEntrega: filterColumn.deliveryDate,
				motivoDevolucao: filterColumn.returnReason,
				dtProcessamento: filterColumn.processingDate,
				dtDevolucao: filterColumn.returnDate,
				sms1: filterColumn.sms1,
				sms2: filterColumn.sms2,
				sms3: filterColumn.sms3,
				sms4: filterColumn.sms4,
				brand: filterColumn.brand,
				logo: filterColumn.logo,
				dtConsultaSenha: filterColumn.passwordConsult,
				dtAlteracaoSenha: filterColumn.passwordChange,
				statusRastreio: filterColumn.trakingStatus,
				dtEfetivacao: filterColumn.effectivationDate,
				dsCanalDeVenda: filterColumn.saleChannel,
				dsTitularidade: filterColumn.ownership,
			},
		};

		const initDate = new Date(initialDate);
		const finDate = new Date(finalDate);
		const timeDiff = finDate.getTime() - initDate.getTime();
		const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

		if (initialDate == '' || finalDate == '') {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Preencha as datas de forma válida!',
			});
		} else if (daysDiff < 0) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'A data final não pode ser menor que a inicial.',
			});
		} else if (daysDiff > 30) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'O intervalo máximo entre data inicial e final deve ser de 31 dias.',
			});
		} else {
			setIsLoading(true);
			setDisableStepButton(true);

			await apiAutomacaoTangiveis
				.post(`/relatorio/rastreio-loja`, payload)
				.then((res) => {
					setStoreTrakingData(res.data);
				});

			await apiAutomacaoTangiveis
				.post(`/relatorio/rastreio-residencia`, payload)
				.then((res) => {
					setIsLoading(false);
					setDisableStepButton(false);
					handleRecordLog('relatório de rastreio', 'consulta');
					if (res.status === 200) {
						onChangeLoading({ open: false });
						setData(res.data);
						handleNext();
					} else if (res.status === 201) {
						onChangeLoading({ open: false });

						onChangeNotify({
							open: true,
							class: 'success',
							msg: 'Nenhum dado foi encontrado!',
						});
					}
				})
				.catch((error) => {
					setIsLoading(false);
					setDisableStepButton(false);
					onChangeLoading({ open: false });

					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro na busca, tente novamente!',
					});
				});
		}
	};

	const handleConsultCardReturn = async () => {
		const payload = {
			cdNegocio: brand,
			sgUf: uf,
			dtInicial: initialDate,
			dtFinal: finalDate,
		};

		const initDate = new Date(initialDate);
		const finDate = new Date(finalDate);
		const timeDiff = finDate.getTime() - initDate.getTime();
		const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

		if (initialDate == '' || finalDate == '') {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Preencha as datas de forma válida!',
			});
		} else if (daysDiff < 0) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'A data final não pode ser menor que a inicial.',
			});
		} else if (daysDiff > 30) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'O intervalo máximo entre data inicial e final deve ser de 31 dias.',
			});
		} else {
			setIsLoading(true);
			setDisableStepButton(true);

			await apiAutomacaoTangiveis
				.post(`/relatorio/devolucao`, payload)
				.then((res) => {
					setIsLoading(false);
					setDisableStepButton(false);
					handleRecordLog('relatorio de devolucao', 'consulta');

					onChangeLoading({ open: false });
					setReturnData(res.data);
					handleNext();
				})
				.catch((error) => {
					setIsLoading(false);
					setDisableStepButton(false);
					onChangeLoading({ open: false });

					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro na busca do relatório de devolução, tente novamente!',
					});
				});
		}
	};

	const handleError = async () => {
		const payload = {
			dtInicial: initialDate,
			dtFinal: finalDate,
		};

		const initDate = new Date(initialDate);
		const finDate = new Date(finalDate);
		const timeDiff = finDate.getTime() - initDate.getTime();
		const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

		if (initialDate == '' || finalDate == '') {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Preencha as datas de forma válida!',
			});
		} else if (daysDiff < 0) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'A data final não pode ser menor que a inicial.',
			});
		} else if (daysDiff > 30) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'O intervalo máximo entre data inicial e final deve ser de 31 dias.',
			});
		} else {
			setIsLoading(true);
			setDisableStepButton(true);

			await apiAutomacaoTangiveis
				.post(`/relatorio/erros`, payload)
				.then((res) => {
					setIsLoading(false);
					setDisableStepButton(false);
					handleRecordLog('relatório de erros de rastreio', 'extracao');

					onChangeLoading({ open: false });
					setReturnData(res.data);
				})
				.catch((error) => {
					setIsLoading(false);
					setDisableStepButton(false);
					onChangeLoading({ open: false });

					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro na busca do relatório de devolução, tente novamente!',
					});
				});
		}
	};

	const getUfs = async () => {
		const payload = {
			cdNegocio: brand,
		};

		if (brand !== null) {
			await apiAutomacaoTangiveis
				.post(`/uf`, payload)
				.then((res) => {
					setIsLoading(false);
					setUfList(res.data);

					onChangeLoading({ open: false });
				})
				.catch((error) => {
					setIsLoading(false);
					setDisableStepButton(false);
					onChangeLoading({ open: false });

					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro na busca das UFs.',
					});
				});
		}
	};

	const handleRecordLog = async (screen, type) => {
		const payload = {
			loginAd: user.login_ad,
			dsTela: screen,
			dsTipo: type,
		};

		await apiAutomacaoTangiveis
			.post(`/log-acesso`, payload)
			.then((res) => {})
			.catch((error) => {});
	};

	return (
		<ReportContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				initialDate,
				setInitialDate,
				finalDate,
				setFinalDate,
				brand,
				setBrand,
				uf,
				setUF,
				reportType,
				setReportType,
				handleOpenModalFilterColumn,
				handleCloseModalFilterColumn,
				openModalColumnFilter,
				filterColumn,
				handleChange,
				handleConsultTracking,
				data,
				isLoading,
				DisableStepButton,
				ufList,
				handleDisableStepButton,
				getUfs,
				setUfList,
				handleConsultCardReturn,
				returnData,
				setReturnData,
				handleRecordLog,
				chooseTrakingTable,
				setChooseTrakingTable,
				storeTrakingData,
				handleError,
			}}
		>
			{children}
		</ReportContext.Provider>
	);
}

export function useReport() {
	const context = React.useContext(ReportContext);

	if (!context)
		throw new Error('useReport must be used within an AuthProvider');

	return context;
}
