import React from 'react';
import { BatchMonitorProvider } from './BatchMonitor';
import { BatchMonitorEmailsProvider } from './BatchMonitorEmails';
import { FgtsReportProvider } from './FgtsReport';
import { LogPdvReportProvider } from './LogPdvReport';


function ReportProvider({ children }) {
	return (
    <BatchMonitorProvider>
      <BatchMonitorEmailsProvider>
        <FgtsReportProvider>
          <LogPdvReportProvider>
            {children}
          </LogPdvReportProvider>
        </FgtsReportProvider>
      </BatchMonitorEmailsProvider>
    </BatchMonitorProvider>
	);
}

export default ReportProvider;
