import React from 'react';
import Papa from 'papaparse';
import XLSX from 'xlsx';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { validateCPF, validatePhone } from 'validations-br';
import dayjs from 'dayjs';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';

import apiAutomacaoTangiveis from '../../../services/apiAutomacao'

const InventoryControlContext = React.createContext({
	onChangeInventoryControl: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

InventoryControlContext.displayName = 'Contexto de controle de estoque - Tangíveis';

export function InventoryControlProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();
	
	const [activeStep, setActiveStep] = React.useState(0);
	const [embossing, setEmbossing] = React.useState(null);
	const [isLoading, setIsLoading] = React.useState(false);
	const [openModal, setOpenModal] = React.useState(false);
	const [fileName, setFileName] = React.useState("");
	const [csv, setCsv] = React.useState([]);

	const handleCloseModal = () => {
		setCsv([]);
		setFileName("");
		setOpenModal(false)
	}

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleNext = () => {
		setActiveStep(1);
	};

	const handleBack = () => {
		setActiveStep(0);
	};

	const handleConsult = async () => {
		handleNext();
	}

	const handleDocument = async (event) => {
		Papa.parse(event.target.files[0], {
			// header: true,
			dynamicTyping: true,
			encoding: 'ISO-8859-1',
			skipEmptyLines: 'greedy', // ignora linhas vazias ou apenas com espaços após conversão
			complete: (results) => {
				setCsv(results.data);
				setFileName(event.target.files[0].name);
			},
		});
	};

	const handleUpload = () => {
		alert("AQUI")
		console.log(csv)
	}

	const handleRecordLog = async (screen, type) => {
		const payload = {
			loginAd: user.login_ad,
			dsTela: screen,
			dsTipo: type,
		};

		await apiAutomacaoTangiveis
			.post(`/log-acesso`, payload)
			.then((res) => {})
			.catch((error) => {});
	};

	return (
		<InventoryControlContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				handleConsult,
				embossing,
				setEmbossing,
				isLoading,
				setIsLoading,
				openModal,
				setOpenModal,
				handleCloseModal,
				handleOpenModal,
				fileName,
				setFileName,
				handleDocument,
				csv,
				setCsv,
				handleUpload,
				handleRecordLog,
			}}
		>
			{children}
		</InventoryControlContext.Provider>
	);
}

export function useInventoryControl() {
	const context = React.useContext(InventoryControlContext);

	if (!context)
		throw new Error('useAttendance must be used within an AuthProvider');

	return context;
}
