import React from 'react';
import dayjs from 'dayjs';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiAutomacaoTangiveis from '../../../services/apiAutomacao';

const BatchMonitorContext = React.createContext({
	onChangeBatchMonitor: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

BatchMonitorContext.displayName = 'Contexto de Monitor de lotes';

export function BatchMonitorProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	
	const [activeStep, setActiveStep] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [data, setData] = React.useState([])
	const [initialDate, setInitialDate] = React.useState("")
	const [finalDate, setFinalDate] = React.useState("")

	const handleNext = () => {
		setActiveStep(1);
		setIsLoading(false);
	};

	const handleBack = () => {
		setInitialDate('');
		setFinalDate("");
		setActiveStep(0);
		setIsLoading(false);
	};

	const handleConsult = async () => {
		setIsLoading(true);

		const payload = {
			dtInicial: initialDate,
			dtFinal: finalDate,
		};

		await apiAutomacaoTangiveis
			.post(`/monitor-lotes/email-log-envio`, payload)
			.then((res) => {
				setData(res.data);
				onChangeLoading({ open: false });
				handleNext();
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	}

	return (
		<BatchMonitorContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				handleConsult,
				isLoading,
				setIsLoading,
				initialDate,
				setInitialDate,
				finalDate,
				setFinalDate,
				data,
			}}
		>
			{children}
		</BatchMonitorContext.Provider>
	);
}

export function useBatchMonitor() {
	const context = React.useContext(BatchMonitorContext);

	if (!context)
		throw new Error('useBatchMonitor must be used within an AuthProvider');

	return context;
}
