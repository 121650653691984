import axios from 'axios';
import config from '../config';

const apiSiv = axios.create({
	baseURL:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_SIV_PROD
			: process.env.REACT_APP_SIV_HMG,
	headers: {
		'id-app': config.app_id,
		'apikey': config.apikeysiv,
		'x-api-key': config.xapikey,
	},
});

export default apiSiv;
