import axios from 'axios';
import config from '../config';

const apiAuth = axios.create({
	baseURL:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_AUTH_PROD
			: process.env.REACT_APP_AUTH_HMG,
	headers: {
		'id-app': config.app_id,
		apikey: config.apikeykong,
		'X-API-KEY': '9f1b2c4d-e4a6-4b8d-9c2a-6a7d3b9e0f0a',
	},
});

export default apiAuth;
