import axios from 'axios';
import config from '../config'

const apiMonitorLog = axios.create({
	baseURL:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_MONITOR_LOG_PROD
			: process.env.REACT_APP_MONITOR_LOG_HOMOLOG,
});

export default apiMonitorLog;
