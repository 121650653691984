import React from 'react';

const initialState = {
	open: false,
	msg: 'Carregando...',
};

const LoadingContext = React.createContext({
	loading: initialState,
});

export function LoadingProvider({ children }) {
	const [loading, setLoading] = React.useState(initialState);

	const onChangeLoading = (data) => {
		setLoading({ ...loading, ...data });
	};

	return (
		<LoadingContext.Provider value={{ loading, onChangeLoading }}>
			{children}
		</LoadingContext.Provider>
	);
}

export function useLoading() {
	const context = React.useContext(LoadingContext);

	if (!context)
		throw new Error('useLoading must be used within an AuthProvider');

	return context;
}
