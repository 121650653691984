import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { validateCPF, validatePhone } from 'validations-br';
import dayjs from 'dayjs';

import { useAuth } from '../../../hooks/auth';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiMonitorLog from '../../../services/apiMonitorLog';
import apiAutomacaoTangiveis from '../../../services/apiAutomacao';

const DataAppReportContext = React.createContext({
	onChangeDataAppReport: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

DataAppReportContext.displayName = 'Contexto de Relatório Geral - Super APP';

export function DataAppReportProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();

	const [activeStep, setActiveStep] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [data, setData] = React.useState([])
	const [page, setPage] = React.useState(1)
	const [paginationRange, setPaginationRange] = React.useState(0)
	const [dtInicial, setDtInicial] = React.useState('');
	const [dtFinal, setDtFinal] = React.useState('');

	const handleNext = () => {
		setActiveStep(1);
	};

	const handleBack = () => {
		setDtInicial('');
		setDtFinal('');
		setData([]);
		setActiveStep(0);
		setIsLoading(false);
	};

	const handleChange = (event, value) => {
		setPage(value);
	};

	const handleConsult = async () => {

		const payload = {
			dateStart: dtInicial,
			dateEnd: dtFinal,
		};

		var _dataInicio = new Date(dtInicial);
		var _dataFim = new Date(dtFinal);
		var difTempo = _dataFim.getTime() - _dataInicio.getTime();
		var difDias = Math.ceil(difTempo / (1000 * 3600 * 24));

		if (dtInicial == '' || dtFinal == '') {
			alert('Preencha as datas de forma válida!');
		} else if (difDias < 0) {
			alert('A data final não pode ser menor que a inicial.');
		} else if (difDias > 0) {
			alert('O maior espaço de tempo para a consulta deve ser de 1 dia.');
		} else {
			setIsLoading(true);
			await apiMonitorLog
				.post(`/request/list?page=${page}`, payload)
				.then(async (res) => {
					setData(res?.data?.data);
					setPaginationRange(res.data.totalPages);
					handleNext();

					setIsLoading(false);
					onChangeLoading({ open: false });
				})
				.catch((error) => {
					setIsLoading(false);
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				});
		}
	}

	const handleRecordLog = async (screen, type) => {
		const payload = {
			loginAd: user.login_ad,
			dsTela: screen,
			dsTipo: type,
		};

		await apiAutomacaoTangiveis
			.post(`/log-super-app/insert`, payload)
			.then((res) => {})
			.catch((error) => {});
	};

	return (
		<DataAppReportContext.Provider
			value={{
				handleNext,
				handleBack,
				activeStep,
				handleConsult,
				isLoading,
				setIsLoading,
				data,
				page,
				handleChange,
				paginationRange,
				handleRecordLog,
				dtInicial,
				setDtInicial,
				dtFinal,
				setDtFinal,
			}}
		>
			{children}
		</DataAppReportContext.Provider>
	);
}

export function useDataAppReport() {
	const context = React.useContext(DataAppReportContext);

	if (!context)
		throw new Error('useDataAppReport must be used within an AuthProvider');

	return context;
}
