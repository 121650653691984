import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Loading from '../components/Loading';

import Route from './Route';

const SimulateProposal = lazy(() =>
	import('../pages/PersonalLoan/SimulateProposal')
);

const AuthLogin = lazy(() => import('../pages/Login'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const PersonalLoan = lazy(() => import('../pages/PersonalLoan'));
const SimulateCdc = lazy(() => import('../pages/PersonalLoan/SimulateCdc'));
const SimulateEp = lazy(() => import('../pages/PersonalLoan/SimulateEp'));

const ConsultProposal = lazy(() =>
	import('../pages/PersonalLoan/ConsultProposal')
);
const ProposalRegistrationEp = lazy(() =>
	import('../pages/PersonalLoan/ProposalRegistrationEp')
);
const ProposalRegistrationCdc = lazy(() =>
	import('../pages/PersonalLoan/ProposalRegistrationCdc')
);

const Parameters = lazy(() => import('../pages/Security/Parameters'));
const ProductsLimits = lazy(() =>
	import('../pages/Security/Parameters/ProductsLimits')
);
const ProductsParameters = lazy(() =>
	import('../pages/Security/Parameters/ProductsParameters')
);
const SmsParameters = lazy(() =>
	import('../pages/Security/Parameters/SmsParameters')
);
const ProgressiveDiscount = lazy(() =>
	import('../pages/Security/ProgressiveDiscount')
);
const DuplicateRequest = lazy(() =>
	import('../pages/PersonalLoan/DuplicateRequest')
);
const ControlCreditCdc = lazy(() =>
	import('../pages/PersonalLoan/ControlCreditCdc')
);
const ProductsRegistrationCDC = lazy(() =>
	import('../pages/Security/ProductsCDC/ProductsRegistration')
);

const GroupStoreManagement = lazy(() =>
	import('../pages/Security/GroupStoreManagement')
);
const UserManagement = lazy(() => import('../pages/Security/UserManagement'));
const ScriptManagement = lazy(() =>
	import('../pages/Security/ScriptManagement')
);
const ReportConc = lazy(() => import('../pages/PersonalLoan/ReportConc'));
const Certificate = lazy(() => import('../pages/PersonalLoan/Certificate'));
const PdvGrid = lazy(() => import('../pages/SalesMonitoring/PdvGrid'));
const ApprovalHistory = lazy(() =>
	import('../pages/SalesMonitoring/ApprovalHistory')
);
const Ranking = lazy(() => import('../pages/SalesMonitoring/Ranking'));

const ProductManagementCDC = lazy(() =>
	import('../pages/Security/ProductsCDC/ProductManagement')
);

const LogAbm = lazy(() => import('../pages/Security/LogAbm'));
const LogAccess = lazy(() => import('../pages/Security/LogAccess'));

const ProductsParamCDC = lazy(() =>
	import('../pages/Security/ProductsCDC/Parametrization')
);
const ProfileManagement = lazy(() =>
	import('../pages/Security/ProfileManagement')
);

const Conversion = lazy(() => import('../pages/SalesMonitoring/Conversion'));
const PointOfSaleOperator = lazy(() =>
	import('../pages/SalesMonitoring/PointOfSaleOperator')
);

const Attendance = lazy(() => import('../pages/Tangible/Attendance'));
const Report = lazy(() => import('../pages/Tangible/Report'));
const SmsParameter = lazy(() => import('../pages/Tangible/SmsParameter'));
const DashboardTangiveis = lazy(() => import('../pages/Tangible/Dashboard'));
const InventoryControl = lazy(() =>
	import('../pages/Tangible/InventoryControl')
);
const Invoice = lazy(() => import('../pages/Invoice/Consult'));

const AttendanceSuperApp = lazy(() => import('../pages/SuperApp/Attendance'));
const AccessReport = lazy(() => import('../pages/SuperApp/AccessReport/index'));
const DataAppReport = lazy(() =>
	import('../pages/SuperApp/DataAppReport/index')
);
const LogsDataAppReport = lazy(() =>
	import('../pages/SuperApp/LogSuperAppReport')
);

const BatchMonitor = lazy(() => import('../pages/Report/BatchMonitor'));
const BatchMonitorEmails = lazy(() =>
	import('../pages/Report/BatchMonitorEmails')
);
const FgtsReport = lazy(() => import('../pages/Report/FgtsReport'));
const LogPdvReport = lazy(() => import('../pages/Report/LogPdvReport'));

const ConsultInvoice = lazy(() => import('../pages/Invoice/Consult'));
const AnalyticalReport = lazy(() =>
	import('../pages/Invoice/AnalyticalReport')
);
const SummaryReport = lazy(() => import('../pages/Invoice/SummaryReport'));
const LogReport = lazy(() => import('../pages/Invoice/LogReport'));

const MonitoringDashboard = lazy(() => import('../pages/Monitoring/Dashboard'));
const apiHealth = lazy(() => import('../pages/Monitoring/Dashboard/apiHealth'));
const filesMonitoring = lazy(() =>
	import('../pages/Monitoring/Dashboard/filesGrid')
);
const filesByArea = lazy(() =>
	import('../pages/Monitoring/Dashboard/filesByArea')
);

const ConsultClient = lazy(() => import('../pages/Cencopay/Consult'));

function Routes() {
	return (
		<AnimatePresence>
			<Suspense fallback={<Loading />}>
				<Switch>
					<Route exact path="/" component={AuthLogin} />
					<Route isPrivate path="/dashboard" exact component={Dashboard} />
					<Route
						isPrivate
						path="/relatorio/conciliacao"
						exact
						component={ReportConc}
					/>
					<Route isPrivate path="/dashboard" exact component={Dashboard} />
					<Route
						isPrivate
						path="/certificados/servicos"
						exact
						component={Certificate}
					/>
					<Route isPrivate path="/emprestimo" exact component={PersonalLoan} />
					<Route
						isPrivate
						path="/emprestimo/simular-proposta"
						exact
						component={SimulateProposal}
					/>
					<Route
						isPrivate
						path="/emprestimo/simular-cdc"
						exact
						component={SimulateCdc}
					/>
					<Route
						isPrivate
						path="/emprestimo/simular-ep"
						exact
						component={SimulateEp}
					/>
					<Route
						isPrivate
						path="/emprestimo/consultar-proposta"
						exact
						component={ConsultProposal}
					/>
					<Route
						isPrivate
						path="/emprestimo/controle-credito-cdc"
						exact
						component={ControlCreditCdc}
					/>
					<Route
						isPrivate
						path="/emprestimo/solicitacao-2-via"
						exact
						component={DuplicateRequest}
					/>
					<Route
						isPrivate
						path="/emprestimo/cadastro-proposta-cdc"
						exact
						component={ProposalRegistrationCdc}
					/>
					<Route
						isPrivate
						path="/emprestimo/cadastro-proposta-ep"
						exact
						component={ProposalRegistrationEp}
					/>
					{/* <Route
						isPrivate
						path="/seguranca/parametros-produtos-cdc"
						exact
						component={ProductsParamCDC}
					/> */}
					{/* <Route
						isPrivate
						path="/seguranca/cadastro-produtos-cdc"
						exact
						component={ProductsRegistrationCDC}
					/> */}
					<Route
						isPrivate
						path="/seguranca/produtos-cdc-parametro"
						exact
						component={ConsultProposal}
					/>
					<Route
						isPrivate
						path="/seguranca/gerenciamento-grupos-lojas"
						exact
						component={GroupStoreManagement}
					/>
					{/* <Route
						isPrivate
						path="/seguranca/gerenciamento-grupos"
						exact
						component={GroupManagement}
					/> */}
					<Route
						isPrivate
						path="/seguranca/gerenciamento-perfil"
						exact
						component={ProfileManagement}
					/>
					<Route
						isPrivate
						path="/seguranca/gerenciamento-usuarios"
						exact
						component={UserManagement}
					/>
					<Route
						isPrivate
						path="/seguranca/gerenciamento-scripts"
						exact
						component={ScriptManagement}
					/>
					<Route
						isPrivate
						path="/seguranca/parametros"
						exact
						component={Parameters}
					/>
					<Route
						isPrivate
						path="/seguranca/parametros-sms"
						exact
						component={SmsParameters}
					/>
					<Route
						isPrivate
						path="/seguranca/parametros-produtos"
						exact
						component={ProductsParameters}
					/>
					<Route
						isPrivate
						path="/seguranca/parametros-limite-produto"
						exact
						component={ProductsLimits}
					/>
					<Route
						isPrivate
						path="/seguranca/gerenciamento-produtos-cdc"
						exact
						component={ProductManagementCDC}
					/>
					<Route isPrivate path="/seguranca/log-abm" exact component={LogAbm} />
					<Route
						isPrivate
						path="/seguranca/log-access"
						exact
						component={LogAccess}
					/>
					<Route
						isPrivate
						path="/seguranca/desconto-progrressivo"
						exact
						component={ProgressiveDiscount}
					/>
					<Route
						isPrivate
						path="/monitor-vendas/pdv"
						exact
						component={PdvGrid}
					/>
					<Route
						isPrivate
						path="/monitor-vendas/historic"
						exact
						component={ApprovalHistory}
					/>
					<Route
						isPrivate
						path="/monitor-vendas/ranking"
						exact
						component={Ranking}
					/>
					<Route
						isPrivate
						path="/monitor-vendas/conversion"
						exact
						component={Conversion}
					/>
					<Route
						isPrivate
						path="/monitor-vendas/operador-pdv"
						exact
						component={PointOfSaleOperator}
					/>
					<Route
						isPrivate
						path="/tangiveis/acompanhamento"
						exact
						component={Attendance}
					/>
					<Route
						isPrivate
						path="/tangiveis/relatorio"
						exact
						component={Report}
					/>
					<Route
						isPrivate
						path="/tangiveis/parametro-sms"
						exact
						component={SmsParameter}
					/>
					<Route
						isPrivate
						path="/tangiveis/dashboard"
						exact
						component={DashboardTangiveis}
					/>
					<Route
						isPrivate
						path="/tangiveis/estoque"
						exact
						component={InventoryControl}
					/>

					<Route
						isPrivate
						path="/super-app/atendimento"
						exact
						component={AttendanceSuperApp}
					/>

					<Route
						isPrivate
						path="/super-app/relatorio-acesso"
						exact
						component={AccessReport}
					/>

					<Route
						isPrivate
						path="/super-app/relatorio-dados"
						exact
						component={DataAppReport}
					/>

					<Route
						isPrivate
						path="/super-app/log-relatorio-dados"
						exact
						component={LogsDataAppReport}
					/>

					<Route
						isPrivate
						path="/relatorio/monitor-lotes"
						exact
						component={BatchMonitor}
					/>

					<Route
						isPrivate
						path="/relatorio/cadastro-email-monitor-lotes"
						exact
						component={BatchMonitorEmails}
					/>

					<Route
						isPrivate
						path="/relatorio/fgts"
						exact
						component={FgtsReport}
					/>

					<Route
						isPrivate
						path="/relatorio/log-pdv"
						exact
						component={LogPdvReport}
					/>

					<Route
						isPrivate
						path="/fatura/consulta"
						exact
						component={ConsultInvoice}
					/>

					<Route
						isPrivate
						path="/fatura/relatorio-analitico"
						exact
						component={AnalyticalReport}
					/>

					<Route
						isPrivate
						path="/fatura/relatorio-sintetico"
						exact
						component={SummaryReport}
					/>

					<Route isPrivate path="/fatura/log" exact component={LogReport} />

					<Route
						// isPrivate
						path="/monitoramento/dashboard"
						exact
						component={MonitoringDashboard}
					/>

					<Route
						// isPrivate
						path="/monitoramento/api-health"
						exact
						component={apiHealth}
					/>

					<Route
						// isPrivate
						path="/monitoramento/arquivos"
						exact
						component={filesMonitoring}
					/>

					<Route
						// isPrivate
						path="/monitoramento/arquivos-por-area"
						exact
						component={filesByArea}
					/>
					<Route
						isPrivate
						path="/cencopay/consulta"
						exact
						component={ConsultClient}
					/>
				</Switch>
			</Suspense>
		</AnimatePresence>
	);
}

export default Routes;
