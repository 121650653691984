import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { validateCPF, validatePhone } from 'validations-br';
import dayjs from 'dayjs';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';

import apiCliente from '../../../services/apiCliente';
import apiAutenticacao from '../../../services/apiAutenticacao'

const AttendanceContext = React.createContext({
	onChangeAttendance: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

AttendanceContext.displayName = 'Contexto de Atendimento - Super APP';

export function AttendanceProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();
	
	const [activeStep, setActiveStep] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [cpf, setCpf] = React.useState('')
	const [data, setData] = React.useState([])
	const [isUpdated, setIsUpdated] = React.useState(true)

	const handleNext = () => {
		setActiveStep(1);
	};

	const handleBack = () => {
		setCpf('')
		setActiveStep(0);
	};

	const handleConsult = async () => {
		setIsLoading(true);
		await apiCliente
		.get(`/consultar-dados-cliente/${cpf.replace(".", "").replace(".", "").replace("-", "")}`)
		.then((res) => {
			setIsLoading(false);
			onChangeLoading({ open: false });
			setData(res.data);
			if (res.data !== "") {
				handleNext();
			} else {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Cliente não encontrado!',
				});
			}
		})
		.catch((error) => {
			setIsLoading(false);
			onChangeLoading({ open: false });

			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Aconteceu algum erro na busca do cliente, tente novamente!',
			});
		});
	}

	const handleUpdateData = async () => {

		const payload = {
			nrCpfCompleto: data.dadosResidenciaisFis.nrCpfCompleto,
			nrCartaoCompleto: data.dadosResidenciaisFis.nrCartaoCompleto,
			idLogin: data.dadosResidenciaisFis.idLogin,
			origem: data.dadosResidenciaisFis.origem,
			flAtualizarFis: false,
			nrCelular: data.dadosResidenciaisFis.nrCelular,
			dsEmail: data.dadosResidenciaisFis.dsEmail,
			nrCep: data.dadosResidenciaisFis.nrCep,
			dsLogradouro: data.dadosResidenciaisFis.dsLogradouro,
			nrLogradouro: data.dadosResidenciaisFis.nrLogradouro,
			dsBairro: data.dadosResidenciaisFis.dsBairro,
			dsCidade: data.dadosResidenciaisFis.dsCidade,
			sgUf: data.dadosResidenciaisFis.sgUf,
			dsComplemento: data.dadosResidenciaisFis.dsComplemento,
		};
		await apiCliente
			.put(`/alterar-cliente`, payload)
			.then((res) => {
				onChangeLoading({ open: false });
				handleConsult();
			})
			.catch((error) => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro na busca do cliente, tente novamente!',
				});
			});
	};

	const handleSendToken = async () => {
		const payolad = {
			nrCpfCompleto: cpf.replace(/\.|-/gm, ''),
		};

		await apiAutenticacao
			.put(`/renviar-tokensms/`, payolad)
			.then(async (res) => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Token enviado com sucesso!',
				});
			})
			.catch((error) => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro no envio do token, tente novamente!',
				});
			});
	}

	React.useEffect(() => {
		if (data.length === 0) {
			setIsUpdated(true)
		} else {
			setIsUpdated(
				data.dadosResidenciaisLocal?.nrCpfCompleto?.toLowerCase() ==
					data.dadosResidenciaisFis?.nrCpfCompleto?.toLowerCase() &&
					data.dadosResidenciaisLocal?.nrCep ==
						data.dadosResidenciaisFis?.nrCep &&
					data.dadosResidenciaisLocal?.dsLogradouro?.toLowerCase() ==
						data.dadosResidenciaisFis?.dsLogradouro?.toLowerCase() &&
					data.dadosResidenciaisLocal?.nrLogradouro?.toLowerCase() ==
						data.dadosResidenciaisFis?.nrLogradouro?.toLowerCase() &&
					data.dadosResidenciaisLocal?.dsBairro?.toLowerCase() ==
						data.dadosResidenciaisFis?.dsBairro?.toLowerCase() &&
					data.dadosResidenciaisLocal?.dsCidade?.toLowerCase() ==
						data.dadosResidenciaisFis?.dsCidade?.toLowerCase() &&
					data.dadosResidenciaisLocal?.sgUf?.toLowerCase() ==
						data.dadosResidenciaisFis?.sgUf?.toLowerCase() &&
					data.dadosResidenciaisLocal?.dsComplemento?.toLowerCase() ==
						data.dadosResidenciaisFis?.dsComplemento?.toLowerCase() &&
					data.dadosResidenciaisLocal?.dsEmail?.toLowerCase() ==
						data.dadosResidenciaisFis?.dsEmail?.toLowerCase() &&
					data.dadosResidenciaisLocal?.nrCelular ==
						data.dadosResidenciaisFis?.nrCelular
			);
		}
	}, [data]);

	return (
		<AttendanceContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				handleConsult,
				isLoading,
				setIsLoading,
				cpf,
				setCpf,
				data,
				isUpdated,
				handleUpdateData,
				handleSendToken,
			}}
		>
			{children}
		</AttendanceContext.Provider>
	);
}

export function useAttendance() {
	const context = React.useContext(AttendanceContext);

	if (!context)
		throw new Error('useAttendance must be used within an AuthProvider');

	return context;
}
