export default {
	basename: '/',
	app_id:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_ID_PROD
			: process.env.REACT_APP_ID_HMG,
	apikeykong: 'uDdNPuhYtDv1d7SPb68wXJsJQ6n7ezeU',
	apikeymotorsiv: 'NcG5XMXmcaxKtJNxpm9MdteGxSYc0esW',
	apikeysiv:
		'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpbl9hZCI6InBldHBzYW50b3MiLCJhcHBfaWQiOiIxZGRjODQxYy1mYjk0LTExZWMtYmVkNS0wMjQyYWMxMTAwMGYiLCJyZXF1ZXN0ZXIiOiJFcXVpcGUgU0ZCIFRFU1RFIiwiZXhwIjoxNzA3OTk4MjY2fQ.2tU_eP89sY1Job7gmYXZGmA7EiWvOxzebcvWWXtD46g',
	xapikey: 'hjbMdvh2so89aBWhSQj2381rHrrtik4J489yOrXe',
	apikey:
		'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpbl9hZCI6InBldHBzYW50b3MiLCJhcHBfaWQiOiIwNGJhOWNhMi00YzU5LTExZWMtOWYxMy0wMjQyYWMxMTAwMDQiLCJyZXF1ZXN0ZXIiOiJFcXVpcGUgU0YiLCJleHAiOjE2NzU1MjQzMjF9.pfIPfNFEr8iSVCsY7XJo3Lvkwh1oO_L2RuDHjA7GF2E',
	apikeyCencopay: 'ZZEBD8PeAC2SfHejwxCJl1KyPyNRPuWF3wLDKsVW',
	apikeyFaturaS3: 'hjbMdvh2so89aBWhSQj2381rHrrtik4J489yOrXe',
};
