import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@material-ui/core';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { usePermissions } from '../../../../../hooks/permissions';

const useStyles = makeStyles((theme) => ({
	listIcon: {
		minWidth: '25px',
	},
	listItem: {
		borderRadius: '5px',
		marginBottom: '5px',
	},
	subMenuCaption: {
		...theme.typography.subMenuCaption,
	},
	listItemNoBack: {
		paddingTop: '8px',
		paddingBottom: '8px',
		borderRadius: '5px',
	},
	errorChip: {
		color: theme.palette.error.main,
		backgroundColor: '#ffcdd2',
		marginRight: '20px',
	},
}));

function NavItem(props) {
	const classes = useStyles();
	const { checkFunctionPermission } = usePermissions();
	const { item, level } = props;

	const Icon = item.icon;
	const itemIcon = item.icon ? (
		<Icon className={classes.listCustomIcon} color="inherit" />
	) : (
		<ArrowForwardIcon
			className={classes.listCustomIcon}
			color="inherit"
			fontSize={level > 0 ? 'inherit' : 'default'}
		/>
	);

	const itemIconClass = !item.icon ? classes.listIcon : classes.menuIcon;	

	return (
		<ListItem
			// disabled={!item.permissions.permission_view}
			disabled={!checkFunctionPermission(item.category_id, item.function_id)}
			className={level > 1 ? classes.listItemNoBack : classes.listItem}
			component={Link}
			to={item.function_route}
			button
			style={{ paddingLeft: `${level * 16}px` }}
		>
			<ListItemIcon className={itemIconClass}>{itemIcon}</ListItemIcon>
			<ListItemText
				primary={
					<Typography
						variant="subtitle1"
						className={classes.listItemTypography}
					>
						{item.function_name}
					</Typography>
				}
			/>
		</ListItem>
	);
}

export default NavItem;
