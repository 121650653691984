import React, { useEffect } from 'react';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';

import apiCencopay from '../../../services/apiCencopay-gwUsersS3';

const ConsultContext = React.createContext({
	onChangeConsultClient: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

ConsultContext.displayName = 'Contexto de Atendimento - Super APP';

export function ConsultProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();

	const [activeStep, setActiveStep] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [cpf, setCpf] = React.useState('');
	const [data, setData] = React.useState([]);

	const itemsPerPage = 5;

	const totalpages = 0;

	const handleNext = () => {
		setActiveStep((prevActiveStep) =>
			prevActiveStep === 0 ? 1 : prevActiveStep
		);
	};

	const handleBack = () => {
		setActiveStep(0);
	};

	const handleConsult = async (tipo, page) => {
		setIsLoading(true);
		let url = '';
		let errorMessage = '';

		switch (tipo) {
			case 1:
				url = `/users/${cpf
					.replace('.', '')
					.replace('.', '')
					.replace('-', '')}`;
				errorMessage = 'Cliente não encontrado!';
				break;
			case 2:
				url = `/Wallet/${cpf
					.replace('.', '')
					.replace('.', '')
					.replace('-', '')}/${page}/${itemsPerPage}`;
				errorMessage = 'Extratos não encontrados!';
				break;
			case 3:
				url = `/CashInCampaign/${cpf
					.replace('.', '')
					.replace('.', '')
					.replace('-', '')}/${page}/${itemsPerPage}`;
				errorMessage = 'Pendências não encontradas!';
				break;
			default:
				setIsLoading(false);
				return;
		}

		try {
			const res = await apiCencopay.get(url);
			setIsLoading(false);
			onChangeLoading({ open: false });

			let isValidData = false;

			if (Array.isArray(res.data)) {
				if (res.data.length > 0) {
					isValidData = true;
				} else {
					onChangeNotify({
						open: true,
						class: 'error',
						msg: errorMessage,
					});
				}
			} else if (typeof res.data === 'object' && res.data !== null) {
				if (res.data.onboarding === null) {
					isValidData = true;
				} else {
					onChangeNotify({
						open: true,
						class: 'error',
						msg: errorMessage,
					});
				}
			} else {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: errorMessage,
				});
			}

			if (isValidData) {
				handleNext();
				setData(res.data);
				setActiveStep(tipo);
				onChangeNotify({ open: false });
			}
		} catch (error) {
			setIsLoading(false);
			onChangeLoading({ open: false });
			onChangeNotify({
				open: true,
				class: 'error',
				msg: `Aconteceu algum erro na busca: ${errorMessage}, tente novamente!`,
			});
		}
	};

	return (
		<ConsultContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				setActiveStep,
				handleConsult,
				isLoading,
				setIsLoading,
				cpf,
				setCpf,
				data,
				itemsPerPage,
				totalpages,
			}}
		>
			{children}
		</ConsultContext.Provider>
	);
}

export function useConsult() {
	const context = React.useContext(ConsultContext);

	if (!context)
		throw new Error('useConsultClient must be used within an AuthProvider');

	return context;
}
