import React from 'react';
import * as yup from 'yup';
import { validateCPF } from 'validations-br';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiSiv from '../../../services/apiSiv';
import { daysToWeeks } from 'date-fns/esm';

const initialState = {
	nrPedido: 0
};

const CertificateContext = React.createContext({
	Certificate: initialState,
	onChangeCertificate: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

const validationSchema = yup.object({
	nrPedido: yup
		.number('Digite codigo do Pedido')
		//.test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
		.required('É obrigatório'),
});

export function CertificateProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const [Certificate, setCertificate] = React.useState(initialState);
	const [consultData, setConsultData] = React.useState([]);
	const [oneData, setOneData] = React.useState([]);
	const [dataReal, setDataReal] = React.useState('');
	const [activeStep, setActiveStep] = React.useState(0);

	const formik = useFormik({
		initialValues: Certificate,
		validationSchema,
		onSubmit: (data, { resetForm }) => {
			handleSubmit(data, resetForm);
			setDataReal(data);
		},
	});

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const handleSelectOneData = (item) => {
		const filterOne = consultData.servicos.filter(
			(itemOne) => itemOne.servico_id === item
		);
		setOneData(filterOne[0]);
		handleNext();
	};

	const onChangeCertificate = (data) => {
		setCertificate({ ...Certificate, ...data });
	};

	const handleSubmit = async (data, resetForm) => {
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		const config = {
			headers: { 'id-pedido': data.nrPedido },
		};

		await apiSiv
			.get(`order/query`, config)
			.then((res) => {
				onChangeLoading({ open: false });

				if (res.status === 200) {
					setConsultData(res.data);
					handleNext();
					resetForm();

					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Consulta realizada com sucesso! `,
					});
				} else {
					resetForm();

					onChangeNotify({
						open: true,
						class: 'attention',
						msg: `Não encontramos dados! `,
					});
				}
			})
			.catch(() => {
				onChangeLoading({ open: false });

				resetForm();

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	return (
		<CertificateContext.Provider
			value={{
				Certificate,
				onChangeCertificate,
				consultData,
				handleSubmit,
				activeStep,
				handleBack,
				handleNext,
				handleSelectOneData,
				formik,
				dataReal,
				oneData,
			}}
		>
			{children}
		</CertificateContext.Provider>
	);
}

export function useCertificate() {
	const context = React.useContext(CertificateContext);

	if (!context)
		throw new Error('useCertificate must be used within an AuthProvider');

	return context;
}
