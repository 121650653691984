import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText, Typography } from '@material-ui/core';

import NavGroup from './NavGroup';

import { useAuth } from '../../../../hooks/auth';

function MenuList() {
	const { user } = useAuth();

	const navItems = user.categories.map((item) => (
		<NavGroup key={item.category_id} item={item} />
	));

	return (
		<>
			<ListItem component={Link} to="/" button style={{ paddingLeft: 16 }}>
				<ListItemText
					primary={<Typography variant="subtitle1">Dashboard</Typography>}
				/>
			</ListItem>
			{navItems}
		</>
	);
}

export default MenuList;
