import React from 'react';
import Papa from 'papaparse';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import config from '../../../config';
import apiAuth from '../../../services/apiAuth';

const initialState = {
	login_ad: '',
	name: '',
	group: '',
	group_store: '',
	store: [],
	app_id: '',
	matricula: '',
	user_id: '',
	tpSelecaoLoja: '1',
};

const UserManagementContext = React.createContext({
	userManagement: initialState,
	onChangeUserManagement: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

UserManagementContext.displayName = "Contexto gerenciamento de Usuários"

const validationSchema = yup.object().shape({
	login_ad: yup.string().required('UsuárioAD é obrigatório'),
	matricula: yup.string().required('Matrícula é obrigatório'),
	name: yup.string().required('Nome é obrigatório'),
	group: yup.object().required('Grupo de acesso é obrigatório'),
	group_store: yup.object().when('tpSelecaoLoja', {
		is: (tpSelecaoLoja) => tpSelecaoLoja === '1',
		then: yup.object().nullable().required('Grupo de lojas é obrigatório'),
		otherwise: yup.object().nullable(),
	}),
});

export function UserManagementProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const [userManagement, setUserManagement] = React.useState(initialState);
	const [userData, setUserData] = React.useState('');
	const [accessGroupList, setAccessGroupList] = React.useState([]);
	const [storeGroupList, setStoreGroupList] = React.useState([]);
	const [userStoreGroupList, setUserStoreGroupList] = React.useState('');
	const [storeList, setStoreList] = React.useState([]);
	const [userList, setUserList] = React.useState([]);
	const [activeStep, setActiveStep] = React.useState(0);
	const [editMode, setEditMode] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [massive, setMassive] = React.useState(false);
	const [individual, setIndividual] = React.useState(false);
	const [modalEditUser, setModalEditUser] = React.useState(false);
	const [fileName, setFileName] = React.useState('');
	const [csv, setCsv] = React.useState([]);
	const [groupList, setGroupList] = React.useState([]);
	const [groupStoreList, setGroupStoreList] = React.useState([]);
	const [lists, setLists] = React.useState(false);
	const [massiveUserList, setMassiveUserList] = React.useState([]);
	const [massiveGroupStoreList, setMassiveGroupStoreList] = React.useState([]);
	const [registrationResult, setRegistrationResult] = React.useState([]);
	const [register, setRegister] = React.useState(true);
	const [userState, setUserState] = React.useState();
	const [renderControl, setRenderControl] = React.useState(true);
	const [disableMassiveRegistration, setDisableMassiveRegistration] = React.useState(false)
	const [isUpdateUserGroupSoreList, setIsUpdateGroupStoreList] = React.useState(false)
	const [idUserGroupStore, setIdUserGroupStore] = React.useState()
	let newMassiveUserList = [];

	const brandList = [
		{ label: 'BRETAS', id: 1 },
		{ label: 'PREZUNIC', id: 2 },
		{ label: 'GBARBOSA', id: 3 },
		{ label: 'MERCANTIL RODRIGUES', id: 4 },
		{ label: 'PERINI', id: 5 },
	];

	const statusList = [
		{ id: 0, label: 'Ativo', value: true, color: 'green' },
		{ id: 1, label: 'Inativo', value: false, color: 'red' },
		{ id: 2, label: 'Bloqueado', value: false, color: 'yellow' },
	];

	const handleBack = () => {
		setActiveStep(activeStep - 1);
		setEditMode(false);
	};
	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};
	const onChangeUserManagement = (data) => {
		setUserManagement({ ...userManagement, ...data });
	};

	const getAccessGroupList = async () => {
		await apiAuth
			.get(`group/query`)
			.then((res) => {
				setAccessGroupList(res.data);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Erro ao consultar os grupos de acesso.',
				});
			});
	};

	const getUserStoreGroupList = async (data) => {
		const settings = {
			headers: {
				'id-group-store': data.group_store_id,
			},
		};
		await apiAuth
			.get(`group-store/query`, settings)
			.then((res) => {
				setUserStoreGroupList(
					res.data.list_stores.sort((a, b) => a.store_id - b.store_id)
				);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Erro ao consultar os grupos de lojas do usuário.',
				});
			});
	};
	const getStoreGroupList = async () => {
		await apiAuth
			.get(`group-store/query`)
			.then((res) => {
				setStoreGroupList(res.data);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Erro ao consultar os grupos de lojas.',
				});
			});
	};

	const getStoreList = async () => {
		await apiAuth
			.get(`store/query`)
			.then((res) => {
				setStoreList(res.data);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Erro ao consultar as lojas.',
				});
			});
	};

	const getUserList = async () => {
		const settings = {
			headers: { 'app-id': config?.app_id },
		};
		await apiAuth.get('user/query', settings).then((res) => {
			const newList = JSON.parse(
				JSON.stringify(res.data, (key, value) =>
					typeof value === 'string' ? value.toUpperCase() : value
				)
			);
			setUserList(newList.sort((a, b) => a.name.localeCompare(b.name)));
		});
	};

	const insertUserGroupStore = (data, brandIdList) => {
		const groupData = {
			name: `GRUPO DE ${data.login_ad?.toUpperCase()}`,
			description: `LOJAS DE ${data.login_ad?.toUpperCase()}`,
			brand_id:
				brandIdList
					.map((brandId) => brandId === brandIdList[0])
					.find((id) => id === false) === false
					? 0
					: brandIdList[0],
			group_store_type: 'user',
			login_ad: data.login_ad,
			list_stores: data.store.map((store) => store.store_id),
		};
		return apiAuth.post(`group-store/insert`, groupData);
	};

	const updateUserGroupStore = (userUpdate, brandIdList) => {
		const groupData = {
			name: null,
			description: null,
			brand_id:
				brandIdList
					.map((brandId) => brandId === brandIdList[0])
					.find((id) => id === false) === false
					? 0
					: brandIdList[0],
			group_store_type: userUpdate?.group_store_type,
			login_ad: userUpdate?.login_ad,
			list_stores: userUpdate?.store.map((store) => store.store_id),
		};

		const settings = {
			headers: { 'id-group-store': userUpdate.group_store_id },
		};

		apiAuth
			.put(`group-store/update`, groupData, settings)
			.then((res) => {
				setEditMode(false)
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'success',
					msg: `Atualização realizada com sucesso! `,
				});
			})
			.catch(() => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleEdit = () => {
		setEditMode(!editMode);
		getAccessGroupList();
	};

	const editUser = async (data) => {
		setUserData(data);
		getStoreList();
		await getStoreGroupList(data);
		await getUserStoreGroupList(data);
		setModalEditUser(true);
	};

	const handleCloseModalEditUser = async () => {
		setModalEditUser(false);
		setEditMode(false);
		await getUserList();
		setRenderControl(!renderControl)
	};

	const handleUpdate = async (userUpdate) => {

		setIsUpdateGroupStoreList(false);
		onChangeLoading({
			open: true,
			msg: 'Atualizando usuário...',
		});

		if (userUpdate.group_store_id === undefined) {
			setIsUpdateGroupStoreList(true);
			const brandIdList = userUpdate?.store?.map((store) => store.brand_id);
			const respApi = await insertUserGroupStore(userUpdate, brandIdList);
			setIdUserGroupStore(respApi?.data?.group_store_id);
		}

		const User = localStorage.getItem('@SF:User');
		const userObj = JSON.parse(User);

		const payloadInsert = {
			headers: {
				'login-ad-abm': userObj.login_ad,
			},
		};

		const objDataStatus = [
			{
				group_id: userUpdate?.group_id,
				login_ad: userData?.login_ad?.toLowerCase(),
				name: userUpdate?.name?.toLowerCase(),
				group_store_id: isUpdateUserGroupSoreList ? idUserGroupStore : userUpdate?.group_store_id,
				store_id: userUpdate?.store[0]?.store_id,
				matricula: userData?.user_matricula,
			},
		];

		const payloadStatus = {
			headers: {
				'user-id': userUpdate.user_id.toLowerCase(),
				status: userState,
				'login-ad-abm': userObj.login_ad,
			},
		};

		if (
			userUpdate.group_store_type === 'user' &&
			userUpdate?.stores !== userStoreGroupList
		) {
			const brandIdList = userUpdate?.store?.map((store) => store.brand_id);
			updateUserGroupStore(userUpdate, brandIdList);
			const objData = [
				{
					login_ad: userUpdate?.login_ad?.toLowerCase(),
					name: userUpdate?.name?.toLowerCase(),
					group_id: userUpdate?.group_id,
					group_store_id: isUpdateUserGroupSoreList
						? idUserGroupStore
						: userUpdate?.group_store_id,
					store_id: userUpdate?.store[0]?.store_id,
					app_id: config?.app_id,
					matricula: `${userUpdate?.user_matricula}`,
				},
			];
			await apiAuth
				.post('user/insert', objData, payloadInsert)
				.then((res) => {
					handleCloseModalEditUser();
					onChangeLoading({ open: false });
					handleEdit();
					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Atualização realizada com sucesso! `,
					});
				})
				.catch(() => {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				});
			
			await apiAuth
				.put('user/status', objDataStatus, payloadStatus)
				.then((res) => {})
				.catch(() => {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				});
		} else {
			const objData = [
				{
					login_ad: userData?.login_ad?.toLowerCase().trim(),
					name: userUpdate?.name?.toLowerCase(),
					group_id: userUpdate?.group_id,
					group_store_id: isUpdateUserGroupSoreList
						? idUserGroupStore
						: userUpdate?.group_store_id,
					store_id: userUpdate?.store[0]?.store_id,
					app_id: config?.app_id,
					matricula: `${userData?.user_matricula}`,
				},
			];

			await apiAuth
				.post('user/insert', objData, payloadInsert)
				.then(() => {
					handleCloseModalEditUser();
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Atualização realizada com sucesso! `,
					});
				})
				.catch(() => {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				});

			const payload = {
				headers: {
					'user-id': userUpdate.user_id.toLowerCase(),
					status: userState,
					'login-ad-abm': userObj.login_ad,
				},
			};
			

			await apiAuth
				.put('user/status', objDataStatus, payload)
				.then((res) => {})
				.catch(() => {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				});
		}
	};

	const formik = useFormik({
		initialValues: initialState,
		validationSchema,
		onSubmit: async (data, { resetForm }) => {
			onChangeLoading({
				open: true,
				msg: 'Criando usuário...',
			});

			if (data.group_store === '') {
				const brandIdList = data?.store?.map((store) => store.brand_id);
				const respApi = await insertUserGroupStore(data, brandIdList);
				const User = localStorage.getItem('@SF:User');
				const userObj = JSON.parse(User);

				const payloadInsert = {
					headers: {
						'login-ad-abm': userObj.login_ad,
					},
				};

				const objData = [
					{
						login_ad: data?.login_ad?.toLowerCase().trim(),
						name: data?.name?.toLowerCase(),
						group_id: data?.group?.group_id,
						group_store_id: respApi?.data?.group_store_id,
						store_id: data?.store[0]?.store_id,
						app_id: config?.app_id,
						matricula: data?.matricula?.toLowerCase(),
					},
				];

				await apiAuth
					.post('user/insert', objData, payloadInsert)
					.then((res) => {
						onChangeLoading({ open: false });
						setUserData(res.data);
						if (res.data.status === 202) {
							onChangeNotify({
								open: true,
								class: 'attention',
								msg: `Usuário já está cadastrado! `,
							});
						} else if (res.status === 201) {
							onChangeNotify({
								open: true,
								class: 'success',
								msg: `Cadastro realizado com sucesso! `,
							});
						}
						resetForm();
					})
					.catch(() => {
						onChangeLoading({ open: false });
						onChangeNotify({
							open: true,
							class: 'error',
							msg: 'Aconteceu algum erro, tente novamente!',
						});
					});
			} else {
				const User = localStorage.getItem('@SF:User');
				const userObj = JSON.parse(User);

				const payloadInsert = {
					headers: {
						'login-ad-abm': userObj.login_ad,
					},
				};

				const objData = [
					{
						login_ad: data?.login_ad?.toLowerCase(),
						name: data?.name?.toLowerCase(),
						group_id: data?.group?.group_id,
						group_store_id: data?.group_store?.group_store_id,
						store_id: data?.group_store?.list_stores[0].store_id,
						app_id: config?.app_id,
						matricula: data?.matricula?.toLowerCase(),
					},
				];
				await apiAuth
					.post('user/insert', objData, payloadInsert)
					.then((res) => {
						onChangeLoading({ open: false });
						setUserData(res.data);
						if (res.data.cod_status === 200) {
							onChangeNotify({
								open: true,
								class: 'attention',
								msg: `Usuário atualizado! `,
							});
						} else if (res.data.cod_status === 201) {
							onChangeNotify({
								open: true,
								class: 'success',
								msg: `Cadastro realizado com sucesso! `,
							});
						}
						resetForm();
					})
					.catch(() => {
						onChangeLoading({ open: false });
						onChangeNotify({
							open: true,
							class: 'error',
							msg: 'Aconteceu algum erro, tente novamente!',
						});
					});
			}
		},
	});

	// Cadastro massivo de usuários

	const handleDocument = (event) => {
		Papa.parse(event.target.files[0], {
			header: true,
			dynamicTyping: true,
			skipEmptyLines: 'greedy', // ignora linhas vazias ou apenas com espaços após conversão
			complete: (results) => {
				setCsv(results.data);
				setFileName(event.target.files[0].name);
				setMassiveUserList(results.data?.map((user) => user));
				setMassiveGroupStoreList(
					results.data
						?.map((user) =>
							user?.list_stores === null && user?.group_store_id !== null
								? null
								: {
										...user,
										name: `GRUPO DE ${user.login_ad.toUpperCase()}`,
										description: `LOJAS DE ${user.login_ad.toUpperCase()}`,
										group_store_type: 'user',
										list_stores: JSON.parse(`[${user.list_stores}]`),
								  }
						)
						.filter((id) => id !== null)
				);
			},
		});
		getStoreList();
	};

	const insertMassiveGroupStoreList = async () => {
		await apiAuth
			.post(`group-store/insert`, massiveGroupStoreList)
			.then((res) => {
				if (Array.isArray(res.data)) {
					newMassiveUserList = massiveUserList
						?.map(
							(massiveUser) =>
								res?.data
									?.map(
										(user) =>
											massiveUser.login_ad === user.user_login_ad && {
												...massiveUser,
												group_store_id: user.group_store_id,
											}
									)
									?.find((id) => id !== false) || massiveUser
						)
						?.map((newUser) => ({
							...newUser,
							name: newUser.name?.toLowerCase(),
							login_ad: newUser.login_ad?.toLowerCase().trim(),
							app_id: config.app_id,
						}));
				} else {
					newMassiveUserList = massiveUserList.map((user) => ({
						...user,
						name: user.name?.toLowerCase(),
						login_ad: user.login_ad?.toLowerCase().trim(),
						group_store_id: res.data.group_store_id,
						app_id: config.app_id,
					}));
				}
			});
		return newMassiveUserList;
	};

	const handleMassive = async () => {
		onChangeLoading({ open: true });
		setDisableMassiveRegistration(true)

		const User = localStorage.getItem('@SF:User');
		const userObj = JSON.parse(User);

		const payloadInsert = {
			headers: {
				'login-ad-abm': userObj.login_ad,
			},
		};
		if (massiveGroupStoreList.length !== 0) {
			await insertMassiveGroupStoreList(massiveGroupStoreList);
			await apiAuth
				.post('user/insert', newMassiveUserList, payloadInsert)
				.then((res) => {
					onChangeLoading({ open: false });
					Array.isArray(res.data)
						? setRegistrationResult(res.data)
						: setRegistrationResult([res.data]);
					setRegister(false);
					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Cadastro realizado com sucesso! `,
					});
					setDisableMassiveRegistration(false);
				})
				.catch(() => {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
					setDisableMassiveRegistration(false);
				});
		} else {
			newMassiveUserList = massiveUserList.map((newUser) => ({
				...newUser,
				name: newUser.name?.toLowerCase(),
				login_ad: newUser.login_ad?.toLowerCase(),
				app_id: config.app_id,
			}));
			await apiAuth
				.post('user/insert', newMassiveUserList, payloadInsert)
				.then((res) => {
					onChangeLoading({ open: false });
					Array.isArray(res.data)
						? setRegistrationResult(res.data)
						: setRegistrationResult([res.data]);
					setRegister(false);
					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Cadastro realizado com sucesso! `,
					});
					setDisableMassiveRegistration(false);
				})
				.catch(() => {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
					setDisableMassiveRegistration(false);
				});
		}
	};

	const handleCloseMassive = async () => {
		setMassive(false);
		setCsv([]);
		setFileName('');
		setLists(false);
		setRegister(true);
		await getUserList();
		setRenderControl(!renderControl);
	};
	const handleNewRegistration = () => {
		setRegister(true);
		setCsv([]);
		setFileName('');
	};
	const handleCloseIndividual = async () => {
		setIndividual(false);
		await getUserList();
		setRenderControl(!renderControl);
	};

	// Listas

	const getGroupList = async () => {
		const groupSettings = {
			headers: {
				'id-app': config.app_id,
			},
		};
		await apiAuth
			.get('group/query', groupSettings)
			.then((groupRes) => {
				setGroupList(groupRes.data);
				getGroupStoreList();
			})
			.catch(() => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: false,
					class: 'error',
					msg: `Erro ao consultar os grupos`,
				});
			});
	};

	const getGroupStoreList = async () => {
		const groupStoreSettings = {
			headers: {
				'group-store-type': 'system',
			},
		};
		await apiAuth
			.get('group-store/query', groupStoreSettings)
			.then((groupStoreRes) => {
				onChangeLoading({ open: false });
				setGroupStoreList(groupStoreRes.data);
			})
			.catch(() => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: false,
					class: 'error',
					msg: `Erro ao consultar os grupos de lojas`,
				});
			});
	};

	const getLists = async () => {
		getGroupList();
	};

	const handleRegistration = () => {
		setLists(false);
	};

	return (
		<UserManagementContext.Provider
			value={{
				userManagement,
				onChangeUserManagement,
				activeStep,
				setActiveStep,
				handleBack,
				userData,
				handleEdit,
				editMode,
				setEditMode,
				handleUpdate,
				open,
				formik,
				// formikSearch,
				getAccessGroupList,
				accessGroupList,
				getStoreGroupList,
				getUserStoreGroupList,
				userStoreGroupList,
				storeGroupList,
				getStoreList,
				storeList,
				getUserList,
				userList,
				handleDocument,
				massive,
				individual,
				setMassive,
				setIndividual,
				handleMassive,
				handleCloseMassive,
				handleDocument,
				handleCloseIndividual,
				csv,
				setCsv,
				fileName,
				massiveUserList,
				massiveGroupStoreList,
				register,
				registrationResult,
				handleNewRegistration,
				getLists,
				groupList,
				groupStoreList,
				lists,
				setLists,
				handleRegistration,
				brandList,
				editUser,
				modalEditUser,
				setModalEditUser,
				handleCloseModalEditUser,
				statusList,
				setUserState,
				renderControl,
				disableMassiveRegistration,
			}}
		>
			{children}
		</UserManagementContext.Provider>
	);
}

export function useUserManagement() {
	const context = React.useContext(UserManagementContext);

	if (!context)
		throw new Error(
			'useUserManagement must be used within an UserManagementProvider'
		);

	return context;
}
