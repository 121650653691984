import axios from 'axios';
import config from '../config';

const apiAutomacaoTangiveis = axios.create({
	baseURL:
		// 'http://localhost:9010',
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_AUTOMACAO_TANGIVEIS_PROD
			: process.env.REACT_APP_AUTOMACAO_TANGIVEIS_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key':
			process.env.REACT_APP_PROD === 'true'
				? config.xapikey
				: '7NrqjTKpp45GxcBGmHl0v5rjaCGZYhNO82J9C7SJ',
	},
});

export default apiAutomacaoTangiveis;
