import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import { useAuth } from '../../../hooks/auth';
import { useStore } from '../../../hooks/store';

import apiMotorSiv from '../../../services/apiMotorSiv';
import apiSiv from '../../../services/apiSiv';
import apiIdentify from '../../../services/apiIdentify';

import RemoveStorageData from '../../../utils/removeLocalStorage';

const tz = require('moment-timezone');

const initialState = {
	cpf: '',
	dueDate: '',
	maximumApprovedValue: '',
	requestedValue: '',
	storeSeller: '',
	selectedProduct: '',
	extendedWarranty: '',
	registration: '',
	productValue: '',
	product: [],
	service: [],
};

const SimulateCdcContext = React.createContext({
	simulateCdc: initialState,
	onChangeSimulateCdc: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

export function SimulateCdcProvider({ children }) {
	const history = useHistory();
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();
	const { store } = useStore();
	//	const { setSeconds } = useCountdown();

	const [loadingProduct, setLoadingProduct] = React.useState(false);

	const [simulateCdc, setSimulateCdc] = React.useState(initialState);

	const [product, setProduct] = React.useState([]);
	const [services, setServices] = React.useState([]);
	const [safe, setSafe] = React.useState([]);
	const [products, setProducts] = React.useState([]);
	const [date, setDate] = React.useState();
	const [serviceValue, setServiceValue] = React.useState(0);

	const [dayAfter, setDayAfter] = React.useState();
	const [dayBefore, setDayBefore] = React.useState();

	const [activeStep, setActiveStep] = React.useState(0);

	const [proposals, setProposals] = React.useState([]);

	const [openModalIdentify, setOpenModalIdentify] = React.useState(false);
	const [openModalQrCodeIdentify, setOpenModalQrCodeIdentify] = React.useState(false);
	
	const [dataIdentify, setDataIdentify] = React.useState([]);

	const [validToken, setValidToken] = React.useState(false);

	const [loadingToken, setLoadingToken] = React.useState(false);
	const [countToken, setCountToken] = React.useState(0);

	const [loadingIdentify, setLoadingIdentify] = React.useState(false);
	const [messageIdentify, setMessageIdentify] = React.useState('');

	const [selectedValue, setSelectedValue] = React.useState('');

	const [counter, setCounter] = React.useState(10);

	const [openModalService, setOpenModalService] = React.useState(false);
	const [openModalToken, setOpenModalToken] = React.useState(false);
	const [triggerIdentify, setTriggerIdentify] = React.useState(false);

	const [selectedId, setSelectedId] = React.useState();
	const initialMinute = 0;
	const initialSeconds = 30;

	const [minutes, setMinutes] = React.useState(initialMinute);
	const [seconds, setSeconds] = React.useState(initialSeconds);

	const [digitalSignature, setDigitalSignature] = React.useState(false);
	const [triggerSignature, setTriggerSignature] = React.useState(false);
	const [openModalDigitalSignature, setOpenModalDigitalSignature] =
		React.useState(false);
	const [controleSignature, setControleSignature] = React.useState(false);
	const [dataDigitalSignature, setDataDigitalSignature] = React.useState([]);

	const [openCancelToken, setOpenCancelToken] = React.useState(false);
	const [controleIdentify, setControleIdentify] = React.useState(false);

	const [disableEstoqueLoja, setDisableEstoqueLoja] = React.useState(true);
	const [disableEstoqueCd, setDisableEstoqueCd] = React.useState(true);
	const [selectedRetirada, setSelectedRetirada] = React.useState('');

	const [openModalDisapproval, setOpenModalDisapproval] = React.useState(false);
	const [messageDisapproval, setMessageDisapproval] = React.useState('');

	const [shipping, setShipping] = React.useState(0);

	const onChangeSimulateCdc = (data) => {
		setSimulateCdc({ ...simulateCdc, ...data });
	};

	React.useEffect(() => {
		const myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval);
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	}, [seconds]);

	const handleRedirect = () => {
		RemoveStorageData();
		history.push('/emprestimo/simular-proposta');
		
		// localStorage.removeItem('@SF:ApprovedData');
		// localStorage.removeItem('@SF:ApprovedResponse');
		// localStorage.removeItem('@SF:Service');
		// localStorage.removeItem('@SF:ServiceData');
		// localStorage.removeItem('@SF:Product');
		// localStorage.removeItem('@SF:SimulateProposalCdc');
		// localStorage.removeItem('@SF:ServiceGe');
		// localStorage.removeItem('@SF:ServiceFr');
		// localStorage.removeItem('@SF:ServiceCb');
		// localStorage.removeItem('@SF:ParcelaCdc');
	};

	const handleCloseModalDigitalSignature = () => {
		setOpenModalDigitalSignature(false);
	};

	const handleCancelModalDigitalSignature = () => {
		setOpenModalDigitalSignature(false);
		history.push('/emprestimo/simular-proposta');
	};

	const handleCancelModalToken = () => {
		setOpenModalToken(false);
		setOpenCancelToken(false);
		handleRedirect();
	};

	const handleCancelProposal = () => {
		setOpenCancelToken(true);
	};

	const handleCloseCancelProposal = () => {
		setOpenCancelToken(false);
	};

	const formik = useFormik({
		initialValues: initialState,
		onSubmit: (data, { resetForm }) => {
			handleSubmitProposals(data, resetForm);
		},
	});

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	function verifyPlan(planer) {
		if (planer.plano.length > 0) {
			onChangeLoading({ open: false });
			setProposals(planer);
			handleNext();
			onChangeNotify({
				open: true,
				class: 'success',
				msg: `Parcelas geradas com sucesso! `,
			});
		} else {
			onChangeLoading({ open: false });
			setProposals(planer);
			handleNext();
			onChangeNotify({
				open: true,
				class: 'attention',
				msg: `Parcelas excede limite do plano! `,
			});
		}
	}

	const handleSubmitProposals = async (data, resetForm) => {
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});

		const simulateProposalData = localStorage.getItem('@SF:ApprovedData');
		const objSimulateProposalData = JSON.parse(simulateProposalData);

		const simulateProposalResponse = localStorage.getItem(
			'@SF:ApprovedResponse'
		);
		const objSimulateProposalResponse = JSON.parse(simulateProposalResponse);

		const ServiceGe = localStorage.getItem('@SF:ServiceGe');
		const valueGe = ServiceGe !== '' ? JSON.parse(ServiceGe) : 0;

		const ServiceCb = localStorage.getItem('@SF:ServiceCb');
		const valueCb = ServiceCb !== '' ? JSON.parse(ServiceCb) : 0;

		const ServiceDa = localStorage.getItem('@SF:ServiceDa');
		const valueDa = ServiceDa !== '' ? JSON.parse(ServiceDa) : 0;

		const ServiceFr = localStorage.getItem('@SF:ServiceFr');
		const valueFr = ServiceFr !== '' ? JSON.parse(ServiceFr) : 0;

		const objServiceData = {
			nmServico: 'serviços',
			vlServico: (valueGe?.valorGe || 0) + valueCb + valueDa + valueFr,
		};

		localStorage.setItem('@SF:ServiceData', JSON.stringify(objServiceData));

		const objData = {
			nrCPF: objSimulateProposalData?.nrCPF,
			dsNomeCompleto: objSimulateProposalData.dsNomeCompleto,
			nrLoja: store,
			dtNascimento: '1997-07-27T15:00:00.000Z',
			flTipoSimulacao: 2,
			vlCredito:
				product.dataPos?.valorOferta +
					(valueGe?.valorGe || 0) +
					valueCb +
					valueDa +
					valueFr +
					Number.parseFloat(shipping) >
				objSimulateProposalResponse?.cdc?.vlLimite
					? objSimulateProposalResponse?.cdc?.vlLimite
					: product.dataPos?.valorOferta +
					  (valueGe?.valorGe || 0) +
					  valueCb +
					  valueDa +
					  valueFr +
					  Number.parseFloat(shipping),
			vlLimiteParcela: objSimulateProposalResponse?.cdc?.vlLimiteParcela,
			qtParcelas: 0,
			dtVencimento: date,
			cdProduto: String(product.codigoPlu),
			vlProduto: product?.dataPos?.valorOferta,
			nrMatriculaVendedor: String(user.matricula),
		};

		localStorage.setItem('@SF:SimulateProposalCdc', JSON.stringify(objData));

		await apiMotorSiv
			.post(`/simular-proposta`, objData)
			.then((res) => {
				verifyPlan(res.data);
			})
			.catch((err) => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: `Algo deu errado, tente novamente! `,
				});
			});
	};

	const listProducts = async () => {
		onChangeLoading({
			open: true,
		});
		const config = {
			headers: { 'codigo-loja': store },
		};
		await apiSiv
			.get(`/product/query`, config)
			.then((res) => {
				setProducts(res.data);
				onChangeLoading({ open: false });
			})
			.catch((err) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: `Algo deu errado, lista de produtos indisponível!`,
				});
			});
	};

	const listProduct = async (id) => {
		setSelectedRetirada('');
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});

		const config = {
			headers: {
				'codigo-loja': store,
				'codigo-plu': id,
			},
		};

		await apiSiv
			.get('/product/query', config)
			.then((res) => {
				onChangeLoading({ open: false });
				if (
					res.data === null ||
					res.data === undefined ||
					res.data.length === 0 ||
					!res.data
				) {
					onChangeNotify({
						open: true,
						class: 'error',
						msg: `Produto não cadastrado!`,
					});
					setSelectedId();
				} else if (
					res.data.dataInventory.estoqueLoja[0].estoqueloja === 0 ||
					res.data.dataInventory.estoqueLoja[0].estoqueloja === 0.0
				) {
					if (
						res.data.dataInventory.estoqueLoja[0].estoquecd === 0 ||
						res.data.dataInventory.estoqueLoja[0].estoquecd === 0.0
					) {
						onChangeNotify({
							open: true,
							class: 'error',
							msg: `Produto sem estoque na Loja e CD!`,
						});
					} else {
						onChangeNotify({
							open: true,
							class: 'error',
							msg: `Produto sem estoque na Loja!`,
						});
					}
				} else if (
					res.data.dataServices === undefined ||
					res.data.dataServices === null ||
					res.data.dataServices.length === 0 ||
					res.data.dataServices === 'null'
				) {
					onChangeNotify({
						open: true,
						class: 'error',
						msg: `Não ha opções de serviços para o produto!`,
					});
					setProduct(res.data);
					setServices('');
				} else {
					setOpenModalService(true);
					setProduct(res.data);
					setServices(res.data.dataServices);
					setDisableEstoqueCd(
						res.data?.dataInventory?.estoqueLoja[0].estoquecd === 0
					);
					setDisableEstoqueLoja(
						res.data?.dataInventory?.estoqueLoja[0].estoqueloja === 0
					);

					const produto = {
						codigoBandeira: res.data.codigoBandeira,
						codigoLoja: res.data.codigoLoja,
						tipoEntrega: '',
						codigoPlu: res.data.codigoPlu,
						codigoRegional: res.data.codigoRegional,
						dtInicial: moment.utc(res.data.dataInicial),
						dtFinal: moment.utc(res.data.dataFinal),
						ean: res.data.ean,
						descricaoProduto: res.data.produtoDescricao,
						produtoId: res.data.produtoId,
						status: res.data.status,
						dataPos: res.data.dataPos,
						dataServices: res.data.dataServices,
						vlFrete: res.data.valorFreteFixo,
					};

					localStorage.setItem('@SF:Product', JSON.stringify(produto));
				}
			})
			.catch((error) => {
				if (
					error?.response?.status === 404 ||
					error?.response?.status === 500 ||
					error
				) {
					setSelectedId();
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: `Produto não cadastrado!`,
					});
				}
			});
	};

	const submitToken = async () => {
		setCountToken(countToken + 1);
		setSeconds(30);
		if (countToken !== 3) {
			onChangeLoading({
				open: true,
				msg: 'Carregando...',
			});

			setLoadingToken(false);
			const simulateProposalData = await localStorage.getItem(
				'@SF:ApprovedData'
			);
			const objSimulateProposalData = JSON.parse(simulateProposalData);

			await apiMotorSiv
				.get(`/gerar-token/${objSimulateProposalData.nrCPF}`)
				.then((res) => {
					onChangeLoading({ open: false });
					setLoadingToken(false);
					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Token enviado com sucesso!`,
					});
				})
				.catch((err) => {
					onChangeLoading({ open: false });
					setLoadingToken(false);

					onChangeNotify({
						open: true,
						class: 'error',
						msg: `Erro no envio do SMS!`,
					});
				});
		}
	};

	const validateToken = async (dataToken) => {
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});

		const simulateProposalData = await localStorage.getItem('@SF:ApprovedData');
		const objSimulateProposalData = JSON.parse(simulateProposalData);

		const data = {
			nrcpf: objSimulateProposalData.nrCPF,
			token: dataToken,
		};

		await apiMotorSiv
			.post(`/validar-token`, data)
			.then((res) => {
				onChangeLoading({ open: false });
				setValidToken(true);
			})
			.catch((err) => {
				onChangeLoading({ open: false });

				setValidToken(false);

				onChangeNotify({
					open: true,
					class: 'error',
					msg: `Sms não validado! `,
				});
			});
	};

	const handleDayClick = async (day) => {
		const dayobj = await day;
		setDate(dayobj);
	};

	React.useEffect(() => {
		const simulateProposalData = localStorage.getItem('@SF:ApprovedData');
		const simulateProposalResponse = localStorage.getItem(
			'@SF:ApprovedResponse'
		);
		const userResponse = sessionStorage.getItem('@SF:user');

		const objSimulateProposalData = JSON.parse(simulateProposalData);
		const objSimulateProposalResponse = JSON.parse(simulateProposalResponse);
		const objUser = JSON.parse(userResponse);

		onChangeSimulateCdc({
			cpf: objSimulateProposalData?.nrCPF,
			maximumApprovedValue: objSimulateProposalResponse?.cdc?.vlLimite,
			storeSeller: objUser?.name,
		});
	}, []);

	const [cdc] = React.useState(
		JSON.parse(localStorage.getItem('@SF:ApprovedResponse'))
	);

	const valuePersonalLoan = () => {
		const value =
			cdc.cdc.vlLimite -
			(product.dataPos?.valorOferta + (safe?.valorGe ? safe?.valorGe : 0));
		return value;
	};

	const submitPlan = async (data) => {
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});
		await apiMotorSiv
			.post(`/escolher-plano/${data}`)
			.then((res) => {
				onChangeLoading({ open: false });
				submitToken();
				setOpenModalToken(true);
				setSeconds(30);
			})
			.catch((err) => {
				onChangeLoading({ open: false });
			});
	};

	React.useEffect(() => {
		const interval = setTimeout(() => {
			if (triggerIdentify) {
				handleSubmitIdentify();
			}
		}, 20000);
		return () => clearTimeout(interval);
	}, [controleIdentify, triggerIdentify]);

	const handleIdentify = async () => {
		setLoadingIdentify(true);
		setOpenModalIdentify(true);
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});

		const simulateProposalData = localStorage.getItem('@SF:ApprovedData');
		const objSimulateProposalData = JSON.parse(simulateProposalData);

		const simulateProposalResponse = localStorage.getItem(
			'@SF:ApprovedResponse'
		);
		const objSimulateProposalResponse = JSON.parse(simulateProposalResponse);

		const data = {
			cpf: objSimulateProposalData.nrCPF,
			nrProposta: objSimulateProposalResponse?.nrProposta,
		};

		await apiIdentify
			.post(`/access-cdc`, data)
			.then((res) => {
				setDataIdentify(res.data);
				onChangeLoading({ open: false });
				setLoadingIdentify(false);

				// window.open(res.data.url, '_blank');
			})
			.catch((err) => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: `Algo deu errado, tente novamente! `,
				});
			});
	};

	const ChooseIdentify = async () => {
		const approvedResponse = await localStorage.getItem('@SF:ApprovedResponse');
		const approvedData = JSON.parse(approvedResponse);
		const { nrProposta } = approvedData;

		const data = {
			cdStatus: 2,
			nrProposta,
		};

		await apiMotorSiv
			.post(`/assinatura-digital`, data)
			.then((res) => {
				handleIdentify();
				setTriggerIdentify(true);
			})
			.catch((err) => {
				onChangeLoading({ open: false });
			});
	};

	const handleSubmitModalDisapproval = () => {
		setOpenModalDisapproval(false);
		handleRedirect();
	};

	const handleSubmitIdentify = async () => {
		const simulateProposalResponse = localStorage.getItem(
			'@SF:ApprovedResponse'
		);
		const objSimulateProposalResponse = JSON.parse(simulateProposalResponse);

		await apiMotorSiv
			.get(`/ConsultarP2A/${objSimulateProposalResponse.nrProposta}`)
			.then((res) => {
				if (res.data.status === 0) {
					setControleIdentify(!controleIdentify);
					setMessageIdentify(res.data.mensagem);
					if (res.data.decision === 'P2A_MUN') {
						setOpenModalDisapproval(true);
						setMessageDisapproval(
							'Cliente foi direcionado para a mesa do Único.'
						);
					} else if (
						res.data.decision === 'P2A_IMG' ||
						res.data.decision === 'P2A_NEG'
					) {
						setOpenModalDisapproval(true);
						setMessageDisapproval('O cliente teve sua proposta negada.');
					}
				}

				if (res.data.status === 1) {
					if (res.data.decision === 'P2A_APR') {
						setOpenModalIdentify(false);
						history.push('/emprestimo/cadastro-proposta-cdc');
					}
					setMessageIdentify(res.data.mensagem);
				}

				if (res.data.status === 2) {
					setOpenModalDisapproval(true);
					setMessageDisapproval('O cliente teve sua proposta negada.');
				}
			})
			.catch((err) => {
				onChangeLoading({ open: false });
			});
	};

	const handleSendPlan = () => {
		submitPlan(selectedValue);
		continueProposalDataSave();
	};

	const handleCloseModalToken = () => {
		setOpenModalToken(false);
	};

	const handleCloseModalIdentify = () => {
		setOpenModalIdentify(false);
	};

	const checkDigitalSignatureResponse = async () => {
		const approvedResponse = await localStorage.getItem('@SF:ApprovedResponse');
		const approvedData = JSON.parse(approvedResponse);
		const { nrProposta } = approvedData;
		await apiMotorSiv
			.get(`/ConsultarP2A/${nrProposta}`)
			.then((res) => {
				if (res.data.status === 3) {
					setDigitalSignature(true);
					setTriggerIdentify(true);
					setOpenModalDigitalSignature(false);
					ChooseIdentify();
				} else {
					setDigitalSignature(false);
				}
			})
			.catch((err) => {
				onChangeLoading({ open: false });
			});
	};

	React.useEffect(() => {
		const interval = setTimeout(() => {
			if (!digitalSignature && triggerSignature) {
				checkDigitalSignatureResponse();
				setControleSignature(!controleSignature);
			}
		}, 20000);
		return () => clearTimeout(interval);
	}, [controleSignature, triggerSignature]);

	const ChooseDigitalSignature = async () => {
		const approvedResponse = await localStorage.getItem('@SF:ApprovedResponse');
		const approvedData = JSON.parse(approvedResponse);
		const { nrProposta } = approvedData;

		const data = {
			cdStatus: 1,
			nrProposta,
		};

		await apiMotorSiv
			.post(`/assinatura-digital`, data)
			.then((res) => {
				DigitalSignature();
			})
			.catch((err) => {
				onChangeLoading({ open: false });
			});
	};

	const DigitalSignature = async () => {
		const approvedResponse = await localStorage.getItem('@SF:ApprovedResponse');
		const approvedData = JSON.parse(approvedResponse);
		const { nrCPF } = approvedData;
		const { nrProposta } = approvedData;
		onChangeLoading({
			open: true,
			msg: 'Carregando...',
		});

		const data = {
			cpf: nrCPF,
			nrProposta,
		};
		setLoadingIdentify(true);

		await apiIdentify
			.post(`/access-digital-signature`, data)
			.then((res) => {
				setDataDigitalSignature(res.data);
				setTriggerSignature(true);
				setOpenModalToken(false);
				setOpenModalDigitalSignature(true);
				onChangeLoading({ open: false });
				const win = window.open(res.data.url, '_blank');
				if (win) {
					win.focus();
				} else {
					alert('Por favor, permita pop-ups para este site');
				}
			})
			.catch((err) => {
				onChangeLoading({ open: false });
			});
	};

	const continueProposalDataSave = async () => {
		const approvedResponse = await localStorage.getItem('@SF:ApprovedResponse');
		const approvedData = JSON.parse(approvedResponse);
		const products = await localStorage.getItem('@SF:Product');
		const approvedProduct = JSON.parse(products);
		const serviceGE = await localStorage.getItem('@SF:ServiceGe');
		const approvedServiceGE = JSON.parse(serviceGE);
		const serviceFr = await localStorage.getItem('@SF:ServiceFr');
		const serviceDa = await localStorage.getItem('@SF:ServiceDa');
		const serviceCb = await localStorage.getItem('@SF:ServiceCb');
		const store = JSON.parse(localStorage.getItem('@SF:Store'));

		const data = {
			nrProposta: approvedData.nrProposta,
			nrCPF: approvedData.nrCPF,
			nrMatriculaPromotor: String(user.matricula),
			usruarioAD: user.login_ad,
			produto: {
				codigoBandeira: approvedProduct.codigoBandeira,
				codigoLoja: store,
				codigoPlu: approvedProduct.codigoPlu,
				codigoRegional: approvedProduct.codigoRegional,
				dtInicial: moment.utc(approvedProduct.dataInicial),
				dtFinal: moment.utc(approvedProduct.dataFinal),
				ean: approvedProduct.ean,
				descricaoProduto: approvedProduct.produtoDescricao,
				produtoId: approvedProduct.produtoId,
				status: approvedProduct.status,
				tipoEntrega: approvedProduct.tipoEntrega,
				vlFrete: shipping,
				dataPos: {
					avisoDeVencimento: approvedProduct.dataPos.avisoDeVencimento,
					codigoBarras: approvedProduct.dataPos.codigoBarras,
					descricaoUnidadeDeMedida:
						approvedProduct.dataPos.descricaoUnidadeDeMedida,
					produtoApresentacao: approvedProduct.dataPos.produtoApresentacao,
					produtoCodigo: approvedProduct.dataPos.produtoCodigo,
					produtoDepartamento: approvedProduct.dataPos.produtoDepartamento,
					produtoDepartamentoCodigo:
						approvedProduct.dataPos.produtoDepartamentoCodigo,
					produtoDescricao: approvedProduct.dataPos.produtoDescricao,
					quantidade: approvedProduct.dataPos.quantidade,
					validadeOfertaFinal: approvedProduct.dataPos.validadeOfertaFinal,
					validadeOfertaInicial: approvedProduct.dataPos.validadeOfertaInicial,
					valorAtacado: approvedProduct.dataPos.valorAtacado,
					valorOferta: approvedProduct.dataPos.valorOferta,
					valorOriginal: approvedProduct.dataPos.valorOriginal,
				},
				dataServices: {
					infoFabricante: {
						status: approvedProduct.dataServices.infoFabricante.Status,
						carac1: approvedProduct.dataServices.infoFabricante.carac1,
						carac2: approvedProduct.dataServices.infoFabricante.carac2,
						carac3: approvedProduct.dataServices.infoFabricante.carac3,
						carac4: approvedProduct.dataServices.infoFabricante.carac4,
						carac5: approvedProduct.dataServices.infoFabricante.carac5,
						carac6: approvedProduct.dataServices.infoFabricante.carac6,
						carac7: approvedProduct.dataServices.infoFabricante.carac7,
						carac8: approvedProduct.dataServices.infoFabricante.carac8,
						furtoRoubo: approvedProduct.dataServices.infoFabricante.furtoRoubo,
						idCategoriaSeg:
							approvedProduct.dataServices.infoFabricante.idCategoriaSeg,
						marca: approvedProduct.dataServices.infoFabricante.marca,
						modelo: approvedProduct.dataServices.infoFabricante.modelo,
						nomeCategoria:
							approvedProduct.dataServices.infoFabricante.nomeCategoria,
						tempoFabricante:
							approvedProduct.dataServices.infoFabricante.tempoFabricante,
					},
				},
			},
			seguro: {
				dataGE: {
					descricao: approvedServiceGE?.descricao || '',
					idFaixaSeg: approvedServiceGE?.idFaixaSeg || 0,
					tempoEstendido: approvedServiceGE?.tempoEstendido || 0,
					valorGe: approvedServiceGE?.valorGe || 0,
					valorMaximo: approvedServiceGE?.valorMaximo || 0,
					valorMinimo: approvedServiceGE?.valorMinimo || 0,
				},
				dataCb: parseFloat(serviceCb) || 0,
				dataFr: parseFloat(serviceFr) || 0,
				dataDa: parseFloat(serviceDa) || 0,
			},
		};
		await apiMotorSiv
			.post(`cadastrar-produto-cdc`, data)
			.then((res) =>
				res.status === 201
					? onChangeNotify({
							open: true,
							class: 'success',
							msg: 'Produto Salvo com sucesso!',
					  })
					: null
			)
			.catch((err) =>
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Erro ao tentar Salvar Produto!',
				})
			);
	};
	const handleSelectedRetirada = (e) => {
		setSelectedRetirada(e.target.value);
		localStorage.setItem('@SF:ProductWithdrawal', e.target.value);
		const product = localStorage.getItem('@SF:Product');
		const objProduct = JSON.parse(product);
		const produto = {
			codigoBandeira: objProduct.codigoBandeira,
			codigoLoja: objProduct.codigoLoja,
			tipoEntrega: e.target.value,
			codigoPlu: objProduct.codigoPlu,
			codigoRegional: objProduct.codigoRegional,
			dtInicial: objProduct.dtInicial,
			dtFinal: objProduct.dtFinal,
			ean: objProduct.ean,
			descricaoProduto: objProduct.descricaoProduto,
			produtoId: objProduct.produtoId,
			status: objProduct.status,
			dataPos: objProduct.dataPos,
			dataServices: objProduct.dataServices,
			dataInventory: objProduct.dataInventory,
			vlFrete: Number.parseFloat(objProduct.vlFrete),
		};
		localStorage.setItem('@SF:Product', JSON.stringify(produto));

		if (e.target.value === 'casa') {
			setShipping(objProduct.vlFrete);
		} else {
			setShipping(0);
		}
	};

	React.useLayoutEffect(() => {
		const timer =
			counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		return () => clearInterval(timer);
	}, [counter]);

	const getDateCdc = async () => {
		const simulateProposalResponse = await localStorage.getItem(
			'@SF:ApprovedResponse'
		);
		const objSimulateProposalResponse = await JSON.parse(
			simulateProposalResponse
		);

		const dA = await new Date(); // ou new Date() para a data atual
		dA.setDate(dA.getDate() + objSimulateProposalResponse?.cdc?.vlVencIinicial);
		setDayAfter(dA);

		const dB = await new Date(); // ou new Date() para a data atual
		dB.setDate(dB.getDate() + objSimulateProposalResponse?.cdc?.vlVencFinal);
		setDayBefore(dB);
	};

	return (
		<SimulateCdcContext.Provider
			value={{
				formik,
				user,
				dayAfter,
				dayBefore,
				handleDayClick,
				date,
				simulateCdc,
				onChangeSimulateCdc,
				activeStep,
				product,
				setProduct,
				products,
				listProduct,
				services,
				safe,
				setSafe,
				handleNext,
				submitToken,
				validateToken,
				handleSubmitProposals,
				proposals,
				handleBack,
				submitPlan,
				validToken,
				handleIdentify,
				openModalIdentify,
				setOpenModalIdentify,
				dataIdentify,
				loadingProduct,
				handleSubmitIdentify,
				messageIdentify,
				loadingToken,
				loadingIdentify,
				counter,
				valuePersonalLoan,
				selectedValue,
				setSelectedValue,
				openModalService,
				setOpenModalService,
				listProducts,
				handleSendPlan,
				openModalToken,
				setOpenModalToken,
				selectedId,
				setSelectedId,
				handleCloseModalToken,
				handleCloseModalIdentify,
				minutes,
				seconds,
				serviceValue,
				setServiceValue,
				countToken,
				DigitalSignature,
				handleCancelModalDigitalSignature,
				handleCloseModalDigitalSignature,
				openModalDigitalSignature,
				ChooseDigitalSignature,
				handleCancelProposal,
				handleCloseCancelProposal,
				openCancelToken,
				handleCancelModalToken,
				setTriggerIdentify,
				disableEstoqueLoja,
				disableEstoqueCd,
				selectedRetirada,
				setSelectedRetirada,
				handleSelectedRetirada,
				setDisableEstoqueLoja,
				setDisableEstoqueCd,
				openModalDisapproval,
				handleSubmitModalDisapproval,
				messageDisapproval,
				setDisableEstoqueLoja,
				setDisableEstoqueCd,
				shipping,
				getDateCdc,
				dataDigitalSignature,
				setOpenModalQrCodeIdentify,
				openModalQrCodeIdentify,
			}}
		>
			{children}
		</SimulateCdcContext.Provider>
	);
}

export function useSimulateCdc() {
	const context = React.useContext(SimulateCdcContext);

	if (!context)
		throw new Error('useSimulateCdc must be used within an AuthProvider');

	return context;
}
