import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { validateCPF, validatePhone } from 'validations-br';
import dayjs from 'dayjs';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';

import apiAutomacao from '../../../services/apiAutomacao'

const LogSuperAppReportContext = React.createContext({
	onChangeLogSuperAppReport: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

LogSuperAppReportContext.displayName = 'Contexto de Logs da tela de relatório do app - Super APP';

export function LogSuperAppReportProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	
	const [activeStep, setActiveStep] = React.useState(0);
	const [loginAd, setLoginAd] = React.useState("")
	const [dtInicial, setDtInicial] = React.useState("")
	const [dtFinal, setDtFinal] = React.useState("")
	const [data, setData] = React.useState([])

	const handleNext = () => {
		setActiveStep(1);
	};

	const handleBack = () => {
		setLoginAd("")
		setDtInicial("")
		setDtFinal("")
		setData([])
		setActiveStep(0);
	};

	const handleConsult = async () => {
		const payload = {
			"loginAd": loginAd || null,
			"dtInicial": dtInicial,
			"dtFinal": dtFinal,
		};

		await apiAutomacao
			.post(`/log-super-app/query`, payload)
			.then(async (res) => {
				setData(res?.data);
				onChangeLoading({ open: false });
				handleNext();
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	return (
		<LogSuperAppReportContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				handleConsult,
				loginAd,
				setLoginAd,
				dtInicial,
				setDtInicial,
				dtFinal,
				setDtFinal,
				data
			}}
		>
			{children}
		</LogSuperAppReportContext.Provider>
	);
}

export function useLogSuperAppReport() {
	const context = React.useContext(LogSuperAppReportContext);

	if (!context)
		throw new Error('useLogSuperAppReport must be used within an AuthProvider');

	return context;
}
