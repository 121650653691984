import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';
import apiAuth from '../../../services/apiAuth';

const initialState = {
	name: '',
	description: '',
	group_store_type: 'system',
	login_ad: '',
	brand: 0,
	list_stores: [],
};
const initialStateSearch = {
	group_store: [],
};

const GroupStoreManagementContext = React.createContext({
	groupStoreManagement: initialState,
});

const validationSchema = yup.object({
	login_ad: yup.string().when('group_store_type', {
		is: (groupStoreType) => groupStoreType === 'user',
		then: yup.string()
			.required('UsuárioAD é obrigatório'),
		otherwise: yup.string().nullable()
		}),
	name: yup.string().when('group_store_type', {
		is: (groupStoreType) => groupStoreType === 'system',
		then: yup.string()
			.required('Nome é obrigatório'),
		otherwise: yup.string().nullable()
		}),
	description: yup.string().when('group_store_type', {
		is: (groupStoreType) => groupStoreType === 'system',
		then: yup.string()
			.required('Nome é obrigatório'),
		otherwise: yup.string().nullable()
		}),
	brand: yup.object().nullable().required('Bandeira é obrigatório'),
	// list_stores: yup.object().nullable().required('Lojas é obrigatório'),
});

const validationSchemaSearch = yup.object({
	group_store: yup.object().nullable().required('Grupo de lojas é obrigatório'),
});

const brands = [
	{ label: 'CENCOSUD', id: 0 },
	{ label: 'BRETAS', id: 1 },
	{ label: 'PREZUNIC', id: 2 },
	{ label: 'GBARBOSA', id: 3 },
	{ label: 'MERCANTIL RODRIGUES', id: 4 },
	{ label: 'PERINI', id: 5 },
];

export function GroupStoreManagementProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const [groupStoreManagement, setGroupStoreManagement] =
		React.useState(initialState);
	const [editMode, setEditMode] = React.useState(false);

	const [groupStoreData, setGroupStoreData] = React.useState('');

	const [activeStep, setActiveStep] = React.useState(0);
	const [registration, setRegistration] = React.useState(false);
	const [groupStoreList, setGroupStoreList] = React.useState([]);
	const [storeList, setStoreList] = React.useState([]);

	const [userType, setUserType] = React.useState(false);
	const [groupStoreUpdate, setGroupStoreUpdate] = React.useState([]);

	const handleOpenRegistration = () => {
		setRegistration(true);
		getStoreList();
	};

	const handleCloseRegistration = () => {
		setRegistration(false);
		formik.initialValues();
	};

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const handleRadioChange = (option) => {
		if (option.target.value === 'system') {
			formik.setFieldValue('group_store_type', 'system');
			formik.setFieldValue('name', '');
			formik.setFieldValue('description', '');
			formik.setFieldValue('login_ad', '');
			formik.setFieldValue('brand', 0);
			formik.setFieldValue('list_stores', []);
			setUserType(false);
		} else {
			formik.setFieldValue('group_store_type', 'user');
			formik.setFieldValue('name', '');
			formik.setFieldValue('description', '');
			formik.setFieldValue('login_ad', '');
			formik.setFieldValue('brand', 0);
			formik.setFieldValue('list_stores', []);
			setUserType(true);
		}
	};

	const handleRadioChangeResult = (option) => {
		if (option.target.value === 'system') {
			setGroupStoreUpdate({ ...groupStoreUpdate, group_store_type: 'system' });
			setUserType(false);
		} else {
			setGroupStoreUpdate({ ...groupStoreUpdate, group_store_type: 'user' });
			setUserType(true);
		}
	};

	const handleSubmitRegistration = () => {
		setRegistration(false);
	};

	const handleEdit = () => {
		setEditMode(!editMode);
	};

	const formik = useFormik({
		initialValues: groupStoreManagement,
		validationSchema,
		onSubmit: async (data, { resetForm }) => {
			const objData = {
				name: userType ? `GRUPO DE ${  data?.login_ad}` : data?.name,
				description: userType
					? `LOJAS DE ${  data?.login_ad}`
					: data?.description,
				group_store_type: data?.group_store_type,
				login_ad: data?.group_store_type === 'user' ? data?.login_ad?.toLowerCase() : null,
				brand_id: data?.brand.id,
				list_stores: data?.list_stores?.map((store) => store.store_id),
			};
			await apiAuth
				.post('group-store/insert', objData)
				.then((res) => {
					resetForm();
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Cadastro realizado com sucesso! `,
					});
				})
				.catch(() => {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				});
		},
	});

	const formikSearch = useFormik({
		initialValues: initialStateSearch,
		validationSchema: validationSchemaSearch,
		onSubmit: async (data, { resetForm }) => {
			onChangeLoading({
				open: true,
				msg: 'Buscando grupo de lojas...',
			});

			const settings = {
				headers: { 'id-group-store': data.group_store.group_store_id },
			};
			await apiAuth
				.get('group-store/query', settings)
				.then((res) => {
					onChangeLoading({ open: false });
					setGroupStoreData(res.data);
					setGroupStoreUpdate({
						...res.data,
						brand: { id: res.data.brand_id, label: res.data.brand_name },
					});
					getStoreList();
					handleNext();
					resetForm();
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Consulta realizada com sucesso! `,
					});
				})
				.catch(() => {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				});
		},
	});

	const getGroupStoreList = async () => {
		await apiAuth
			.get('group-store/query')
			.then((res) => {
				setGroupStoreList(res.data);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Erro ao consultar grupos de lojas.',
				});
			});
	};
	const getStoreList = async () => {
		await apiAuth
			.get('store/query')
			.then((res) => {
				setStoreList(res.data);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Erro ao consultar as lojas.',
				});
			});
	};

	const handleUpdate = async (dataUpdate) => {
		const objData = {
			name: dataUpdate.group_store_name,
			description: dataUpdate.group_store_description,
			brand_id: dataUpdate.brand_id,
			group_store_type: dataUpdate.group_store_type,
			login_ad:
				dataUpdate.group_store_type === 'user'
					? dataUpdate.user_login_ad
					: null,
			list_stores: dataUpdate.list_stores.map((store) => store.store_id),
		};
		const settings = {
			headers: { 'id-group-store': dataUpdate.group_store_id },
		};
		await apiAuth
			.put('group-store/update', objData, settings)
			.then(() => {
				onChangeNotify({
					open: true,
					class: 'success',
					msg: `Atualização realizada com sucesso! `,
				});
			})
			.catch(() => {
				onChangeNotify({
					open: false,
					class: 'error',
					msg: `Aconteceu algum erro, tente novamente!`,
				});
			});
		handleEdit();
	};

	return (
		<GroupStoreManagementContext.Provider
			value={{
				formik,
				formikSearch,
				handleSubmitRegistration,
				activeStep,
				setActiveStep,
				handleBack,
				groupStoreData,
				handleEdit,
				editMode,
				handleOpenRegistration,
				handleCloseRegistration,
				registration,
				getGroupStoreList,
				groupStoreList,
				getStoreList,
				storeList,
				setEditMode,
				userType,
				setUserType,
				handleRadioChange,
				brands,
				handleRadioChangeResult,
				handleUpdate,
				groupStoreUpdate,
				setGroupStoreUpdate,
			}}
		>
			{children}
		</GroupStoreManagementContext.Provider>
	);
}

export function useGroupStoreManagement() {
	const context = React.useContext(GroupStoreManagementContext);

	if (!context)
		throw new Error(
			'useGroupStoreManagement must be used within an GroupStoreManagementProvider'
		);

	return context;
}
