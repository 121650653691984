import React from 'react';
import {
	Dialog,
	AppBar,
	Toolbar,
	Typography,
	DialogContent,
	DialogContentText,
	Grid,
	ListItem,
	List,
	ListItemText,
} from '@material-ui/core';
import { useStore } from '../../hooks/store';
import { useAuth } from '../../hooks/auth';
import { useSessionExpiration } from '../../hooks/sessionExpiration';

export default function StoreModal() {
	const { onChangeStore, modal } = useStore();
	const { user } = useAuth();
	const { getMoreSession } = useSessionExpiration();

	return (
		<Dialog
			open={modal}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableRestoreFocus
		>
			<AppBar position="static">
				<Toolbar className="flex w-full">
					<Typography variant="h6" color="inherit">
						Escolha a sua Loja:
					</Typography>
				</Toolbar>
			</AppBar>

			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<Grid container justifyContent="center">
						<List>
							{user?.group_store[0].lojas?.map((store) => (
								<ListItem
									button
									alignItems="center"
									onClick={() => {onChangeStore(store); getMoreSession	()} }
									key={store}
								>
									<ListItemText
										primary={
											<Typography variant="subtitle1">LOJA {store}</Typography>
										}
									/>
								</ListItem>
							))}
						</List>
					</Grid>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
}
