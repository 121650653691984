import { createTheme } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

export function theme() {
	return createTheme({
		palette: {
			type: 'light',
			common: {
				black: '#232b38',
			},
			primary: {
				light: '#3380f4',
				main: '#051c48',
				dark: '#0043a9',
				100: '#4c6fff',
			},
			secondary: {
				light: '#60696d',
				main: '#425466',
				dark: '#272f33',
			},
			error: {
				light: '#ec4333',
				main: '#ff413a',
				dark: '#a20e00',
			},
			warning: {
				light: '#f6b333',
				main: '#f4a100',
				dark: '#aa7000',
			},
			info: {
				light: '#33d8dd',
				main: '#00cfd5',
				dark: '#009095',
			},
			success: {
				light: '#33bc87',
				main: '#00ac69',
				dark: '#007849',
			},
			grey: {
				300: '#425466',
			},
			bg: {
				100: '#f8f8f9',
			},
			textDark: {
				color: '#12171e',
			},
			text: {
				primary: '#242c3a',
				secondary: '#1b212c',
				dark: '#12171e',
				hint: '#00000061',
			},
			background: {
				default: '#f0f2f8',
			},
		},
		typography: {
			fontFamily: `'Poppins', sans-serif`,
			h6: {
				fontWeight: 600,
				color: '#1b212c',
				fontSize: '0.875rem',
			},
			h5: {
				fontSize: '1.125rem',
				color: '#1b212c',
				fontWeight: 600,
			},
			h4: {
				fontSize: '1.25rem',
				color: '#1b212c',
				fontWeight: 500,
			},
			h3: {
				fontSize: '1.5rem',
				color: '#12171e',
				fontWeight: 600,
			},
			h2: {
				fontSize: '2rem',
				color: '#12171e',
				fontWeight: 600,
			},
			h1: {
				fontSize: '2.2rem',
				color: '#12171e',
				fontWeight: 600,
			},
			subtitle1: {
				fontSize: '0.875rem',
				fontWeight: 500,
				color: '#1b212c',
				lineHeight: '1.643em',
			},
			subtitle2: {
				fontSize: '0.8125rem',
				fontWeight: 400,
			},
			caption: {
				fontSize: '0.68rem',
				color: '#00000061',
				fontWeight: 500,
			},
			body1: {
				fontSize: '0.875rem',
				fontWeight: 400,
				lineHeight: '1.643em',
			},
			body2: {
				letterSpacing: '0em',
				fontWeight: 400,
				lineHeight: '1.643em',
			},
			menuCaption: {
				fontSize: '0.6875rem',
				fontWeight: 600,
				color: '#051c48',
				padding: '5px 15px 5px',
				textTransform: 'uppercase',
				marginTop: '10px',
			},
			subMenuCaption: {
				fontSize: '0.6875rem',
				fontWeight: 400,
				color: '#242c3a',
				textTransform: 'capitalize',
			},
			subHeading: {
				color: 'red',
			},
			cardTitle: {
				color: '#051c48',
				fontSize: '1rem',
			},
			breadcrumbTitle: {
				fontWeight: 500,
				fontSize: '1.5rem',
				color: '#12171e',
			},
		},
		overrides: {
			MuiAccordion: {
				root: {
					boxShadow: 'none',
				},
			},
			MuiAccordionSummary: {
				root: {
					fontWeight: 600,
					fontSize: '0.875rem',
				},
				content: {
					color: '#1b212c',
					fontWeight: 500,
				},
			},
			MuiPaper: {
				elevation1: {
					boxShadow:
						'0 4px 6px -2px rgb(0 0 0 / 12%), 0 2px 2px -1px rgb(0 0 0 / 5%)',
				},
				rounded: {
					borderRadius: '10px',
				},
			},
			MuiCardHeader: {
				root: {
					color: '#12171e',
					padding: '24px',
				},
			},
			MuiCardContent: {
				root: {
					padding: '24px',
				},
			},
			MuiCardActions: {
				root: {
					padding: '24px',
				},
			},
			MuiSvgIcon: {
				root: {
					fontSize: '1.3rem',
				},
			},
			// Table
			MuiTableCell: {
				root: {
					padding: '16px 36px 16px 36px',
					whiteSpace: 'nowrap',
				},
				head: {
					padding: '16px 36px 16px 36px',
					color: '#12171e',
					fontWeight: 600,
				},
				paddingCheckbox: {
					paddingLeft: '36px',
					position: 'relative',
				},
			},
			MuiList: {
				root: {
					overflow: 'hidden',
				},
			},
			MuiListItem: {
				root: {
					color: '#242c3a',
					paddingTop: '12px',
					paddingBottom: '12px',
				},
			},
			MuiListItemIcon: {
				root: {
					minWidth: '36px',
					color: '#242c3a',
				},
			},
			MuiChip: {
				colorSecondary: {
					color: grey[100],
				},
				colorPrimary: {
					color: grey[100],
				},
				root: {
					color: grey[100],
				},
				outlined: {
					color: grey[500],
				},
			},
			MuiTimelineDot: {
				defaultGrey: {
					background: grey[300],
				},
			},
			MuiTimelineConnector: {
				root: {
					background: grey[300],
				},
			},
			MuiTableContainer: {
				root: {
					boxShadow: 'none',
				},
			},
			MuiAvatar: {
				colorDefault: {
					backgroundColor: '#00000061',
					color: grey[100],
				},
			},
			MuiInputBase: {
				input: {
					color: '#12171e',
				},
			},
		},
	});
}

export default theme;
