import React from 'react';
import { AttendanceProvider } from './Attendance';
import { AccessReportProvider } from './AccessReport'
import { DataAppReportProvider } from './DataAppReport'
import { LogSuperAppReportProvider } from './LogSuperAppReport'

function SuperAppProvider({ children }) {
	return (
		<AttendanceProvider>
			<AccessReportProvider>
				<DataAppReportProvider>
					<LogSuperAppReportProvider>
						{children}
					</LogSuperAppReportProvider>
				</DataAppReportProvider>
			</AccessReportProvider>
		</AttendanceProvider>
	);
}

export default SuperAppProvider;
