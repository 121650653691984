import React from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green, red, yellow } from '@material-ui/core/colors';
import { useNotify } from '../../hooks/notify';

const useStyles = makeStyles({
	success: {
		backgroundColor: green[500],
	},
	error: {
		backgroundColor: red[600],
	},
	attention: {
		backgroundColor: yellow[600],
	},
});

export default function Notify() {
	const classes = useStyles();

	const { notify, onChangeNotify } = useNotify();

	return (
		<Snackbar
			anchorOrigin={{
				horizontal: notify.horizontal,
				vertical: notify.vertical,
			}}
			open={notify.open}
			autoHideDuration={notify.time}
			onClose={() => onChangeNotify({ open: false })}
		>
			<SnackbarContent
				className={`${
					classes[notify.class]
				} d-flex justifyContent-content-center`}
				message={
					<span className="d-flex align-items-center">{notify.msg}</span>
				}
			/>
		</Snackbar>
	);
}
