import React from 'react';
import { AttendanceProvider } from './Attendance';
import { ReportProvider } from './Report';
import { SmsParameterProvider } from './SmsParameter';
import { DashboardProvider } from './Dashboard';
import { InventoryControlProvider } from './InventoryControl';

function TangibleProvider({ children }) {
	return (
		<AttendanceProvider>
			<ReportProvider>
				<SmsParameterProvider>
					<DashboardProvider>
						<InventoryControlProvider>{children}</InventoryControlProvider>
					</DashboardProvider>
				</SmsParameterProvider>
			</ReportProvider>
		</AttendanceProvider>
	);
}

export default TangibleProvider;
