import React from 'react';
import { useHistory } from 'react-router-dom';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiMonitorVendas from '../../../services/apiMonitorVendas';

const SalesMonitoringContext = React.createContext({});

export function SalesMonitoringProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const [carregando, setCarregando] = React.useState(true);
	const [bandeira, setBandeira] = React.useState(1);
	const [lojas, setLojas] = React.useState([]);
	const [cdLoja, setCdLoja] = React.useState(0);
	const [nomeLoja, setNomeLoja] = React.useState();
	const [municipioLoja, setMunicipioLoja] = React.useState();
	const [ufLoja, setUfLoja] = React.useState();
	const [dados, setDados] = React.useState([]);
	const [controle, setControle] = React.useState(false);
	const [naoNotificar, setNaoNotificar] = React.useState([]);
	const [historico, setHistorico] = React.useState([])
	const [ufs, setUfs] = React.useState([])
	const [dataInicial, setDataInicial] = React.useState('');
	const [dataFinal, setDataFinal] = React.useState('');
	const [uf, setUf] = React.useState(null);
	const [regionais, setRegionais] = React.useState([]);
	const [dadosRegioes, setDadosRegioes] = React.useState([]);
	const [buscando, setBuscando] = React.useState(false);
	const [regional, setRegional] = React.useState(null);
	const [convertidos, setConvertidos] = React.useState([])
	const [aprovadosOperador, setAprovadosOperador] = React.useState([])
	const [selected, setSelected] = React.useState();
	
	const getLojas = async () => {
		
		const payload = {
			"cdNegocio": bandeira,
		};

		await apiMonitorVendas
			.post(`/lojas`, payload)
			.then((res) => {
				setLojas(res.data.lojas);
			})
			.catch((error) => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro na busca das lojas, tente novamente!',
				});
			});
	};

	const getPreAprovados = async () => {
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		const payload = {
			"cdLoja": cdLoja,
		};

		await apiMonitorVendas
			.post(`/pdv`, payload)
			.then((res) => {
				onChangeLoading({ open: false });

				setCarregando(false);
				setDados(res.data);

				onChangeNotify({
					open: true,
					class: 'success',
					msg: `Ofertas PDV consultadas com sucesso!`,
				});
			})
			.catch((error) => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro na busca de aprovados, tente novamente!',
				});
			});
	};

	const getHistorico = async () => {
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		const payload = {
			"cdLoja": cdLoja,
		};

		await apiMonitorVendas
			.post(`/historico`, payload)
			.then((res) => {
				onChangeLoading({ open: false });

				setCarregando(false);
				setHistorico(res.data.dados);
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro na busca do histórico, tente novamente!',
				});
			});
	}

	const getUf = async () => {
		const payload = {
			"cdNegocio": bandeira,
		};

		await apiMonitorVendas
			.post(`/ufBandeira`, payload)
			.then((res) => {
				setUfs(res.data.uf);
			})
			.catch((error) => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro na busca das UFs, tente novamente!',
				});
			});
	};
	
	const getRanking = async () => {
		var _dataInicio = new Date(dataInicial);
		var _dataFim = new Date(dataFinal);
		var difTempo = _dataFim.getTime() - _dataInicio.getTime();
		var difDias = Math.ceil(difTempo / (1000 * 3600 * 24));
		setCarregando(true);


		if (dataInicial == '' || dataFinal == '') {
			alert('Preencha as datas de forma válida!');
		} else if (difDias < 0) {
			alert('A data final não pode ser menor que a inicial.');
		} else if (difDias > 1) {
			alert('O maior espaço de tempo para a consulta deve ser de 2 dias.');
		} else if (bandeira !== '') {
			onChangeLoading({
				open: true,
				msg: 'Consultando...',
			});

			const payload = {
				"cdNegocio": bandeira,
				"dtInicial": dataInicial,
				"dtFinal": dataFinal,
				"uf": uf
			};

			await apiMonitorVendas
				.post(`/aprovadosPorLoja`, payload)
				.then((res) => {
					onChangeLoading({ open: false });
					setCarregando(false);
					setDados(res.data.dados);
				})
				.catch((error) => {
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro na busca dos aprovados por loja, tente novamente!',
					});
				});
		}
	}

	const getRegioes = async () => {
			const payload = {
				"cdBandeira": bandeira,
			};
		
			await apiMonitorVendas
				.post(`/regionais`, payload)
				.then((res) => {
					setRegionais(res.data);
					res.data.map((regiao) => {
						dadosRegioes.push({
							cdRegiao: regiao.cdRegiao,
							nmRegiao: regiao.dsNomeRegiao,
						});
					});
				})
				.catch((error) => {
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro na busca doas regionais, tente novamente!',
					});
				});
	};
	
	const getConvertidos = async () => {
		
		var _dataInicio = new Date(dataInicial);
		var _dataFim = new Date(dataFinal);
		var difTempo = _dataFim.getTime() - _dataInicio.getTime();
		var difDias = Math.ceil(difTempo / (1000 * 3600 * 24));
		
		if (dataInicial == '' || dataFinal == '') {
			alert('Preencha as datas de forma válida!');
		} else if (difDias < 0) {
			alert('A data final não pode ser menor que a inicial.');
		} else if (difDias > 100) {
			alert('O maior espaço de tempo para a consulta deve ser de 2 dias.');
		} else if (bandeira !== '') {
			setCarregando(true);

			onChangeLoading({
				open: true,
				msg: 'Consultando...',
			});

			const payload = {
				"cdNegocio": bandeira,
				"dtInicial": dataInicial,
				"dtFinal": dataFinal,
				"uf": uf,
				"regional": regional,
			};

				await apiMonitorVendas
					.post(`/convertidos-bandeira`, payload)
					.then((res) => {
						onChangeLoading({ open: false });
						setCarregando(false);
						setConvertidos(res.data[0]);
					})
					.catch((error) => {
						onChangeLoading({ open: false });
						onChangeNotify({
							open: true,
							class: 'error',
							msg: 'Aconteceu algum erro na busca dos convertidos, tente novamente!',
						});
					});
			}
	}
	
	const handleSelectBandeira = (value) => {
		setSelected(undefined)
		setBandeira(value);
		setRegional(null);
		setUf(null)
		selecionarLoja(0);
	};

	const setThirtyInterval = () => {
		const interval = setTimeout(() => {
			setControle(!controle);
		}, 30000);
		return () => clearTimeout(interval);
	};

	function selecionarLoja(loja) {
		setCdLoja(loja);
		lojas.map((i) => {
			if (i.nrLoja === loja) {
				setNomeLoja(i.nmLoja);
				setMunicipioLoja(i.nmCidade);
				setUfLoja(i.nmUf);
				return 0;
			}
		});
	}

		function verificaNotificar(dataHora) {
			let achou = false;
			naoNotificar.forEach((element) => {
				if (element === dataHora) {
					achou = true;
				}
			});
			return achou;
	}
	
		function reverseString(str) {
			if (str === '') return '';
			return reverseString(str.substr(1)) + str.charAt(0);
		}

		function numFormat(num) {
			num = reverseString(num);
			num = num.replace(/\D/g, '');
			num = num.replace(/(\d{3})(\d)/, '$1.$2');
			num = num.replace(/(\d{3})(\d)/, '$1.$2');
			num = num.replace(/(\d{3})(\d)/, '$1.$2');
			num = num.replace(/(\d{3})(\d)/, '$1.$2');
			num = reverseString(num);
			return num;
	}
	
	const getAprovadosOperador = async () => {
		var _dataInicio = new Date(dataInicial);
		var _dataFim = new Date(dataFinal);
		var difTempo = _dataFim.getTime() - _dataInicio.getTime();
		var difDias = Math.ceil(difTempo / (1000 * 3600 * 24));

		if (dataInicial == '' || dataFinal == '') {
			alert('Preencha as datas de forma válida!');
		} else if (difDias < 0) {
			alert('A data final não pode ser menor que a inicial.');
		} else if (difDias > 15) {
			alert('O maior espaço de tempo para a consulta deve ser de 15 dias.');
		} else if (bandeira !== '') {
			setCarregando(true);

			onChangeLoading({
				open: true,
				msg: 'Consultando...',
			});

			const payload = {
				"cdNegocio": bandeira,
				"cdLoja": Number(selected),
				"dtInicial": dataInicial,
				"dtFinal": dataFinal,
				"regional": regional
			};

			await apiMonitorVendas
				.post(`/aprovados-operador-pdv`, payload)
				.then((res) => {
					onChangeLoading({ open: false });
					setCarregando(false);
					setAprovadosOperador(res.data);
					setCarregando(false);
				})
				.catch((error) => {
					setCarregando(false);
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro na busca dos aprovados por operador, tente novamente!',
					});
				});
		}
	};

	return (
		<SalesMonitoringContext.Provider
			value={{
				carregando,
				setCarregando,
				bandeira,
				setBandeira,
				lojas,
				setLojas,
				cdLoja,
				setCdLoja,
				nomeLoja,
				setNomeLoja,
				municipioLoja,
				setMunicipioLoja,
				ufLoja,
				setUfLoja,
				dados,
				setDados,
				controle,
				setControle,
				naoNotificar,
				setNaoNotificar,
				getLojas,
				setThirtyInterval,
				getPreAprovados,
				selecionarLoja,
				verificaNotificar,
				numFormat,
				getHistorico,
				historico,
				ufs,
				getUf,
				setUf,
				getRanking,
				dataInicial,
				setDataInicial,
				dataFinal,
				setDataFinal,
				uf,
				getRegioes,
				regionais,
				buscando,
				getConvertidos,
				regional,
				setRegional,
				convertidos,
				dadosRegioes,
				handleSelectBandeira,
				setHistorico,
				aprovadosOperador,
				getAprovadosOperador,
				setSelected,
				selected,
			}}
		>
			{children}
		</SalesMonitoringContext.Provider>
	);
}

export function useSalesMonitoring() {
	const context = React.useContext(SalesMonitoringContext);

	if (!context)
		throw new Error('useSalesMonitoring must be used within an AuthProvider');

	return context;
}
