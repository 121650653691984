import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { validateCPF, validatePhone } from 'validations-br';
import dayjs from 'dayjs';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';

import apiAutomacao from '../../../services/apiAutomacao';

const AccessReportContext = React.createContext({
	onChangeAccessReport: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

AccessReportContext.displayName = 'Contexto de Relatório de Acesso - Super APP';

export function AccessReportProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	
	const [activeStep, setActiveStep] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [data, setData] = React.useState([])
	const [date, setDate] = React.useState('');

	const handleNext = () => {
		setActiveStep(1);
	};

	const handleBack = () => {
		setDate("");
		setActiveStep(0);
	};

	const handleConsult = async () => {
		const payload = {
			dtInicial: date,
			dtFinal: date,
		};

		if (date === '') {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Preencha as datas de forma válida!',
			});
		} else {
			setIsLoading(true);

			await apiAutomacao
				.post(`/log-motor-siv`, payload)
				.then((res) => {
					setIsLoading(false);
					onChangeLoading({ open: false });
					setData(res.data);
					handleNext();
				})
				.catch((error) => {
					setIsLoading(false);
					onChangeLoading({ open: false });

					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro na busca, tente novamente!',
					});
				});
		}
	}

	return (
		<AccessReportContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				handleConsult,
				isLoading,
				setIsLoading,
				date,
				setDate,
				setData,
				data,
			}}
		>
			{children}
		</AccessReportContext.Provider>
	);
}

export function useAccessReport() {
	const context = React.useContext(AccessReportContext);

	if (!context)
		throw new Error('useAccessReport must be used within an AuthProvider');

	return context;
}
