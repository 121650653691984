import React, { useState } from 'react';
import axios from 'axios';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiMonitoramento from '../../../services/apiMonitoramento';
import apiMonitoraApiHealth from '../../../services/apiMonitoraApiHealth';

const DashboardContext = React.createContext({});
DashboardContext.displayName = 'Contexto do Dashboard do Monitoramento';

export function DashboardProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const data = new Date();
	const ano = data.getFullYear();
	const mes = String(data.getMonth() + 1).padStart(2, '0');
	const dia = String(data.getDate()).padStart(2, '0');
	const initialDate = `${ano}-${mes}-${dia}`;

	const [isLoading, setIsLoading] = React.useState(true);
	const [apiData, setApiData] = React.useState([]);
	const [apisWithErro, setApisWithErro] = React.useState(0);
	const [fileTypes, setFileTypes] = React.useState();
	const [dataLog, setDataLog] = React.useState([]);
	const [dateLog, setDateLog] = React.useState();

	const [dataEmbossing, setDataEmbossing] = React.useState([]);
	const [dateEmbossing, setDateEmbossing] = React.useState(initialDate);

	const [totalAPIs, setTotalAPIs] = useState(0);
	const [errosAPIs, setErrosAPIs] = useState(0);
	const [totalArquivos, setTotalArquivos] = useState(0);
	const [errosArquivos, setErrosArquivos] = useState(0);
	const [waitingGeral, setWaitinGeral] = useState(0);

	const handleSetDateLog = (key, newDate) => {
		setDateLog((prevState) => ({
			...prevState,
			[key]: newDate,
		}));
	};

	const handleConsultTiposArquivos = async () => {
		setIsLoading(true);
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		await apiMonitoramento
			.get(`/monitoramento/query/tipos-arquivos`)
			.then((res) => {
				onChangeLoading({ open: false });
				setIsLoading(false);
				setFileTypes(res?.data);
				handleConsultApiData();
				handleConsultEmbossing(dateEmbossing);

				// Objeto JSON dinâmico para armazenar os dados
				const dynamicInitialDatesJSON = {};

				// Percorra o array de fileTypes para criar as propriedades dinâmicas no JSON
				res?.data?.forEach((fileType) => {
					dynamicInitialDatesJSON[fileType?.nome] = initialDate;
				});

				setDateLog(dynamicInitialDatesJSON);
				handleConsultAllLogs(res?.data);
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro na busca, tente novamente!',
				});
			});
	};

	const handleConsultAllLogs = async (fileTypes) => {
		setIsLoading(true);
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		const requests = [];

		console.log(fileTypes);
		// requests.push(apiMonitoraApiHealth.get(`/health`));
		fileTypes?.forEach((item) => {
			requests.push(
				apiMonitoramento.post(`/monitoramento/query`, {
					data: initialDate,
					arquivo: item?.nome,
				})
			);
		});

		// requests.push(apiMonitoraApiHealth.get(`/health`));

		// Aguarde todas as solicitações terminarem usando Promise.all
		Promise.all(requests)
			.then((responses) => {
				let totaldeArquivos = 0;
				let totalErros = 0;
				let aguardando = 0;
				// Mapeie os dados de resposta de volta para fileTypes
				const newDataLog = {};
				fileTypes?.forEach((item, index) => {
					const responseData = responses[index]?.data;
					if (responseData) {
						newDataLog[item.nome] = responseData;

						totaldeArquivos += responseData.totalArquivos;
						totalErros += responseData.qtd.rpa.erro;
						aguardando += responseData.qtd.rpa.aguardando;
					}
				});

				setTotalArquivos(totaldeArquivos);
				setErrosArquivos(totalErros);
				setWaitinGeral(aguardando);
				// Atualize o estado com os novos dados
				setDataLog((prevDataLog) => ({
					...prevDataLog,
					...newDataLog,
				}));
				onChangeLoading({ open: false });
				setIsLoading(false);
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				setIsLoading(false);
			});
	};

	const handleConsultApiData = async (fileType, dateLog) => {
		// setIsLoading(true);
		// onChangeLoading({
		// 	open: true,
		// 	msg: 'Consultando...',
		// });

		await apiMonitoraApiHealth
			.get(`/health`)
			.then((res) => {
				// onChangeLoading({ open: false });
				// setIsLoading(false);
				setApiData(res?.data?.apis);
				setErrosAPIs(
					res.data.apis.filter((resp) => resp.response_status !== 200).length
				);
				setTotalAPIs(res.data.apis.length);
			})
			.catch((error) => {
				// onChangeLoading({ open: false });
				// onChangeNotify({
				// 	open: true,
				// 	class: 'error',
				// 	msg: 'Aconteceu algum erro na busca, tente novamente!',
				// });
			});
	};

	const handleConsultOneLog = async (fileType, date) => {
		setIsLoading(true);
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		if (fileType === 'EMBOSSING') {
			handleConsultEmbossing(date, true);
		}

		const payload = {
			data: date,
			arquivo: fileType,
		};

		await apiMonitoramento
			.post(`/monitoramento/query`, payload)
			.then((res) => {
				onChangeLoading({ open: false });
				setIsLoading(false);
				setDataLog({
					...dataLog,
					[fileType]: res?.data,
				});
			})
			.catch((error) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro na busca, tente novamente!',
				});
			});
	};

	const handleConsultEmbossing = async (date, isReload = false) => {
		setIsLoading(true);
		onChangeLoading({
			open: true,
			msg: 'Consultando...',
		});

		const payload = {
			data: date,
		};

		await apiMonitoramento
			.post(`/monitoramento/query/embossing`, payload)
			.then((res) => {
				setDataEmbossing(res?.data);
				if (isReload) {
					onChangeLoading({ open: false });
					setIsLoading(false);
				}
			})
			.catch((error) => {
				// onChangeLoading({ open: false });
				// onChangeNotify({
				// 	open: true,
				// 	class: 'error',
				// 	msg: 'Aconteceu algum erro na busca, tente novamente!',
				// });
			});
	};

	return (
		<DashboardContext.Provider
			value={{
				isLoading,
				apiData,
				setApiData,
				apisWithErro,
				handleConsultOneLog,
				dataLog,
				handleConsultAllLogs,
				handleSetDateLog,
				dateLog,
				setDateLog,
				dateLog,
				setDataLog,
				handleConsultTiposArquivos,
				fileTypes,
				totalAPIs,
				errosAPIs,
				totalArquivos,
				errosArquivos,
				waitingGeral,
				dataEmbossing,
			}}
		>
			{children}
		</DashboardContext.Provider>
	);
}

export function useDashboard() {
	const context = React.useContext(DashboardContext);

	if (!context)
		throw new Error('useDashboard must be used within an AuthProvider');

	return context;
}
