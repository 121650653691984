import React from 'react';
import dayjs from 'dayjs';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiAutomacaoTangiveis from '../../../services/apiAutomacao';
import apiAuth from '../../../services/apiAuth';

const BatchMonitorEmailsContext = React.createContext({
	onChangeBatchMonitorEmails: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

BatchMonitorEmailsContext.displayName = 'Contexto de Emails do Monitor de lotes';

export function BatchMonitorEmailsProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	
	const [isLoading, setIsLoading] = React.useState(false);
	const [data, setData] = React.useState([])
	const [openModal, setOpenModal] = React.useState('');
	const [emailList, setEmailList] = React.useState("");
	const [batchMonitor, setBatchMonitor] = React.useState("")
	const [isEditing, setIsEditing] = React.useState(false)
	const [newStatus, setNewStatus] = React.useState(true)
	const [selectedId, setSelectedId] = React.useState(null)
	const [storeList, setStoreList] = React.useState([])

	const [filterSore, setFilterStore] = React.useState(null)
	const [filterStatus, setFilterStatus] = React.useState(null)

	const handleCloseModal = () => {
		setEmailList("");
		setBatchMonitor("");
		setOpenModal(false);
		setSelectedId(null);
		handleConsult();
	}

	const handleOpenModalCreate = () => {
		setIsEditing(false);
		setOpenModal(true);
	};

	const handleOpenModalEdit = (param) => {
		setOpenModal(true);
		setIsEditing(true);
		setNewStatus(param?.flAtivo);
		setEmailList(param?.dsEmail);
		setSelectedId(param?.idMonitorEmail);
		setBatchMonitor(param?.cdEstabelecimento);
	};

	const handleConsult = async () => {
		const payload = {
			cdEstabelecimento: filterSore,
			flAtivo: filterStatus,
		};

		await apiAutomacaoTangiveis
			.post(`/monitor-lotes/email/query`, payload)
			.then((res) => {
				setData(res.data);
				onChangeLoading({ open: false });
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	}

	const handleUpdateEmailList = async (list) => {
		const payload = {
			dsListaEmail: emailList,
			idMonitorEmail: selectedId,
		};

		await apiAutomacaoTangiveis
			.put(`/monitor-lotes/email/update-lista-email`, payload)
			.then((res) => {
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Atualização realizada!',
				});
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleUpdateStatus = async (status) => {
		const payload = {
			flAtivo: status ? 1 : 0,
			idMonitorEmail: selectedId,
		};
		
		await apiAutomacaoTangiveis
		.put(`/monitor-lotes/email/update-status`, payload)
		.then((res) => {
			setNewStatus(status)
			onChangeLoading({ open: false });
			onChangeNotify({
				open: true,
				class: 'success',
				msg: 'Atualização realizada!',
			});
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleSubmitCreate = async (status) => {
		const payload = {
			cdEstabelecimento: batchMonitor,
			dsListaEmail: emailList,
			flAtivo: 1,
		};

		await apiAutomacaoTangiveis
			.post(`/monitor-lotes/email/insert`, payload)
			.then((res) => {
				setEmailList("");
				setBatchMonitor('');
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Cadastro realizado!',
				});
			})
			.catch((error) => {
				setIsLoading(false);
				onChangeLoading({ open: false });
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const getStoreList = async () => {
		await apiAuth
			.get(`store/query`)
			.then((res) => {
				setStoreList(
					res.data.sort((a, b) => a.store_id - b.store_id)
				);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Erro ao consultar as lojas.',
				});
			});
	};

	return (
		<BatchMonitorEmailsContext.Provider
			value={{
				isLoading,
				setIsLoading,
				data,
				openModal,
				handleOpenModalCreate,
				handleOpenModalEdit,
				handleCloseModal,
				emailList,
				handleUpdateEmailList,
				batchMonitor,
				setBatchMonitor,
				isEditing,
				handleConsult,
				setEmailList,
				newStatus,
				handleUpdateStatus,
				handleSubmitCreate,
				getStoreList,
				storeList,
				filterSore,
				setFilterStore,
				filterStatus,
				setFilterStatus,
			}}
		>
			{children}
		</BatchMonitorEmailsContext.Provider>
	);
}

export function useBatchMonitorEmails() {
	const context = React.useContext(BatchMonitorEmailsContext);

	if (!context)
		throw new Error('useBatchMonitorEmails must be used within an AuthProvider');

	return context;
}
