import React from 'react';
import { useHistory } from 'react-router-dom';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiAutomacaoTangiveis from '../../../services/apiAutomacao';

const FgtsReportContext = React.createContext({});

export function FgtsReportProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const [dtInicial, setDtInicial] = React.useState("")
	const [dtFinal, setDtFinal] = React.useState("")
	const [carregando, setCarregando] = React.useState(false)
	const [dados, setDados] = React.useState([])

	const handleConsult = async () => {
		var _dataInicio = new Date(dtInicial);
		var _dataFim = new Date(dtFinal);
		var difTempo = _dataFim.getTime() - _dataInicio.getTime();
		var difDias = Math.ceil(difTempo / (1000 * 3600 * 24));

		if (dtInicial == '' || dtFinal == '') {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Preencha as datas de forma válida.',
			});
		} else if (difDias < 0) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'A data final não pode ser menor que a inicial.',
			});
		} else if (difDias > 30) {
			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'O maior espaço de tempo para a consulta deve ser de até 31 dias.',
			});
		} else {
			setCarregando(true);
			const payload = {
				dtInicial: dtInicial,
				dtFinal: dtFinal,
			};
	
			await apiAutomacaoTangiveis
				.post(`/fgts/relatorio/query`, payload)
				.then((res) => {
					setDados(res.data);
					onChangeLoading({ open: false });
					setCarregando(false);
				})
				.catch((error) => {
					setCarregando(false);
					onChangeLoading({ open: false });
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
				});
		}
	};

	return (
		<FgtsReportContext.Provider
			value={{
				dtInicial,
				setDtInicial,
				dtFinal,
				setDtFinal,
				carregando,
				dados,
				setDados,
				handleConsult,
			}}
		>
			{children}
		</FgtsReportContext.Provider>
	);
}

export function useFgtsReport() {
	const context = React.useContext(FgtsReportContext);

	if (!context)
		throw new Error('useFgtsReport must be used within an AuthProvider');

	return context;
}
