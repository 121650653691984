import React from 'react';

import { makeStyles, List, Typography } from '@material-ui/core';

import NavItem from '../NavItem';

const useStyles = makeStyles((theme) => ({
	menuCaption: {
		...theme.typography.menuCaption,
	},
}));

function NavGroup(props) {
	const { item } = props;
	const classes = useStyles();

	const items = item.functionalities.map((menu) => (
		<NavItem key={menu.function_id} item={menu} level={1} />
	));

	return (
		<List
			subheader={
				<Typography
					variant="caption"
					className={classes.menuCaption}
					display="block"
					gutterBottom
				>
					{item.category_name}
				</Typography>
			}
		>
			{items}
		</List>
	);
}

export default NavGroup;
