import React from 'react';
import {
	makeStyles,
	Button,
	Chip,
	ClickAwayListener,
	Fade,
	Paper,
	Popper,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Typography,
} from '@material-ui/core';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { useAuth } from '../../../../hooks/auth';
import { useStore } from '../../../../hooks/store';
import SessionExpirationSection from '../SessionExpirationSection';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: '350px',
		minWidth: '250px',
		backgroundColor: theme.palette.background.paper,
		paddingBottom: 0,
		borderRadius: '10px',
	},
	ScrollHeight: {
		height: '320px',
		overflowX: 'hidden',
	},
	menuIIcon: {
		fontSize: '1.5rem',
	},
	menuButton: {
		[theme.breakpoints.down('sm')]: {
			minWidth: '50px',
		},
		[theme.breakpoints.down('xs')]: {
			minWidth: '35px',
		},
	},
}));

function NotificationSection() {
	const classes = useStyles();
	const { user } = useAuth();
	const { store, onChangeStore } = useStore();

	const [open, setOpen] = React.useState(false);

	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);

	return (
		<>			
			<SessionExpirationSection />
			<Button
				className={classes.menuButton}
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				color="inherit"
			>				
				<Typography variant="body2" className={classes.name}>
					LOJA {store}	
				</Typography>
			</Button>
			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: {
						offset: {
							enable: true,
							offset: '0px, 10px',
						},
						preventOverflow: {
							padding: 0,
						},
					},
				}}
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<List className={classes.root}>
									<PerfectScrollbar className={classes.ScrollHeight}>
										<ListSubheader disableSticky>
											<Chip
												size="small"
												color="primary"
												label="Selecione sua loja"
											/>
										</ListSubheader>
										{user.group_store[0].lojas.map((store) => (
											<ListItem
												button
												alignItems="center"
												onClick={() => {onChangeStore(store); setOpen(false)}}
												key={store}
											>
												<ListItemText
													primary={
														<Typography variant="subtitle1">
															LOJA {store}
														</Typography>
													}
												/>
											</ListItem>
										))}
									</PerfectScrollbar>
								</List>
							</ClickAwayListener>
						</Paper>
					</Fade>
				)}
			</Popper>
		</>
	);
}

export default NotificationSection;
