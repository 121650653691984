import axios from 'axios';
import config from '../config';

const apiFaturaSisf = axios.create({
	baseURL:
		// 'http://localhost:9011',
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_FATURA_SISF_PROD
			: process.env.REACT_APP_FATURA_SISF_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Headers':
			'Origin, X-Requested-With, Content-Type, Accept',
		apikey:
			process.env.REACT_APP_PROD === 'true'
				? 'waegmRJ6j3tvCgkHtF5oXvELVmka4X5p'
				: 'HB638Zhme9Hj4oG2vtFmxVU3SJlhHf6k',
	},
});

export default apiFaturaSisf;
