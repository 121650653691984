import React from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik';

import { useNotify } from '../../../hooks/notify';

import apiDescProgressivo from '../../../services/apiDescProgressivo';

import { useAuth } from '../../../hooks/auth';

const initialState = {
	campaign: '',
	dateStart: '',
	dateEnd: '',
	status: '',
	channel: '',
	registration: '',
	store: [],
};

const ProgressiveDiscountContext = React.createContext({
	progressiveDiscount: initialState,
	onChangeProgressiveDiscount: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

ProgressiveDiscountContext.displayName = 'Contexto Desconto Progressivo';

const validationSchema = yup.object().shape({
	campaign: yup.string().required('Camapanha é obrigatório'),
	dateStart: yup.string().required('Data Inicial é obrigatório'),
	dateEnd: yup.string().required('Data Final é obrigatório'),
	channel: yup.string().required('Canal é obrigatório'),
	store: yup.string().required('Lojas é obrigatório'),
});

export function ProgressiveDiscountProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { user } = useAuth();

	const [modalNewCampaign, setModalNewCampaign] = React.useState(false);
	const [modalViewCampaign, setModalViewCampaign] = React.useState(false);
	const [editMode, setEditMode] = React.useState(true);
	const [campaigns, setCampaigns] = React.useState([]);
	const [oneCampaign, setOneCampaign] = React.useState([]);
	const [stores, setStores] = React.useState([]);
	const [storeList, setStoreList] = React.useState([]);
	const [regionList, setRegionList] = React.useState([]);
	const [groupStoreUpdate, setGroupStoreUpdate] = React.useState([]);
	const [dateStart, setDateStart] = React.useState('');
	const [dateEnd, setDateEnd] = React.useState('');
	const [channel, setChannel] = React.useState('');
	const [status, setStatus] = React.useState(false);
	const [campaignName, setCampaignName] = React.useState('');

	const headers = [
		{ label: 'CAMPANHA', key: 'CAMPANHA' },
		{ label: 'INICIO', key: 'DATAINICIO' },
		{ label: 'FINAL', key: 'DATAFINAL' },
		{ label: 'STATUS', key: 'STATUS' },
		{ label: 'CANAL', key: 'CANAL' },
		{ label: 'MATRICULA', key: 'MATRICULA' },
		{ label: 'LOJAS', key: 'LOJAS' },
	];

	const brandList = [
		{ label: 'CENCOSUD', id: 0 },
		{ label: 'MERCANTIL RODRIGUES', id: 1 },
		{ label: 'GBARBOSA', id: 2 },
		{ label: 'BRETAS', id: 3 },
		{ label: 'PERINI', id: 4 },
	];

	const filterList = [
		{ value: 'campanha', label: 'Campanha' },
		{ value: 'matricula', label: 'Matrícula' },
		{ value: 'canal', label: 'Canal' },
		{ value: 'status', label: 'Status' },
	];

	const statusList = [
		{ id: 0, label: 'Ativo', value: true, color: 'green' },
		{ id: 1, label: 'Inativo', value: false, color: 'red' },
	];

	const formik = useFormik({
		initialValues: initialState,
		validationSchema,
		onSubmit: (data, { resetForm }) => {
			handleSaveCampaign(data, resetForm);
		},
	});

	async function loadCampaignsList() {
		const dados = await apiDescProgressivo.get('/all');
		setCampaigns(dados.data.data);
	}

	const handleCloseModalNewCampaign = () => {
		setModalNewCampaign(false);
	};

	const handleOpenModalNewCampaign = () => {
		setModalNewCampaign(true);
	};

	const handleCloseModalViewCampaign = () => {
		setModalViewCampaign(false);
	};

	const handleOpenModalViewCampaign = () => {
		setModalViewCampaign(true);
	};

	const handleViewCampaign = (campaignone) => {
		setOneCampaign(campaignone);
	};

	const handleEditMode = (edit) => {
		setEditMode(edit);
		handleSearchStores();
	};

	const getRandomIntInclusive = (min, max) => {
		const minimo = Math.ceil(min);
		const maximo = Math.floor(max);
		const number = Math.floor(Math.random() * (maximo - minimo + 1)) + minimo;
		return number;
	};

	const handleSearchStores = async () => {
		await apiDescProgressivo
			.get('/stores')
			.then((res) => {
				setStoreList(res.data.data);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Erro ao consultar as lojas.',
				});
			});
	};

	const handleSearchRegions = async (codigo) => {
		await apiDescProgressivo
			.get(`/regions/${codigo}`)
			.then((res) => {
				setRegionList(res.data.data);
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Erro ao consultar as lojas.',
				});
			});
	};

	const handleSelectStores = async (codigo) => {
		if (codigo === 0) {
			handleSearchStores();
		} else {
			handleSearchStoresFlags(codigo);
		}
	};

	const handleSearchStoresRegions = async (codigo) => {
		setStoreList([]);
		if (codigo) {
			await apiDescProgressivo
				.get(`/stores_regions/${codigo}`)
				.then((res) => {
					setStoreList(res.data.data);
				})
				.catch(() => {
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Erro ao consultar as regiões.',
					});
				});
		}
	};

	const handleSearchStoresFlags = async (codigo) => {
		setStoreList([]);
		if (codigo) {
			await apiDescProgressivo
				.get(`/stores_flags/${codigo}`)
				.then((res) => {
					setStoreList(res.data.data);
				})
				.catch((error) => {
					onChangeNotify({
						open: true,
						class: 'error',
						msg: `Erro ao consultar as lojas. ${error}`,
					});
				});
		}
	};

	function handleStores(lojas) {
		const array = [];
		for (let i = 0; i < lojas.length; i++) {
			array.push(lojas[i].NRESTABELECIMENTO);
		}
		return array.toString();
	}

	const handleSaveCampaign = async (
		campaign,
		dateStarts,
		dateEnds,
		listStores,
		channels,
		estado
	) => {
		const data = {
			campanha: campaign,
			dataInicio: dateStarts,
			dataFinal: dateEnds,
			lojas: handleStores(listStores.list_stores),
			canal: channels,
			status: estado ? 'Ativo' : 'Inativo',
			matricula: user.matricula.toString(),
		};
		await apiDescProgressivo
			.post('/insert', data)
			.then(() => {
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Campanha criada com sucesso!',
				});
				handleCloseModalNewCampaign();
				loadCampaignsList();
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleUpdateCampaign = async (data) => {
		const campaign = {
			campanha: data.newCampaign,
			dataInicio: data.newDateInicio,
			dataFinal: data.newDateFinal,
			lojas: data.stores,
			canal: data.newCanal,
			status: data.status,
			matricula: user.matricula.toString(),
		};
		await apiDescProgressivo
			.put(`/update/${data.newID}`, campaign)
			.then(() => {
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Campanha atualizada com sucesso!',
				});

				loadCampaignsList();
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleUpdateCampaignAction = async (data) => {
		const campaign = {
			campanha: data.CAMPANHA,
			dataInicio: data.DATAINICIO,
			dataFinal: data.DATAFINAL,
			lojas: data.LOJAS,
			canal: data.CANAL,
			status: data.STATUS === 'Ativo' ? 'Inativo' : 'Ativo',
			matricula: user.matricula.toString(),
		};
		console.log(campaign);
		await apiDescProgressivo
			.put(`/update/${data.ID}`, campaign)
			.then(() => {
				onChangeNotify({
					open: true,
					class: 'success',
					msg: 'Campanha atualizada com sucesso!',
				});

				loadCampaignsList();
			})
			.catch(() => {
				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	return (
		<ProgressiveDiscountContext.Provider
			value={{
				statusList,
				brandList,
				headers,
				filterList,
				modalNewCampaign,
				handleCloseModalNewCampaign,
				handleOpenModalNewCampaign,
				modalViewCampaign,
				handleCloseModalViewCampaign,
				handleOpenModalViewCampaign,
				handleViewCampaign,
				campaigns,
				handleEditMode,
				editMode,
				loadCampaignsList,
				campaigns,
				validationSchema,
				getRandomIntInclusive,
				handleSelectStores,
				handleSearchRegions,
				handleSearchStoresRegions,
				stores,
				storeList,
				setStoreList,
				regionList,
				groupStoreUpdate,
				setGroupStoreUpdate,
				dateStart,
				setDateStart,
				dateEnd,
				setDateEnd,
				handleSaveCampaign,
				channel,
				setChannel,
				status,
				setStatus,
				oneCampaign,
				campaignName,
				setCampaignName,
				handleUpdateCampaignAction,
				handleUpdateCampaign,
				formik,
			}}
		>
			{children}
		</ProgressiveDiscountContext.Provider>
	);
}

export function useProgressiveDiscount() {
	const context = React.useContext(ProgressiveDiscountContext);

	if (!context)
		throw new Error(
			'useUserManagement must be used within an UserManagementProvider'
		);

	return context;
}
