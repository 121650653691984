import React from 'react';

const initialState = {
	open: false,
	horizontal: 'center',
	vertical: 'top',
	class: 'error',
	time: 4000,
	msg: '',
};

const SessionExpirationContext = React.createContext({
	onChangeSessionExpiration: () => console.log('Not implementated yet'),
});

export function SessionExpirationProvider({ children }) {
	const initialMinute = 30;
	const initialSeconds = 0;

	const [minutes, setMinutes] = React.useState(initialMinute);
	const [seconds, setSeconds] = React.useState(initialSeconds);
	const [modal, setModal] = React.useState(false);
	const [count, setCount] = React.useState(false);

	const userEmpty = localStorage.getItem('@SF:User')
		? localStorage.getItem('@SF:User').length
		: null;

	React.useEffect(() => {
		const myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval);
					setModal(false);
					localStorage.clear();
					window.location.reload(true);
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}

			if (minutes < 2 && count === false && userEmpty !== null) {
				setModal(true);
				setCount(true);
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	});

	const getMoreSession = () => {
		setMinutes(initialMinute);
		setSeconds(0);
		setModal(false);
		setCount(false);
	};

	return (
		<SessionExpirationContext.Provider
			value={{ minutes, seconds, modal, setModal, getMoreSession }}
		>
			{children}
		</SessionExpirationContext.Provider>
	);
}

export function useSessionExpiration() {
	const context = React.useContext(SessionExpirationContext);

	if (!context)
		throw new Error('useSessionExpiration must be used within an AuthProvider');

	return context;
}
