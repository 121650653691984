import React from 'react';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import { useAuth } from '../../../hooks/auth';

import apiAutomacaoTangiveis from '../../../services/apiAutomacao'

const DashboardContext = React.createContext({
	onChangeDashboard: () => console.log('Not implementated yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

DashboardContext.displayName = 'Contexto Dashboard - Tangíveis';

export function DashboardProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();
	const { user } = useAuth();
	
	const [activeStep, setActiveStep] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false)

	const [brand, setBrand] = React.useState(null);
	const [initialDate, setInitialDate] = React.useState(null);
	const [finalDate, setFinalDate] = React.useState(null);

	const [generalData, setGeneralData] = React.useState([]);
	const [cardsPerBrand, setCardsPerBrand] = React.useState([])
	const [cardsCarrying, setCardsCarrying] = React.useState([])
	const [cardsUf, setCardsUf] = React.useState([])
	const [avgDaysDeliveryStore, setAvgDaysDeliveryStore] = React.useState([])
	const [avgDaysDeliveryResidence, setAvgDaysDeliveryResidence] = React.useState([]);
	const [returnReason, setReturnReason] = React.useState([])
	const [misplacementReason, setMisplacementReason] = React.useState([])
	const [next, setNext] = React.useState(true);

	const handleNext = () => {
		setActiveStep(1);
		setIsLoading(false);
	};

	const handleBack = () => {
		setInitialDate(null)
		setFinalDate(null)
		setBrand(null)
		setActiveStep(0);
		setIsLoading(false);

		setGeneralData([]);
		setCardsPerBrand([]);
		setCardsCarrying([]);
		setCardsUf([]);
		setAvgDaysDeliveryStore([]);
		setAvgDaysDeliveryResidence([]);
		setReturnReason([]);
		setMisplacementReason([]);
	};

	const handleConsultGeneralData = async (payload) => {
		await apiAutomacaoTangiveis
			.post(`/dashboard/dados-gerais`, payload)
			.then((res) => {
				setGeneralData(res);
				setNext(true);
			})
			.catch((error) => {
				setNext(false);
			});
	};

	const handleConsultCardsPerBrand = async (payload) => {
			await apiAutomacaoTangiveis.post(`/dashboard/cartoes-bandeira`, payload)
				.then((res) => {
					setCardsPerBrand(res)
					setNext(next && true);
				})
				.catch((error) => {
					setNext(next && false);
				});
	};

	const handleConsultCardsCarrying = async (payload) => {
		await apiAutomacaoTangiveis
			.post(`/dashboard/cartoes-transportadora`, payload)
			.then((res) => {
				setCardsCarrying(res);
				setNext(next && true);
			})
			.catch((error) => {
				setNext(next && false);
			});
	};

	const handleConsultCardsUf = async (payload) => {
		await apiAutomacaoTangiveis
			.post(`/dashboard/cartoes-uf`, payload)
			.then((res) => {
				setCardsUf(res);
				setNext(next && true);
			})
			.catch((error) => {
				setNext(next && false);
			});
	};

	const handleConsultAvgDaysDeliveryStore = async (payload) => {
		await apiAutomacaoTangiveis
			.post(`/dashboard/dias-entrega-loja`, payload)
			.then((res) => {
				setAvgDaysDeliveryStore(res);
				setNext(next && true);
			})
			.catch((error) => {
				setNext(next && false);
			});
	};

	const handleConsultAvgDaysDeliveryResidence = async (payload) => {
		await apiAutomacaoTangiveis
			.post(`/dashboard/dias-entrega-residencia`, payload)
			.then((res) => {
				setAvgDaysDeliveryResidence(res);
				setNext(next && true);
			})
			.catch((error) => {
				setNext(next && false);
			});
	};

	const handleConsultReturnReason = async (payload) => {
		await apiAutomacaoTangiveis
			.post(`/dashboard/motivo-devolucao`, payload)
			.then((res) => {
				setReturnReason(res);
				setNext(next && true);
			})
			.catch((error) => {
				setNext(next && false);
			});
	};

	const handleConsultMisplacementReason = async (payload) => {
		await apiAutomacaoTangiveis
			.post(`/dashboard/motivo-extravio`, payload)
			.then((res) => {
				setMisplacementReason(res);
				setNext(next && true);
			})
			.catch((error) => {
				setNext(next && false);
			});
	};


	const handleConsult = async () => {
		setIsLoading(true);
		const payload = {
			dtInicial: initialDate,
			dtFinal: finalDate,
			cdNegocio: brand,
		};

		await handleConsultGeneralData(payload);
		await handleConsultCardsPerBrand(payload);
		await handleConsultCardsCarrying(payload);
		await handleConsultCardsUf(payload);
		await handleConsultAvgDaysDeliveryStore(payload);
		await handleConsultAvgDaysDeliveryResidence(payload);
		await handleConsultReturnReason(payload);
		await handleConsultMisplacementReason(payload);

		if (next) {
			handleRecordLog('dashboard', 'consulta');
			handleNext();
		} else {
			setIsLoading(false);
			onChangeLoading({ open: false });

			onChangeNotify({
				open: true,
				class: 'error',
				msg: 'Aconteceu algum erro na busca, tente novamente!',
			});
		}
	}

	const handleRecordLog = async (screen, type) => {
		const payload = {
			loginAd: user.login_ad,
			dsTela: screen,
			dsTipo: type,
		};

		await apiAutomacaoTangiveis
			.post(`/log-acesso`, payload)
			.then((res) => {})
			.catch((error) => {});
	};

	return (
		<DashboardContext.Provider
			value={{
				activeStep,
				handleNext,
				handleBack,
				brand,
				setBrand,
				initialDate,
				setInitialDate,
				finalDate,
				setFinalDate,
				handleConsult,
				isLoading,
				handleRecordLog,
				generalData,
				cardsPerBrand,
				cardsCarrying,
				cardsUf,
				avgDaysDeliveryStore,
				avgDaysDeliveryResidence,
				returnReason,
				misplacementReason,
			}}
		>
			{children}
		</DashboardContext.Provider>
	);
}

export function useDashboard() {
	const context = React.useContext(DashboardContext);

	if (!context)
		throw new Error('useAttendance must be used within an AuthProvider');

	return context;
}
