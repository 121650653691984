import React from 'react';
import Cookies from 'universal-cookie';

import apiAuth from '../services/apiAuth';

const AuthContext = React.createContext({
	signIn: () => console.log('Not implemented yet'),
	signOut: () => console.log('Not implemented yet'),
});

export function AuthProvider({ children }) {
	const cookies = new Cookies();

	const [loading, setLoading] = React.useState(false);
	const [data, setData] = React.useState({});

	React.useEffect(() => {
		const token = cookies.get('@SF:Token');
		const user = localStorage.getItem('@SF:User');

		const config = {
			headers: {
				'x-access-token': token,
			},
		};

		if (token && user) {
			apiAuth.get('/user/validate', config).then((response) => {
				response.status === 200
					? (apiAuth.defaults.headers.authorization = `Bearer ${token}`)
					: (apiAuth.defaults.headers.authorization = null);
			});
		}

		return {};
	}, []);

	const signIn = async ({ username, password }) => {
		setLoading(true);
		const response = await apiAuth.post('/user/login', {
			login_ad: username,
			password,
		});

		const tomorrow = new Date();
		tomorrow.setHours(tomorrow.getHours() + 8);

		cookies.set('@SF:Token', response.data.token, {
			expires: tomorrow,
		});

		localStorage.setItem('@SF:User', JSON.stringify(response.data));

		apiAuth.defaults.headers.authorization = `Bearer ${response.data.token}`;

		setLoading(false);

		setData({
			user: response.data,
			token: response.data.token,
		});
	};

	const signOut = React.useCallback(() => {
		localStorage.clear();
		apiAuth.defaults.headers.authorization = null;
		setData({});
	}, []);

	return (
		<AuthContext.Provider
			value={{ user: data.user, signIn, signOut, loading, setLoading }}
		>
			{children}
		</AuthContext.Provider>
	);
}

export function useAuth() {
	const context = React.useContext(AuthContext);

	if (!context) throw new Error('useAuth must be used within an AuthProvider');

	return context;
}
