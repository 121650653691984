import axios from 'axios';
import config from '../config';

const apiArmazenamentoFaturaS3 = axios.create({
	baseURL:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_ARMAZ_FATURA_S3_PROD
			: process.env.REACT_APP_ARMAZ_FATURA_S3_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key': 'duSqKltnk3avjjOlyPQxj2malihcE7Lg8I6eHhKD',
	},
});

export default apiArmazenamentoFaturaS3;
